<div class="p-grid p-ai-top p-ai-stretch vertical-container" style="margin:20px">
    <div class="p-col-4" style="padding-bottom: 0px">
        <div #myIdentifier class="p-grid" style="padding:0px 10px 0px 0px">
            <div class="p-col-12 donor-view" style="margin-bottom:0px; min-height:708px; text-align: center;">
                <div class="p-grid" style="text-align: left; padding-left:20px">
                    <div class="p-col-fixed" style="width:50px">
                        <div style="height:60px">&nbsp;</div>
                        <div style="height:30px"><p-radioButton value="coldischemiatime" [(ngModel)]="selectedVar" inputId="item1" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="puritypercentage" [(ngModel)]="selectedVar" inputId="item2" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="trappedpercentage" [(ngModel)]="selectedVar" inputId="item3" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="pancreasweight" [(ngModel)]="selectedVar" inputId="item4" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="digesttime" [(ngModel)]="selectedVar" inputId="item5" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="totalieq" [(ngModel)]="selectedVar" inputId="item6" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="isletparticleindex" [(ngModel)]="selectedVar" inputId="item7" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="ieqperpancreasweight" [(ngModel)]="selectedVar" inputId="item8" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="insulincontent" [(ngModel)]="selectedVar" inputId="item9" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="insulinperieq" [(ngModel)]="selectedVar" inputId="item10" (onClick)="main()"></p-radioButton></div>

                        <div style="height:60px">&nbsp;</div>
                        <div style="height:30px"><p-radioButton value="predistributionculturetime" [(ngModel)]="selectedVar" inputId="item11" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="percentieqrecoverypostculture" [(ngModel)]="selectedVar" inputId="item12" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="pdisletparticleindex" [(ngModel)]="selectedVar" inputId="item13" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="pdinsulinperieq" [(ngModel)]="selectedVar" inputId="item14" (onClick)="main()"></p-radioButton></div>

                        <div style="height:60px">&nbsp;</div>
                        <div style="height:30px"><p-radioButton value="cryotubesremaining" [(ngModel)]="selectedVar" inputId="item16" (onClick)="main()"></p-radioButton></div>
                        <div style="height:30px"><p-radioButton value="sftubesremaining" [(ngModel)]="selectedVar" inputId="item17" (onClick)="main()"></p-radioButton></div>
                    </div>
                    <div class="p-col" *ngIf="donorInfType != 'undefined'">
                        <div style="height:50px; padding-top: 20px; margin-bottom: 10px">
                            <span style="font-size: 1.5rem; font-weight: 600; margin-left:-50px">Isolation</span>
                        </div>
                        <div style="height:30px"><label for="item1" class="ml-2"><span style="font-weight: 500; color: #505050">Cold ischemic time:</span> {{donorInf.coldischemiatime.value}} <span *ngIf="donorInf.coldischemiatime.value != 'NA'">h</span></label></div>
                        <div style="height:30px"><label for="item2" class="ml-2"><span style="font-weight: 500; color: #505050">Purity:</span> {{donorInf.puritypercentage.value}}<span *ngIf="donorInf.puritypercentage.value != 'NA'">%</span></label></div>
                        <div style="height:30px"><label for="item3" class="ml-2"><span style="font-weight: 500; color: #505050">Trapped:</span> {{donorInf.trappedpercentage.value}}<span *ngIf="donorInf.trappedpercentage.value != 'NA'">%</span></label></div>
                        <div style="height:30px"><label for="item4" class="ml-2"><span style="font-weight: 500; color: #505050">Pancreas weight:</span> {{donorInf.pancreasweight.value}} <span *ngIf="donorInf.pancreasweight.value != 'NA'">g</span></label></div>
                        <div style="height:30px"><label for="item5" class="ml-2"><span style="font-weight: 500; color: #505050">Digestion time:</span> {{donorInf.digesttime.value}} <span *ngIf="donorInf.digesttime.value != 'NA'">min</span></label></div>
                        <div style="height:30px"><label for="item6" class="ml-2"><span style="font-weight: 500; color: #505050">Total IEQ:</span> {{donorInf.totalieq.value}}</label></div>
                        <div style="height:30px"><label for="item7" class="ml-2"><span style="font-weight: 500; color: #505050">Islet particle index:</span> {{donorInf.isletparticleindex.value}}</label></div>
                        <div style="height:30px"><label for="item8" class="ml-2"><span style="font-weight: 500; color: #505050">IEQ/pancreas:</span> {{donorInf.ieqperpancreasweight.value}} <span *ngIf="donorInf.ieqperpancreasweight.value != 'NA'">IEQ/g</span></label></div>
                        <div style="height:30px"><label for="item9" class="ml-2"><span style="font-weight: 500; color: #505050">Insulin content:</span> {{donorInf.insulincontent.value}} <span *ngIf="donorInf.insulincontent.value != 'NA'">mg</span></label></div>
                        <div style="height:30px"><label for="item10" class="ml-2"><span style="font-weight: 500; color: #505050">Insulin/IEQ:</span> {{donorInf.insulinperieq.value}} <span *ngIf="donorInf.insulinperieq.value != 'NA'">ng/IEQ</span></label></div>

                        <div style="height:50px; padding-top: 20px; margin-bottom: 10px">
                            <span style="font-size: 1.5rem; font-weight: 600; margin-left:-50px">Pre-distribution</span>
                        </div>
                        <div style="height:30px"><label for="item11" class="ml-2"><span style="font-weight: 500; color: #505050">Culture time:</span> {{donorInf.predistributionculturetime.value}} <span *ngIf="donorInf.predistributionculturetime.value != 'NA'">hours</span></label></div>
                        <div style="height:30px"><label for="item12" class="ml-2"><span style="font-weight: 500; color: #505050">IEQ recovery:</span> {{donorInf.percentieqrecoverypostculture.value}}<span *ngIf="donorInf.percentieqrecoverypostculture.value != 'NA'">%</span></label></div>
                        <div style="height:30px"><label for="item13" class="ml-2"><span style="font-weight: 500; color: #505050">Islet particle index:</span> {{donorInf.pdisletparticleindex.value}}</label></div>
                        <div style="height:30px"><label for="item14" class="ml-2"><span style="font-weight: 500; color: #505050">Insulin/IEQ:</span> {{donorInf.pdinsulinperieq.value}} <span *ngIf="donorInf.pdinsulinperieq.value != 'NA'">ng/IEQ</span></label></div>
                                                
                        <div style="height:50px; padding-top: 20px; margin-bottom: 10px">
                            <span style="font-size: 1.5rem; font-weight: 600; margin-left:-50px;">Inventory</span>
                        </div>
                        <div style="height:30px"><label for="item16" class="ml-2"><span style="font-weight: 500; color: #505050">Cryopreserved tubes:</span> {{donorInf.cryotubesremaining.value}}</label></div>
                        <div style="height:30px"><label for="item17" class="ml-2"><span style="font-weight: 500; color: #505050">Snap-frozen tubes:</span> {{donorInf.sftubesremaining.value}}</label></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-4" style="padding-bottom: 0px">
        <div class="p-grid" style="padding:0px 10px">
            <div class="p-col-12 donor-view" style="margin-bottom:20px; min-height: 344px; text-align: center;">
                <div  style="text-align: center; margin-top:15px">
                    <span style="font-size: 1.5rem; font-weight: 600;">{{histTitle}}</span>
                </div>
                <div id="VarHistogram" class="histogram" style="margin-top: 20px; align-content: center;"></div>
                <span style="text-align: center;">{{units}}</span>
            </div>
            <div class="p-col-12 donor-view" style="margin-bottom:0px; min-height: 344px; text-align: center;">
                <div  style="text-align: center; margin-top:15px;">
                    <span style="font-size: 1.5rem; font-weight: 600;">Cell Type Composition</span>
                </div>
                <div>
                    <div id="CellTypeBar" class="histogram" style="margin-top: 20px; align-content: center;"></div>
                    <span *ngIf="cellComp" style="text-align: center;">Cell Type (computed from proteomics data)</span>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col-4" style="padding-bottom: 0px">
        <div class="p-grid" style="padding:0px 0px 0px 10px; min-height: 708px;">
            <div class="p-col-12 donor-view">
                <div  style="text-align: center; margin-top:15px">
                    <span style="font-size: 1.5rem; font-weight: 600;">Dithizone-stained Islets</span>
                </div>
                <div  class="p-grid" style="padding: 10px 15px 0px 15px">
                    <div class="p-col-12 p-d-flex p-ai-center p-jc-center" style="margin-top:20px">
                        <div>
                            <p-selectButton [options]="imageOptions" [(ngModel)]="selImage" optionLabel="label" optionValue="value"></p-selectButton>
                        </div>
                    </div>
                    <div class="p-col-12" style="margin-top:15px">
                        <div *ngIf="selImage=='postiso'" style="height: 250px" class="p-d-flex p-ai-center p-jc-center">
                            <img *ngIf="postisoImg" alt="No image for this donor" [src]="postisoImg" width="90%">
                            <span *ngIf="!postisoImg">No image for this donor</span>
                        </div>
                        <div *ngIf="selImage=='predist'" style="height: 250px" class="p-d-flex p-ai-center p-jc-center">
                            <img *ngIf="predistImg" alt="No image for this donor" [src]="predistImg" width="90%">
                            <span *ngIf="!predistImg">No image for this donor</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


