import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    
    analysisState: string = "vis";
    
    state: BehaviorSubject<string>;
    constructor() {
 
        this.state = new BehaviorSubject(this.analysisState);
    }
 
    nextState(index: number) {
        if(index == 1){
            this.state.next("vis");
        }  else if(index == 2){
            this.state.next("stats");
        } else {
            this.state.next("export");
        }
    }
    
}

