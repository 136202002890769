<div class="topbar clearfix">
    <div class="logo">
        <a href="#">
            <img src="assets/layout/images/logo.png">
        </a>
    </div>

    <a href="#">
        <img src="assets/layout/images/logo-text.svg" class="app-name"/>
    </a>

    <a id="topbar-menu-button" href="#" (click)="appMain.onTopbarMenuButtonClick($event)">
        <i class="pi pi-bars"></i>
    </a>

    <ul class="topbar-menu fadeInDown" style="margin-right:50px;">
        <li>
            <a routerLink="/tos" style="margin-left:15px">
                <span style="font-size:20px; font-weight: bold">Terms of Service</span>
            </a>
        </li>
        <li>
            <a routerLink="/about" style="margin-left:15px">
                <span style="font-size:20px; font-weight: bold">About</span>
            </a>
        </li>
        <li>
            <a routerLink="/updates" style="margin-left:15px">
                <span style="font-size:20px; font-weight: bold">Updates</span>
            </a>
        </li>
        <li>
            <a href="http://doc.humanislets.com/" target="_blank" style="margin-left:15px">
                <span style="font-size:20px; font-weight: bold">Documentation</span>
            </a>
        </li> 
    </ul>
</div>