<style>
    ::ng-deep .p-tooltip {
        max-width: fit-content;
    }

    .sig-up a {
        color: #cf2e2e;
    }

    .sig-down a {
        color: #0000ff;
    }
    
    .custom-download {
    font-size: 16px; 
    padding: 5px 18px; 
}

.custom-download .pi-download {  
    transform: scale(1.2);  
    margin-right: 8px; 
}

.custom-icon-size .pi {
    font-size: 1.5em;   
    transform: scale(1.2);  
}

</style>
<div class="p-grid">
    <div class="p-col-12">
        <div class="card" style="padding-left:40px">
            <h1 style='margin: 0px'>Omics View</h1>
        </div>
    </div>
</div>
<div class="p-grid">
    <div class="p-col-12">
        <div class="card" style="padding:40px">
            <div>
                <p>
                    This tool uses general linear models to find associations between omics features and donor
                    metadata/outcomes using the limma R package.
                    A linear model is fit to each omics feature that includes the primary metadata and covariate
                    variables. Then, results are extracted from the model for the primary metadata. See the 
                    <a href="https://xia-lab.github.io/HumanIsletsDoc/overview-of-statistical-analysis-methods.html#multiple-linear-regression" target="_blank">
                        statistical analysis documentation
                    </a> for more details.
                </p>
                <div style="margin-top:30px" class="p-grid p-ai-top p-jc-left">
                    <div class="p-col-12" style="padding-left:20px">
                        <div class="p-grid p-ai-center p-jc-left">
                            <div class="p-col-2">
                                <label style="font-weight: bold;">Omics Type:</label>
                            </div>
                            <div class="p-col-10">
                                <p-dropdown [options]="omicsType" [(ngModel)]="selectedOmicsType" optionLabel="label"
                                            optionValue="value" [style]="{'width':'350px', 'margin-right': '10px'}"
                                            (onChange)="onOmicsChange()" [scrollHeight]="'200px'"></p-dropdown>

                                <p-dropdown
                                    *ngIf="selectedOmicsType == 'proc_pbrna' || selectedOmicsType == 'proc_scrna'"
                                    [options]="cellType" [(ngModel)]="selectedCell" optionLabel="label"
                                    optionValue="value" [style]="{'width':'150px', 'margin-right': '10px'}"
                                    [scrollHeight]="'200px'"></p-dropdown>

                                <p-dropdown *ngIf="selectedOmicsType == 'proc_scrna'" [options]="glucConc"
                                            [(ngModel)]="selectedGluc" optionLabel="label" optionValue="value"
                                            [style]="{'width':'160px', 'margin-right': '10px'}"
                                            [scrollHeight]="'200px'"></p-dropdown>
                            </div>
                        </div>
                        <div style="margin-top:20px;" class="p-grid p-ai-center p-jc-left">
                            <div class="p-col-2">
                                <label style="font-weight: bold;">Primary Metadata:</label>
                            </div>
                            <div class="p-col-10">
                                <p-dropdown *ngIf="selectedGroup != 'ephys_cell'" [options]="variableGroups"
                                            [(ngModel)]="selectedGroup" optionLabel="label" optionValue="value"
                                            [style]="{'width':'350px', 'margin-right': '10px'}" [scrollHeight]="'200px'"
                                            (onChange)="primaryType = 'cont'"></p-dropdown>

                                <p-dropdown
                                    *ngIf="selectedGroup == 'ephys_donor' && selectedOmicsType != 'proc_scrna' && selectedOmicsType != 'proc_pbrna'"
                                    [options]="cellType" [(ngModel)]="selectedCell" optionLabel="label"
                                    optionValue="value" [style]="{'width':'150px', 'margin-right': '10px'}"
                                    [scrollHeight]="'200px'"></p-dropdown>

                                <p-dropdown
                                    *ngIf="selectedGroup == 'ephys_donor' && selectedOmicsType != 'proc_scrna' && selectedOmicsType != 'proc_pbrna'"
                                    [options]="glucConc" [(ngModel)]="selectedGluc" optionLabel="label"
                                    optionValue="value" [style]="{'width':'160px', 'margin-right': '10px'}"
                                    [scrollHeight]="'200px'"></p-dropdown>

                                <p-dropdown *ngIf="selectedGroup == 'donor'" [options]="varsDonor"
                                            [(ngModel)]="selectedPrimary" optionLabel="label" optionValue="value"
                                            [style]="{'width':'350px'}" [scrollHeight]="'200px'"
                                            (onChange)="onPrimaryChange($event.value)">
                                </p-dropdown>

                                <p-dropdown *ngIf="selectedGroup == 'organ'" [options]="varsOrgan"
                                            [(ngModel)]="selectedPrimary" optionLabel="label" optionValue="value"
                                            [style]="{'width':'350px'}" [scrollHeight]="'200px'"
                                            (onChange)="onPrimaryChange($event.value)">
                                </p-dropdown>

                                <p-dropdown *ngIf="selectedGroup == 'isolation'" [options]="varsIsolation"
                                            [(ngModel)]="selectedPrimary" optionLabel="label" optionValue="value"
                                            [style]="{'width':'350px'}" [scrollHeight]="'200px'"
                                            (onChange)="onPrimaryChange($event.value)">
                                </p-dropdown>

                                <p-dropdown *ngIf="selectedGroup == 'cell_pro'" [options]="varsCellPro"
                                            [(ngModel)]="selectedPrimary" optionLabel="label" optionValue="value"
                                            [style]="{'width':'350px'}" [scrollHeight]="'200px'"
                                            (onChange)="onPrimaryChange($event.value)">
                                </p-dropdown>

                                <p-dropdown *ngIf="selectedGroup == 'cell_culture'" [options]="varsCellCulture"
                                            [(ngModel)]="selectedPrimary" optionLabel="label" optionValue="value"
                                            [style]="{'width':'350px'}" [scrollHeight]="'200px'"
                                            (onChange)="onPrimaryChange($event.value)">
                                </p-dropdown>

                                <p-dropdown *ngIf="selectedGroup == 'gsis'" [options]="varsGSIS"
                                            [(ngModel)]="selectedPrimary" optionLabel="label" optionValue="value"
                                            [style]="{'width':'350px'}" [scrollHeight]="'200px'"></p-dropdown>

                                <p-dropdown *ngIf="selectedGroup == 'seahorse'" [options]="varsSeahorse"
                                            [(ngModel)]="selectedPrimary" optionLabel="label" optionValue="value"
                                            [style]="{'width':'350px'}" [scrollHeight]="'200px'"
                                            (onChange)="onPrimaryChange($event.value)">
                                </p-dropdown>

                                <p-dropdown *ngIf="selectedGroup == 'perifusion'" [options]="varsPerifusion"
                                            [(ngModel)]="selectedPrimary" optionLabel="label" optionValue="value"
                                            [style]="{'width':'350px'}" [scrollHeight]="'200px'"
                                            (onChange)="onPrimaryChange($event.value)">
                                </p-dropdown>

                                <p-dropdown *ngIf="selectedGroup == 'grs'" [options]="varsGRS"
                                            [(ngModel)]="selectedPrimary" optionLabel="label" optionValue="value"
                                            [style]="{'width':'350px'}" [scrollHeight]="'200px'"
                                            (onChange)="onPrimaryChange($event.value)">
                                </p-dropdown>

                                <p-dropdown *ngIf="selectedGroup == 'ephys_donor'" [options]="varsEphysDonor"
                                            [(ngModel)]="selectedPrimary" optionLabel="label" optionValue="value"
                                            [style]="{'width':'350px'}" [scrollHeight]="'200px'"
                                            (onChange)="onPrimaryChange($event.value)">
                                </p-dropdown>

                                <p-dropdown *ngIf="selectedGroup == 'ephys_cell'" [options]="varsEphysCell"
                                            [(ngModel)]="selectedPrimary" optionLabel="label" optionValue="value"
                                            [style]="{'width':'350px'}" [scrollHeight]="'200px'"
                                            (onChange)="onPrimaryChange($event.value)">
                                </p-dropdown>

                            </div>
                        </div>
                        <div *ngIf="primaryType=='disc'" style="margin-top:20px;" class="p-grid p-ai-center p-jc-left">
                            <div class="p-col-2">
                                <label style="font-weight: bold;">Comparison of Interest:</label>
                            </div>
                            <div class="p-col-10">
                                <p-dropdown [options]="varClasses" [(ngModel)]="class1" optionLabel="label"
                                            optionValue="value" [style]="{'width':'150px', 'margin-right':'15px'}"
                                            [scrollHeight]="'200px'" [filter]="true"></p-dropdown>
                                vs.
                                <p-dropdown [options]="varClasses" [(ngModel)]="class2" optionLabel="label"
                                            optionValue="value" [style]="{'width':'150px', 'margin-left':'15px'}"
                                            [scrollHeight]="'200px'" [filter]="true"></p-dropdown>
                            </div>
                        </div>
                        <div *ngIf="selectedOmicsType != 'proc_scrna'" style="margin-top:20px;"
                             class="p-grid p-ai-center p-jc-left">
                            <div class="p-col-2">
                                <label style="font-weight: bold;">Control for:</label>
                            </div>
                            <div class="p-col-10">
                                <p-multiSelect [options]="covariates" [group]="true" [(ngModel)]="selectedCovariates"
                                               defaultLabel="Select covariates" scrollHeight="250px" display="chip"
                                               [style]="{'width':'400px'}" [maxSelectedLabels]="100">
                                    <ng-template let-group pTemplate="group">
                                        <div class="flex align-items-center">
                                            <span>{{group.label}}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </div>
                        </div>
                        <div style="margin-top:20px;" class="p-grid p-ai-center p-jc-left">
                            <div class="p-col-2">
                                <label style="font-weight: bold;">Donors:</label>
                            </div>
                            <div class="p-col-10">
                                <div class="field-radiobutton">
                                    <p-radioButton value="all" [(ngModel)]="donorOpt" (click)="clearDonorList()"
                                                   inputId="donorOpt1"></p-radioButton>
                                    <label style="margin: 0px 20px 0px 5px;" for="donorOpt1">All</label>
                                    <p-radioButton *ngIf="selectedOmicsType != 'proc_scrna'" value="subset" [(ngModel)]="donorOpt" (click)="displayDonor=true"
                                                   inputId="donorOpt2"></p-radioButton>
                                    <p-radioButton *ngIf="selectedOmicsType == 'proc_scrna'" value="subset" [(ngModel)]="donorOpt" (click)="displaySCDonor=true"
                                                   inputId="donorOpt2"></p-radioButton>
                                    <label style="margin: 0px 20px 0px 5px;" for="donorOpt2">Subset</label>
                                    <div *ngIf="listExists" style="margin-top: 15px">
                                        <button pButton type="button" label="Download Donor List"
                                                class="p-button-warning" (click)="downloadDonorList()"
                                                icon="pi pi-download"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top:20px;" class="p-grid p-ai-center p-jc-left">
                            <div class="p-col-2">
                                <label style="font-weight: bold;">P-value cutoff:</label>
                            </div>
                            <div class="p-col-fixed" style="width: 200px;">
                                <p-inputNumber [(ngModel)]="pvalThresh" inputId="minmax" inputId="minmax" mode="decimal"
                                               [min]="0" [max]="1" [style]="{'width':'100px'}"></p-inputNumber>
                            </div>
                            <div class="p-col" *ngIf="selectedOmicsType != 'proc_scrna'">
                                <p-checkbox [(ngModel)]="fdr" [binary]="true" [style]="{'margin-left':'20px'}"></p-checkbox>
                                <label style="margin-left:15px">Use adjusted p-values</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-grid p-ai-center p-jc-center vertical-container" style="margin-top:50px;">
                    <div>
                        <button pButton pRipple type="button" label="Submit" class="p-button-rounded p-button-primary"
                                (click)="performRegression()" [loading]="loadingDEA"></button>
                    </div>
                </div>
                <div style="padding-top: 20px">
                    <p-messages [(value)]="currentMsg" [enableService]="false"></p-messages>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="deaResults" class="p-grid">
    <div class="p-col-12">
        <div class="card" style="padding:40px">
            <h4>Feature Association Results </h4>
            <div style="margin-top:30px">
                <div *ngIf="displayZero" class="p-grid p-ai-center p-jc-left vertical-container" 
                     style="margin:30px 0px; background-color:#B3E5FC; border-radius: 4px; padding: 1.25rem 1.5rem; height: 70px">
                    <i class="pi pi-info-circle" style="margin-right: 15px; font-size: 1.5rem"></i> 
                    <span style="margin-right:15px"> <strong style="margin-right:5px">Note </strong> There were no statistically significant features, however there may be significant results at the pathway level. 
                        Check with the GSEA algorithm:</span>
                    <button pButton pRipple type="button" label="Perform GSEA" class="p-button-warning"
                            (click)="autoGSEA()" [loading]="loadingFunc"></button>
                </div>
                <div class="p-grid p-ai-center p-jc-left vertical-container" style="margin:30px 0px;">
                    <div style="font-size: 12pt;">
                        This statistical analysis resulted in <span style="font-weight: bold">{{sigNum}}</span>
                        significant features (<span style="font-weight: bold; color:#cf2e2e">{{sigUp}}</span> up
                        and
                        <span style="font-weight: bold; color:blue">{{sigDown}}</span> down).
                    </div>
                    <div style="margin-left:20px"><button pButton type="button" label="Download Results"
                                                          class="p-button-warning" (click)="downloadDEAResults()" icon="pi pi-download"></button>
                    </div>
                    <div style="margin-left:20px"><button pButton type="button" label="Save Analysis"
                                                          class="p-button-warning" (click)="saveAnalysis()" icon="pi pi-download"></button>
                    </div>
                </div>
                <p-tabView [(activeIndex)]="index">
                    <p-tabPanel header="Graphical Summary">
                        <app-volcano [data]="deaResults" [xCol]="featureES"
                                      (newItemEvent)="plotFeature($event, 'volcano')"></app-volcano>
                     </p-tabPanel>
                    <p-tabPanel header="Results Table">
                        <p>Features with significant positive associations have red gene symbols and features with significant negative associations have blue gene symbols. 
                            Click a data point in the volcano plot or a row in the results table to generate a scatter plot of the feature ~ primary metadata.
                        </p>
                        <div style="width:95%; margin-top:10px; margin-left:15px">
                            <p-table #dt1 [columns]="deaColumns" [value]="deaResults" rowHover="true"
                                     selectionMode="single" (onRowSelect)="plotFeature($event, 'table')"
                                     [(selection)]="selectedFeature" [globalFilterFields]="['Feature']" [paginator]="true"
                                     [rows]="25" [showCurrentPageReport]="true"
                                     currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                     [rowsPerPageOptions]="[25, 50, 100]">
                                <ng-template pTemplate="caption">
                                    <div style="text-align: left">
                                        <i class="pi pi-search" style="margin:4px 4px 0 0"> </i>
                                        <input type="text" pInputText size="30"
                                               placeholder="Search by symbol (ie. PGS1)"
                                               (input)="dt1.filterGlobal($event.target.value, 'contains')"
                                               style="width:auto" />
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th>
                                            {{featureHeader}}
                                        </th>
                                        <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                            {{ col.header }} <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </th>
                                    <th>
                                        Links
                                    </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr [pSelectableRow]="rowData">
                                        <td>
                                            <span pTooltip='{{rowData["Description"]}}'
                                                  [ngClass]="{'sig-up': (rowData['sig'] == 'up'), 'sig-down': (rowData['sig'] == 'down')}">
                                                <a href='{{pageURL}}/#/feature/?varID={{rowData["Gene_ID"]}}'
                                                   target='_blank'
                                                   style="text-decoration: underline;">{{ rowData[featureHeader] }}</a></span>
                                        </td>
                                        <td *ngFor="let col of columns">
                                            {{ rowData[col.field] }}
                                        </td>
                                        <td>
                                            <a href='https://www.ncbi.nlm.nih.gov/gene/?term={{rowData["Gene_ID"]}}'
                                               target='_blank'
                                               style="text-decoration: underline;">NCBI</a>&nbsp;&nbsp;&nbsp;
                                            <a href='https://t2d.hugeamp.org/gene.html?gene={{rowData[featureHeader]}}'
                                               target='_blank'
                                               style="text-decoration: underline;">T2DKP</a>&nbsp;&nbsp;&nbsp;
                                            <a role="button" style="text-decoration: underline;"
                                               (click)='plotSingleCell(rowData["Gene_ID"], rowData[featureHeader], rowData["Description"])'>SC</a>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </p-tabPanel>
                    <p-tabPanel header="Pathway Analysis" *ngIf="selectedOmicsType != 'proc_nanostring_merge'">
                        <h5 style="margin-top:30px">Pathway Analysis of Significant Features </h5>
                        <p>See the <a href="https://xia-lab.github.io/HumanIsletsDoc/overview-of-statistical-analysis-methods.html#overrepresentation-analysis" target="_blank">pathway analysis documentation</a> for more details on the parameters and methods below.</p>
                        <div class="p-grid p-ai-top p-jc-left">
                            <div style="margin-top:10px; padding-left: 50px;"
                                 class="p-grid p-ai-center p-jc-left p-col-6">
                                <div style="margin-top:20px;" class="p-grid p-ai-center p-jc-left p-col-12">
                                    <div class="p-col-4">
                                        <label style="font-weight: bold;">Method:</label>
                                    </div>
                                    <div class="p-col-8">
                                        <div class="field-radiobutton">
                                            <p-radioButton value="ora" [(ngModel)]="funcOpt"
                                                           inputId="funcOpt1"></p-radioButton>
                                            <label style="margin: 0px 20px 0px 5px;" for="funcOpt1">ORA</label>
                                            <p-radioButton value="gsea" [(ngModel)]="funcOpt"
                                                           inputId="funcOpt2"></p-radioButton>
                                            <label style="margin: 0px 20px 0px 5px;" for="funcOpt2">GSEA</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="funcOpt==='gsea'" style="margin-top:10px;"
                                     class="p-grid p-ai-center p-jc-left p-col-12">
                                    <div class="p-col-4">
                                        <label style="font-weight: bold;">Ranking statistic:</label>
                                    </div>
                                    <div class="p-col-8">
                                        <p-dropdown [options]="rankingOpts" [(ngModel)]="selectedRanking"
                                                    optionLabel="label" optionValue="value" [style]="{'width':'300px'}"
                                                    [scrollHeight]="'200px'"></p-dropdown>
                                    </div>
                                </div>
                                <div style="margin-top:10px;" class="p-grid p-ai-center p-jc-left p-col-12">
                                    <div class="p-col-4">
                                        <label style="font-weight: bold;">Collapse redundant sets:</label>
                                    </div>
                                    <div class="p-col-8">
                                        <p-checkbox [(ngModel)]="collapsePathways" [binary]="true"></p-checkbox>
                                    </div>
                                </div>
                                <div style="margin-top:10px;" class="p-grid p-ai-center p-jc-left p-col-12">
                                    <div class="p-col-4">
                                        <label style="font-weight: bold;">FDR cutoff:</label>
                                    </div>
                                    <div class="p-col-8">
                                        <p-inputNumber [(ngModel)]="fdrFunc" inputId="minmax" inputId="minmax"
                                                       mode="decimal" [min]="0" [max]="1"
                                                       [style]="{'width':'100px'}"></p-inputNumber>
                                    </div>
                                </div>
                                <div style="margin-top:10px;" class="p-grid p-ai-center p-jc-left p-col-12">
                                    <div class="p-col-4">
                                        <label style="font-weight: bold;">Library:</label>
                                    </div>
                                    <div class="p-col-8">
                                        <p-dropdown [options]="libraryOpts" [(ngModel)]="selectedLibrary"
                                                    optionLabel="label" optionValue="value" [style]="{'width':'300px'}"
                                                    [scrollHeight]="'200px'"></p-dropdown>
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top:10px;" class="p-grid p-ai-center p-jc-left p-col-6">
                                <div class="p-d-flex p-jc-center" style="margin-top:50px;">
                                    <button pButton pRipple type="button" label="Submit"
                                            (click)="performFunctionalAnalysis()" [loading]="loadingFunc"
                                            class="p-button-rounded p-button-primary"></button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="funcResults"
                             style="margin-top: 50px; padding: 30px; border: 1px solid rgba(0,0,0,.12); border-radius: 4px">
                            <h5 style="margin-bottom:20px">Pathway Results </h5>
                            <div class="p-grid p-ai-center p-jc-left vertical-container"
                                 style="margin-top:30px; margin-left:15px; margin-bottom: 30px;">
                                <div style="font-size: 12pt;">
                                    This pathway analysis resulted in <span
                                        style="font-weight: bold">{{sigPathNum}}</span>
                                    significant gene sets.
                                </div>
                                <div style="margin-left:20px"><button pButton type="button" label="Download Results"
                                                                      class="p-button-warning" (click)="downloadFuncResults()"
                                                                      icon="pi pi-download"></button></div>
                                <div style="margin-left:20px"><button pButton type="button" label="Save Analysis"
                                                                      class="p-button-warning" (click)="saveAnalysis()" icon="pi pi-download"></button>
                                </div>
                            </div>
                            <p-tabView>
                                <p-tabPanel header="Graphical Summary">
                                    <p *ngIf="funcOpt=='gsea'" style="margin-top: 30px">
                                        Distributions of gene-level {{ridgeXLab}} for the top significant gene sets,
                                        coloured by the GSEA p-value (darker green = more significant),
                                        and sorted top-to-bottom by ascending mean {{ridgeXLab}}. Hover over a
                                        distribution to see the GSEA adjusted p-value (FDR) and gene set size and
                                        click to generate a gene set heatmap. Use the sliders below to adjust the number
                                        of displayed gene sets and the density plot resolution.
                                    </p>
                                    <p *ngIf="funcOpt=='ora'" style="margin-top: 30px">
                                        Distributions of gene-level {{ridgeXLab}} for the top significant gene sets,
                                        coloured by the ORA p-value (darker green = more significant),
                                        and sorted top-to-bottom by ascending mean {{ridgeXLab}}. Hover over a
                                        distribution to see the ORA adjusted p-value (FDR) and gene set size and
                                        click to generate a gene set heatmap. Use the sliders below to adjust the number
                                        of displayed gene sets and the density plot resolution.
                                    </p>
                                    <app-ridgeline [data]="ridgeData" [xlabel]="ridgeXLab"
                                                   [pathNum]="sigPathNum" (newItemEvent)="plotPathway($event)"></app-ridgeline>
                                </p-tabPanel>
                                <p-tabPanel header="Results Table">
                                    <div style="width:90%; margin-top:30px; margin-left:15px">
                                        <p-table [columns]="funcColumns" [value]="funcResults" [autoLayout]="true"
                                                 [paginator]="true" [rows]="25" [showCurrentPageReport]="true"
                                                 currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                                 [rowsPerPageOptions]="[25, 50, 100]">
                                            <ng-template pTemplate="header" let-columns>
                                                <tr>
                                                    <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                                                        {{ col.header }}
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                                <tr>
                                                    <td *ngFor="let col of columns">
                                                        {{ rowData[col.field] }}
                                                    </td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </p-tabPanel>
                            </p-tabView>
                        </div>
                    </p-tabPanel>
                </p-tabView>
            </div>
        </div>
    </div>
</div>

<p-dialog header="Select Donors" [(visible)]="displayDonor" [style]="{width: '750px', height: '750px'}">
    <app-filter (emitFilterSuccess)="getFilterSuccess($event)"></app-filter>
    <ng-template pTemplate="footer">
        <button pButton type="button" icon="pi pi-check" (click)="displayDonor=false" label="OK"
                class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog id="static" header="{{currentFeature}}: {{currentDescription}}" [(visible)]="displayFeature"
          [style]="{width: '500px', height: '550px'}" (onHide)="clearFeature()">
    <div class="p-grid p-ai-center p-jc-center vertical-container" *ngIf="loadingImage == 'yes'"
         style="width:100%; height:100%">
        <p-progressSpinner></p-progressSpinner>
        <img alt="Place image title" *ngIf="imageToShow" [src]="imageToShow" width="400px">
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" icon="pi pi-check" (click)="clearFeature()" label="OK"
                class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog id="interactive" header="{{currentFeature}}: {{currentDescription}}" [(visible)]="displayInterFeature"
          [style]="{width: '500px', height: '550px'}" (onHide)="clearFeature()">
    <div class="p-grid p-ai-center p-jc-center vertical-container" *ngIf="loadingImage == 'yes'"
         style="width:100%; height:100%">
        <p-progressSpinner></p-progressSpinner>
    </div>
      <app-boxplot [boxData]="boxData" [primaryType]="primaryType" [selectedOmicsType]="selectedOmicsType" 
                   [currentFeature]="currentFeature" [selectedPrimary]="selectedPrimary" 
                   [metaLabels]="metaLabels" (newItemEvent)="queryDonor($event)"></app-boxplot>
 
    <ng-template pTemplate="footer">
          <button pButton type="button" label="CSV" (click)="dwlFeature('csv')" 
                class="custom-download"></button>
         <button pButton type="button" label="SVG" (click)="dwlFeature('svg')" 
                class="custom-download"></button>
         <button pButton type="button" label="PNG" (click)="dwlFeature('png')" 
                class="custom-download"></button>
         <button pButton type="button" label="PDF" (click)="dwlFeature('pdf')" 
                class="custom-download"></button>
        <button pButton type="button" icon="pi pi-check" (click)="clearFeature()" label="OK"
                class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="{{currentFeature}}: {{currentDescription}}" [(visible)]="displaySCFeature"
          [style]="{width: '600px', height: '100%'}" (onHide)="clearFeature()">
    <div class="p-grid p-ai-center p-jc-center vertical-container" *ngIf="loadingImage == 'yes'"
         style="width:100%; height:100%">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div>
        <img alt="Place image title" *ngIf="imageToShow" [src]="imageToShow" width="550px">
    </div>
    <ng-template pTemplate="footer">
         <button pButton type="button" label="CSV" (click)="dwlFeature('csv')" 
                class="custom-download"></button>
       <button pButton type="button" label="SVG" (click)="dwlFeature('svg')" 
                class="custom-download"></button>
        <button pButton type="button" label="PNG" (click)="dwlFeature('png')" 
                class="custom-download"></button>
         <button pButton type="button" label="PDF" (click)="dwlFeature('pdf')" 
                class="custom-download"></button>
        <button pButton type="button" icon="pi pi-check" (click)="clearFeature()" label="OK"
                class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Heatmap of pathway/set feature levels" [(visible)]="displayPathway" [modal]="true"
          [style]="{width: '1000px', height: '100%'}" (onHide)="clearFeature()">
    <div class="p-grid p-ai-center p-jc-center vertical-container" *ngIf="loadingImage == 'yes'"
         style="width:100%; height:100%">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div *ngIf="imageToShow" style="padding: 0px 20px 30px 20px">
        <p>Samples are sorted according to the primary metadata from the statistical analysis. Significant features are annotated with "***". 
            Donor IDs are shown if there are fewer than 50 donors. Feature levels are scaled (Z-score) across rows. Heatmap cells that correspond 
            to missing values are black. For pathways with many features, scroll down to see the entire heatmap.
        </p>
    </div>
    <div style="margin:auto; padding:auto; text-align: center;">
        <img alt="Place image title" *ngIf="imageToShow" [src]="imageToShow" width="90%">
    </div>
    <ng-template pTemplate="footer">
         <button pButton type="button" label="CSV" (click)="dwlFeature('csv')" 
                class="custom-download"></button>
        <button pButton type="button" label="SVG" (click)="dwlFeature('svg')" 
                class="custom-download"></button>
        <button pButton type="button" label="PNG" (click)="dwlFeature('png')" 
                class="custom-download"></button>
         <button pButton type="button" label="PDF" (click)="dwlFeature('pdf')" 
                class="custom-download"></button>
        <button pButton type="button" icon="pi pi-check" (click)="clearFeature()" label="OK"
                class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Select Donors" [(visible)]="displaySCDonor" [style]="{width: '750px', height: '750px'}">
    <app-scfilter (emitFilterSuccess)="getFilterSuccess($event)"></app-scfilter>
    <ng-template pTemplate="footer">
        <button pButton type="button" icon="pi pi-check" (click)="displaySCDonor=false" label="OK"
                class="p-button-text"></button>
    </ng-template>
</p-dialog>