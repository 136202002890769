import { Component, OnInit, ViewEncapsulation, Input, OnChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as d3 from "d3";
import { csvParse } from "d3";
import { HttpClient } from '@angular/common/http';
import { LineChart } from 'src/assets/javascript/d3-charts/multilines.js';
import { Subscription } from 'rxjs';
import { GlobalVarsService } from 'src/app/service/global-vars.service';

declare function greet(): void;

@Component({
  selector: 'mito-func',
  templateUrl: './mito-func.component.html',
  styleUrls: ['./mito-func.component.css'],
  encapsulation: ViewEncapsulation.None,  // see: https://jeffschoonover.dev/posts/2021/02/styling-d3-charts-angular-view-encapsulation/
})
export class MitoFuncComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() donorInf: any;
  @ViewChild('myIdentifier')
  myIdentifier: ElementRef;

  subBaseUrl: Subscription;
  baseUrl: string;
  subUsrDir: Subscription;
  usrDir: string;

  height: number = 615;
  width: number = 1400;
  data: any;
  numData: any;
  csvUrl: string;
  donorMissing: string = " ";
  colWidth: number = 250;
  svg: any;
  donorID: string;
  selectedVar: string = "calc_nonmito_oc";
  selType: string = 'dna';
  stateOptions: any[] = [{ label: 'Normalized to DNA', value: 'dna' }, { label: 'Normalized to DNA & Baseline OCR', value: 'baseline' }];
  ylab: string = "↑ OCR (pmol/min/µg DNA)";
  donorInfType: string;

  constructor(private http: HttpClient, private global: GlobalVarsService, private element: ElementRef) {
    this.donorInfType = typeof this.donorInf;
  }

  ngOnInit(): void {

    this.subBaseUrl = this.global.baseUrl.subscribe(data => {
      this.baseUrl = data
      this.csvUrl = this.baseUrl + "download/humanislets/display_data-mito_func.csv";
    })
  }

  ngAfterViewInit() {
    this.colWidth = this.myIdentifier.nativeElement.offsetWidth - 16; // determine plot width based on grid size
  }

  ngOnChanges() {
    this.donorID = this.donorInf.record_id

    if (typeof this.csvUrl != 'undefined') {
      this.main(this.csvUrl);
    }
  }

  main(csvUrl: string) {

    // line plot
    this.http.get(csvUrl, { responseType: 'text' }).subscribe(data => {
      this.data = csvParse(data);

      var filtData = this.data.filter(d => d['record_id'] == this.donorID)
      if (filtData.length == 0) {
        this.donorInfType = "undefined"
        this.donorMissing = "No mitochondrial function data for donor " + this.donorID
      } else {
        this.donorInfType = "defined"
        this.donorMissing = "Displaying data for donor " + this.donorID
      }

      d3.select("#mitoFunc").select("svg").remove();

      const chart = LineChart(this.data, {
        x: (d) => +d.time,
        y: d => +d.insulin,
        z: d => d.record_id,
        yLabel: this.ylab,
        width: this.colWidth,
        height: this.height,
        color: "#DD08F7",
        divID: "#mitoFunc",
        marginTop: 40,
        marginLeft: 60,
        xType: d3.scaleLinear,
        yType: d3.scaleSqrt,
        curve: d3.curveCardinal.tension(0.5),
        strokeOpacity: 0.5,
        donorID: this.donorID,
        dataType: 'mitofunc'
      });
    });

    this.makeHistograms();
  }

  updateLines(){
    if (this.selType == "dna") {
      this.csvUrl = this.baseUrl + "download/humanislets/display_data-mito_func.csv";
      this.ylab = "↑ OCR (pmol/min/µg DNA)";
      this.main(this.csvUrl)
    } else {
      this.csvUrl = this.baseUrl + "download/humanislets/display_data-mito_func_norm.csv";
      this.ylab = "↑ OCR (fold-change)";
      this.main(this.csvUrl)
    }
  }

  makeHistograms() {
    this.http.get(this.baseUrl + "download/humanislets/display_data-numerical_mito.csv", { responseType: 'text' }).subscribe(data => {
      this.numData = csvParse(data, d3.autoType);
      this.basicHistogram(this.numData, this.selectedVar, 220, 200, 15, "#SelectedHistogram", 25, "#0008ff", this.donorInf[this.selectedVar].value, this.donorInf[this.selectedVar].perc)
    })
  }


  // functions
  basicHistogram(inputData: any, varName: string, width: number, height: number, binPar: number, selectTerm: string,
    marginBottom: number, color: string, vert: number, perc: number) {

    if (typeof vert == "object") {
      vert = NaN;
    }

    d3.select(selectTerm).select("svg").remove();

    // remove NA - too many messes up histogram function
    inputData = inputData.filter(d => d[varName] != 'NA');

    var margin = { top: 0, right: 10, bottom: marginBottom, left: 10 }
    var compHeight = height - margin.bottom - margin.top;

    var xExtent = d3.extent(d3.map(inputData, d => d[varName]))

    // set the parameters for the histogram
    const bins = d3.bin()
      .thresholds(binPar)
      .value((d) => d[varName])
      (inputData);

    // Create the x-axis
    const x = d3.scaleLinear()
      .domain([bins[0].x0, bins[bins.length - 1].x1])
      .range([0, width - margin.right - margin.left]);

    // Create the y-axis
    var y = d3.scaleLinear()
      .range([compHeight, 0]);
    y.domain([0, d3.max(bins, function (d) { return d.length; })]);

    // Create SVG
    this.svg = d3.select(this.element.nativeElement)
      .select(selectTerm)
      .append('svg')
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
      .style("-webkit-tap-highlight-color", "transparent")
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    this.svg.append("g")
      .attr("transform", "translate(0," + compHeight + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)")
      .call(g => g.select(".domain").remove())

    this.svg.selectAll("rect")
      .data(bins)
      .enter()
      .append("rect")
      .attr("x", 0)
      .attr("transform", function (d) { return "translate(" + x(d.x0) + "," + y(d.length) + ")"; })
      .attr("width", function (d) { return x(d.x1) - x(d.x0); })
      .attr("height", function (d) { return compHeight - y(d.length); })
      .style("fill", color)
      .attr("fill-opacity", "0.6")

    // add donor-specific line
    if (!isNaN(vert)) {
      var percText = Math.round(perc * 100).toString() + "th perc."
      this.svg.append("line")
        .attr("y1", 0)
        .attr("y2", compHeight)
        .attr("x1", x(vert))
        .attr("x2", x(vert))
        .style("stroke-width", 2)
        .style("stroke", "black")
        .style("fill", "none");

      if (x(vert) > (width / 2)) {
        this.svg.append("text")
          .attr("x", x(vert) - 30)
          .attr("y", 15)
          .text(percText)
          .style("font-weight", "bold")
          .style("font-size", "12pt")
      } else {
        this.svg.append("text")
          .attr("x", x(vert) + 5)
          .attr("y", 15)
          .text(percText)
          .style("font-weight", "bold")
          .style("font-size", "12pt")
      }
    }

  }

}
