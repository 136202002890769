<div style="padding:15px">
    <div style="width:100%" class="p-grid">
        <div class="p-d-flex p-col-6 p-jc-start">
            <p-selectButton [options]="donorSelectOptions" [(ngModel)]="donorSelect" optionLabel="label"
                optionValue="value"></p-selectButton>
        </div>
        <div *ngIf="listExist" class="p-d-flex p-col-6 p-jc-end">
            <button pButton type="button" label="Download Donor List" class="p-button-warning" (click)="downloadDonorList()"
                icon="pi pi-download"></button>
        </div>
    </div>
    <div *ngIf="donorSelect=='filter'">
        <p style="padding:25px 0px">
            Note: if enabled, variable filters will exclude donors with missing values for that variable.
        </p>
        <div style="margin-left:25px">
            <div class="p-grid p-ai-center vertical-container">
                <div class="p-col-fixed" style="width:180px">
                    <p-checkbox [(ngModel)]="filterSex" [binary]="true"></p-checkbox>
                    <span style="font-weight: bold; margin-left:15px">Sex</span>
                </div>
                <div class="p-col-7">
                    <div class="field-checkbox">
                        <p-checkbox name="sex" value="Male" [(ngModel)]="selectedSex" inputId="1"
                            [disabled]="!filterSex"></p-checkbox>
                        <label for="male" style="margin-right:20px; margin-left:10px">Male</label>
                        <p-checkbox name="sex" value="Female" [(ngModel)]="selectedSex" inputId="2"
                            [disabled]="!filterSex"></p-checkbox>
                        <label for="female" style="margin-right:20px; margin-left:10px">Female</label>
                    </div>
                </div>
            </div>
            <div class="p-grid p-ai-center vertical-container" style="padding-top:40px;">
                <div class="p-col-fixed" style="width:180px">
                    <p-checkbox [(ngModel)]="filterDiagnosis" [binary]="true"></p-checkbox>
                    <span style="font-weight: bold; margin-left:15px">Diabetes diagnosis</span>
                </div>
                <div class="p-col-7">
                    <div class="field-checkbox">
                        <p-checkbox name="dtype" value="None" [(ngModel)]="selectedDtype" inputId="none"
                            [disabled]="!filterDiagnosis">
                        </p-checkbox>
                        <label for="none" style="margin-right:20px; margin-left:10px">No
                            diabetes</label>
                        <p-checkbox name="dtype" value="Type1" [(ngModel)]="selectedDtype" inputId="type1"
                            [disabled]="!filterDiagnosis">
                        </p-checkbox>
                        <label for="type1" style="margin-right:20px; margin-left:10px">Type 1</label>
                        <p-checkbox name="dtype" value="Type2" [(ngModel)]="selectedDtype" inputId="type2"
                            [disabled]="!filterDiagnosis">
                        </p-checkbox>
                        <label for="type2" style="margin-right:20px; margin-left:10px">Type 2</label>
                    </div>
                </div>
            </div>
            <div class="p-grid p-ai-center vertical-container" style="padding-top:40px">
                <div class="p-col-fixed" style="width:180px">
                    <p-checkbox [(ngModel)]="filterAge" [binary]="true"></p-checkbox>
                    <span style="font-weight: bold; margin-left:15px">Age
                        <a href="javascript: void(0);" (click)="displayAge=true" pTooltip="Click to type range"
                            tooltipPosition="bottom" *ngIf="filterAge">({{ageValues[0] + '
                            - ' +
                            ageValues[1]}})</a></span>
                </div>
                <div class="p-col-7">
                    <p-slider [(ngModel)]="ageValues" [range]="true" [disabled]="!filterAge"></p-slider>
                </div>
            </div>
            <div class="p-grid p-ai-center vertical-container" style="padding-top:40px">
                <div class="p-col-fixed" style="width:180px;">
                    <p-checkbox [(ngModel)]="filterBMI" [binary]="true"></p-checkbox>
                    <span style="font-weight: bold; margin-left: 15px">BMI
                        <a href="javascript: void(0);" (click)="displayBMI=true" pTooltip="Click to type range"
                            tooltipPosition="bottom" *ngIf="filterBMI">({{bmiValues[0] + '
                            - ' +
                            bmiValues[1]}})</a></span>
                </div>
                <div class="p-col-7">
                    <p-slider [(ngModel)]="bmiValues" [range]="true" [min]="15" [max]="50" [disabled]="!filterBMI"></p-slider>
                </div>
            </div>
            <div class="p-grid p-ai-center vertical-container" style="padding-top:40px;">
                <div class="p-col-fixed" style="width:180px;">
                    <p-checkbox [(ngModel)]="filterHba1c" [binary]="true"></p-checkbox>
                    <span style="font-weight: bold; margin-left: 15px">HbA1c
                        <a href="javascript: void(0);" (click)="displayHba1c=true" pTooltip="Click to type range"
                            tooltipPosition="bottom" *ngIf="filterHba1c">({{hba1cValues[0] +
                            ' - ' +
                            hba1cValues[1]}})</a></span>
                </div>
                <div class="p-col-7">
                    <p-slider [(ngModel)]="hba1cValues" [range]="true" [min]="3" [max]="15"
                        [disabled]="!filterHba1c"></p-slider>
                </div>
            </div>
        </div>
    </div>
    
    <div *ngIf="donorSelect=='upload'">
        <div class="p-grid" style="margin-top: 20px">
            <div class="p-col-12 p-grid">
                <div class="p-col-12 p-ai-center">
                    <label style="padding: 10px; font-size: 14px">ID type:</label>
                    <p-dropdown [options]="IDType" [(ngModel)]="selectedIDType"></p-dropdown>
                    <button pButton type="button" label="Try Example" class="p-button-text" (click)="loadExample()"
                        [style]="{'margin-left': '15px'}"></button>
                </div>
                <div class="p-col-12" style="margin-top: 20px; margin-left: 25px">
                    <textarea rows="15" cols="30" [(ngModel)]="uploadIds" pInputTextarea
                        placeholder="Copy-and-paste a list of donor IDs"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="p-grid p-ai-center p-jc-left vertical-container" style="padding-top:50px;">
        <div class="p-col-fixed" style="width:150px">
            <button pButton pRipple type="button" label="Submit" (click)="getDonors(donorSelect)"></button>
        </div>
        <div class="p-col">
            <p-messages [(value)]="currentMsg" [enableService]="false"></p-messages>
        </div>
    </div>
</div>


<p-dialog header="Input Age range" [(visible)]="displayAge" [style]="{width: '500px'}">
    Include donors with ages from
    <input class="p-inputnumber-sm" [(ngModel)]="ageValues[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="ageValues[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
        <button pButton type="button" icon="pi pi-check" (click)="displayAge=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input BMI range" [(visible)]="displayBMI" [style]="{width: '500px'}">
    Include donors with BMI values from
    <input class="p-inputnumber-sm" [(ngModel)]="bmiValues[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="bmiValues[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
        <button pButton type="button" icon="pi pi-check" (click)="displayBMI=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input HbA1c range" [(visible)]="displayHba1c" [style]="{width: '500px'}">
    Include donors with HbA1c values from
    <input class="p-inputnumber-sm" [(ngModel)]="hba1cValues[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="hba1cValues[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
        <button pButton type="button" icon="pi pi-check" (click)="displayHba1c=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>