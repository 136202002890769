import { Component, OnInit, ViewEncapsulation, OnChanges, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import * as d3 from "d3";

@Component({
    selector: 'app-ridgeline',
    templateUrl: './app.ridgeline.component.html',
    styleUrls: ['./app.ridgeline.component.css'],
    encapsulation: ViewEncapsulation.None,  // see: https://jeffschoonover.dev/posts/2021/02/styling-d3-charts-angular-view-encapsulation/
})
export class AppRidgelineComponent implements OnInit, OnChanges {

    @Input() data: any;
    @Input() xlabel: string;
    @Input() pathNum: string;
    @Output() newItemEvent = new EventEmitter<string>();

    private svg: any;
    private tooltip: any;

    height: number = 1000;
    width: number = 1200;

    marginTop: number = 75;
    marginRight: number = 20;
    marginBottom: number = 50;
    marginLeft: number = 400;
    yCol: string = "Gene Set";
    xCol: string = "GeneStat";
    colorCol: string = "sig";
    yLabel: string = "-Log10(p-value)";
    categories: any;
    allMeans: any;

    resolution: number = 15;
    nPathways: number = 20;
    rowHeight: number = 50;
    densHeight: number = 50;
    totalPaths: number = 100;

    constructor(private element: ElementRef) {
    }

    ngOnInit() {
        this.nPathways = Number(this.pathNum)
    }

    ngOnChanges() {

        if (typeof this.data !== 'undefined') {
            var paths = this.data.map(d => d["Set Name"]);
            this.totalPaths = [...new Set(paths)].length; // get number of pathways in results
        }

        this.render(this.data);
    }

    render(data: any) {

        if (typeof data !== 'undefined') {

            d3.select(".ridgeline").select("svg").remove();
            d3.select(".ridgeline").selectAll(".chart-tooltip").remove();

            this.height = (this.nPathways * this.rowHeight) + this.marginBottom + this.marginTop;

            // subset array to only keep top 20 pws
            data = data.filter(d => d.RankSig <= this.nPathways);

            var pathVars = JSON.parse(JSON.stringify(data));
            pathVars = pathVars.map(function (obj) {
                delete obj.RankSig;
                delete obj.GeneStat;
                delete obj.GeneID;
                return obj;
            });

            pathVars = [...new Map(pathVars.map((item) => [item["Set ID"], item])).values()];

            // get unique names
            this.categories = data.map(d => d["Set Name"]);
            this.categories = [...new Set(this.categories)];
            var nameLength = this.categories.map(d => d.length);
            var maxLength = Math.max(...[].concat(...nameLength))
            this.marginLeft = 7 * maxLength


            // get means for categories
            this.allMeans = data.map(d => d.MeanGeneStat);
            this.allMeans = [...new Set(this.allMeans)]

            // Create a color scale using these means.
            const myColor = d3.scaleSequential()
                .domain(d3.extent(d3.map(data, d => d['pathNegLogP'])))
                .interpolator(d3.interpolateGreens);

            // Define x axis
            var xExtent = d3.extent(d3.map(data, d => d[this.xCol]))
            var xMag = xExtent[1] - xExtent[0]

            const x = d3.scaleLinear()
                .domain([(xExtent[0] - 0.2 * xMag), (xExtent[1] + 0.2 * xMag)]) // need to be a bit larger than extent because density profiles extend past
                .range([this.marginLeft, this.width - this.marginRight]);

            // Create the Y axis for names
            const y = d3.scalePoint()
                .domain(this.categories)
                .range([this.marginTop, this.height - this.marginBottom])

            // Compute kernel density estimation for each column:
            var kde = this.kernelDensityEstimator(this.kernelEpanechnikov(xMag / this.resolution), x.ticks(40))
            var allDensity = []
            for (var i = 0; i < this.categories.length; i++) {
                var key = this.categories[i]
                var path = data.filter(d => d["Set Name"] == key)
                var density = kde(path.map(function (d) { return d['GeneStat'] }))
                allDensity.push({ key: key, density: density })
            }

            // Create a Z scale for densities
            var allDens = allDensity.map(d => d.density.map(b => b[1]));
            var maxDens = Math.max(...[].concat(...allDens))
            const z = d3.scaleLinear()
                .domain([0, maxDens * 0.7 * (50/this.densHeight)]) // ylim 
                .range([y.step(), 0]); // how tall we want mini y-axes to be

            // Create SVG
            this.svg = d3.select(this.element.nativeElement)
                .select('.ridgeline')
                .append('svg')
                .attr("width", this.width)
                .attr("height", this.height)
                .attr("viewBox", [0, 0, this.width, this.height])
                .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
                .style("-webkit-tap-highlight-color", "transparent");

            // Create tooltip container
            this.tooltip = d3.select(this.element.nativeElement)
                .select('.ridgeline')
                .append("div")
                .classed("chart-tooltip", true)
                .html("HI")
                .style("display", "none");

            // Add axes to svg
            this.svg.append("g")
                .attr("transform", `translate(0,${this.height - this.marginBottom})`)
                .call(d3.axisBottom(x)
                    .ticks(this.width / 80)
                    .tickSizeOuter(0))
                .call(g => g.append("text")
                    .attr("x", ((this.width - this.marginLeft - this.marginRight)/2) + this.marginLeft)
                    .attr("y", this.marginBottom - 5)
                    .attr("fill", "currentColor")
                    .attr("text-anchor", "middle")
                    .text("Gene-level " + this.xlabel));

            this.svg.append("g")
                .attr("transform", `translate(${this.marginLeft},0)`)
                .call(d3.axisLeft(y).tickSize(0).tickPadding(4))
                .call(g => g.select(".domain").remove())


            // Create the plot
            var thisRef = this;
            var lastColor;
            this.svg.append("g")
                .selectAll("areas")
                .data(allDensity)
                .enter()
                .append("path")
                .attr("transform", function (d) { return ("translate(0," + (y(d.key) - y.step()) + ")") })
                .attr("fill", function (d) {
                    var index = thisRef.categories.indexOf(d.key)
                    var value = pathVars[index]['pathNegLogP']
                    return myColor(value)
                })
                .attr("pathName", function (d) {
                    return d.key
                })
                .attr("Set_Size", function (d) {
                    var index = thisRef.categories.indexOf(d.key)
                    return pathVars[index]['Set Size']
                })
                .attr("pathFDR", function (d) {
                    var index = thisRef.categories.indexOf(d.key)
                    return pathVars[index]['pathFDR']
                })
                .on('mouseover', function (d) {
                    lastColor = d.target.attributes['fill'].nodeValue
                    //d.target.attributes['pathName'].nodeValue  <-- this is how to access the pathway name of the density plot
                    d3.select(this)
                        .style("fill", "#d3d3d3");
                    d3.selectAll('.chart-tooltip')
                        .style("left", event['offsetX'] + 15 + "px")
                        .style("top", event['offsetY'] - 25 + "px")
                        .style("display", "block")
                        .html("<strong>FDR: </strong>" + d.target.attributes['pathFDR'].nodeValue + "</br> <strong>Size: </strong>" + d.target.attributes['Set_Size'].nodeValue);
                })
                .on('mousemove', function () {
                    d3.selectAll('.chart-tooltip')
                        .style("left", event['offsetX'] + 15 + "px")
                        .style("top", event['offsetY'] - 25 + "px")
                })
                .on('mouseout', function (d) {
                    d3.select(this)
                        .style("fill", lastColor);
                    d3.selectAll('.chart-tooltip')
                        .style("display", "none")
                })
                .datum(function (d) { return (d.density) })
                .attr("opacity", 1)
                .attr("stroke", "#6F7378")
                .attr("stroke-width", 0.5)
                .attr("d", d3.line()
                    .curve(d3.curveBasis)
                    .x(function (d) { return x(d[0]); })
                    .y(function (d) { return z(d[1]); })
                )
                .on('click', function (d) {
                    var clickRes = d.target.attributes[2].value;
                   console.log(clickRes)
                    thisRef.newItemEvent.emit(clickRes);
                });

            this.svg.append("line")
                .attr("y1", (this.height - this.marginBottom - 5))
                .attr("y2", this.marginTop - y.step())
                .attr("x1", x(0))
                .attr("x2", x(0))
                .attr("stroke", "#6F7378")
                .style("stroke-width", 2)
                .style("stroke-dasharray", ("10,5"))
        }
    }

    // This is what I need to compute kernel density estimation
    kernelDensityEstimator(kernel: any, X: any) {
        return function (V) {
            return X.map(function (x) {
                return [x, d3.mean(V, function (v: any) { return kernel(x - v); })];
            });
        };
    }

    kernelEpanechnikov(k) {
        return function (v) {
            return Math.abs(v /= k) <= 1 ? 0.75 * (1 - v * v) / k : 0;
        };
    }

}
