<div class="p-grid p-ai-top p-ai-stretch vertical-container" style="margin:20px">
    <div class="p-col-12" style="padding-bottom: 0px">
        <div class="p-grid" style="padding:0px 0px 0px 0px">
            <div class="p-col-12 donor-view" style="margin-bottom:20px; min-height: 168px">
                <div class="p-grid">
                    <div class="p-col-3 p-d-flex p-ai-center p-jc-center" style="min-height: 160px;">
                        <div class="p-grid">
                            <div class="p-col-12 p-d-flex p-ai-center p-jc-center">
                                <span style="font-size:44px; font-weight: bolder;">{{donorID}}</span>
                            </div>
                            <div *ngIf="rrid != 'NA'" class="p-col-12 p-d-flex p-ai-center p-jc-center">
                                <span style="font-size:20px;">(RRID: <a href="https://www.ncbi.nlm.nih.gov/biosample/{{rrid}}" target='_blank'>{{rrid}})</a></span>
                            </div>
                            <div *ngIf="rrid == 'NA'" class="p-col-12 p-d-flex p-ai-center p-jc-center">
                                <span style="font-size:20px;">(No RRID available)</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-col-3 p-d-flex p-ai-center p-jc-center" style="min-height: 160px;">
                        <ul style="font-size: 14pt;">
                            <li style="margin-bottom:10px"><strong>Diagnosis: </strong>{{diagnosis}}</li>
                            <li><strong>Adjusted diabetes status: </strong>{{adjStatus}}</li>
                        </ul>
                    </div>
                    <div class="p-col-3 p-d-flex p-ai-center p-jc-center" style="min-height: 160px;">
                        <ul style="font-size: 14pt;">
                            <li style="margin-bottom:10px"><strong>Sex: </strong>{{sex}}</li>
                            <li><strong>HLA A2: </strong>{{hla}}</li>
                        </ul>
                    </div>
                    <div class="p-col-3 p-d-flex p-ai-center p-jc-center" style="min-height: 160px;">
                    <ul style="font-size: 14pt;">
                        <li style="margin-bottom:10px"><strong>Cryopreserved tubes:</strong> {{cryotubesremaining}}</li>
                        <li><strong>Snap-frozen tubes: </strong>{{sftubesremaining}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div #myIdentifier class="p-col-4" style="padding:0px">
            <div class="donor-view" style="min-height:520px; text-align: center; margin: 0px 14px 0px 0px">
                <div style="text-align: center; margin-top:60px">
                    <span style="font-size: 1.5rem; font-weight: 600;">HbA1c: </span>
                    <span style="font-size: 1.5rem;">{{hba1c}}<span *ngIf="isNumber(this.hba1c)">%</span></span>
                </div>
                <div id="Hba1cHistogram" class="histogram" style="margin-top: 60px; align-content: center;"></div>
                <span style="text-align: center;">Percent</span>
            </div>
        </div>
        <div class="p-col-4" style="padding:0px">
            <div class="donor-view" style="min-height:520px; text-align: center; margin: 0px 7px 0px 7px">
                <div style="text-align: center; margin-top:60px">
                    <span style="font-size: 1.5rem; font-weight: 600;">Age: </span>
                    <span style="font-size: 1.5rem;">{{age}} <span *ngIf="isNumber(this.age)">years</span></span>
                </div>
                <div id="AgeHistogram" class="histogram" style="margin-top: 60px; align-content: center;"></div>
                <span style="text-align: center;">Years</span>
            </div>
        </div>
        <div class="p-col-4" style="padding:0px">
            <div class="donor-view" style="min-height:520px; text-align: center; margin: 0px 0px 0px 14px">
                <div style="text-align: center; margin-top:60px">
                    <span style="font-size: 1.5rem; font-weight: 600;">BMI: </span>
                    <span style="font-size: 1.5rem;">{{bmi}}</span>
                </div>
                <div id="BMIHistogram" class="histogram" style="margin-top: 60px; align-content: center;"></div>
                <span style="text-align: center;">Index</span>
            </div>
        </div>
    </div>
</div>
</div>