import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalVarsService {

  // when we set variables as observables, we can access them from any component by subscribing to them
  // this allows us to define global variables
  // here, we want to always use the same user directory and API URL throughout the entire tool

  private usrDirSource = new BehaviorSubject('NA');
  private baseUrlSource = new BehaviorSubject('NA');
  
  currentUsrDir = this.usrDirSource.asObservable();
  baseUrl = this.baseUrlSource.asObservable();

  constructor() { 
    const currentUrl = window.location.href;
    if (currentUrl.includes("localhost")) {
        console.log("localhost")
        this.baseUrlSource.next("http://localhost:8080/restxialab/api/");
    } else {
        console.log("web")
        this.baseUrlSource.next("https://api2.xialab.ca/api/");
    }
  }

  changeUsrDir(usrDir: string) {
    this.usrDirSource.next(usrDir)
  }

}

