import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { MessageService, Message } from 'primeng/api';
import { AppMainComponent } from '../app.main.component';
import { RestAPIService } from '../service/rest-api.service';
import { Subscription } from 'rxjs';
import { GlobalVarsService } from '../service/global-vars.service';
import { DomSanitizer } from '@angular/platform-browser';

interface City {
    name: string,
    code: string
}

@Component({
    selector: 'app-download',
    templateUrl: './app.download.component.html',
    providers: [MessageService],
})

export class AppDownloadComponent implements OnInit, OnDestroy {

    testString: string;

    // usrDir variable
    subUsrDir: Subscription;
    usrDir: string;

    host: string = "public";

    // view image variables
    imageToShow: any;
    featureImage: string = "";
    loadingImage: string = "no";
    base64Image: any;

    // data type paramters
    dataType: SelectItemGroup[];
    dataTypeReviewer: SelectItemGroup[];
    selectedDataType: any[];

    fileType: SelectItem[];
    selectedFileType: string = "csv";

    showDataInfo: string;
    displayDataSummary: boolean = false;
    displayPatchSeq: boolean = false;
    loadingPatch: string = "no";
    // step booleans
    step2: boolean = false;

    zipFile: string;
    msgText: string;
    currentMsg: Message[];

    dataStats: string;
    numDonors: string;
    printSummary: string;
    dwnld_loading: boolean = false;
    figNm: string;

    constructor(public appMain: AppMainComponent, private apiService: RestAPIService, private domSanitizer: DomSanitizer, private global: GlobalVarsService,
        private messageService: MessageService) {

        this.fileType = [
            { label: 'Comma separated values (.csv)', value: 'csv' },
            { label: 'Text file (.txt)', value: 'txt' },
            { label: 'R object (.rds)', value: 'rds' },
            { label: 'Excel (.xlsx)', value: 'xlsx' }
        ];

        this.dataType = [
            {
                label: 'Donor Metadata', value: 'metadata',
                items: [
                    { label: 'Donor Information', value: 'donor' },
                    { label: 'Isolation Information', value: 'isolation' },
                ]
            },
            {
                label: 'Insulin Secretion', value: 'insulin',
                items: [
                    { label: 'GSIS', value: 'gsis' },
                    { label: 'Glucose Perifusions', value: 'peri_gluc' },
                    { label: 'Leucine Perifusions', value: 'peri_leu' },
                    { label: 'OLP Perifusions', value: 'peri_olp' }
                ]
            },
            {
                label: 'Other Outcomes', value: 'other',
                items: [
                    { label: 'Electrophysiology Outcomes', value: 'ephys_donor' },
                    { label: 'Mitochondrial Function', value: 'seahorse' },
                ]
            },
            {
                label: 'Omics Data', value: 'omics',
                items: [
                    { label: 'Gene Expression (Nanostring)', value: 'proc_nanostring_merge' },
                    { label: 'Proteomics', value: 'proc_prot' },
                    { label: 'Bulk RNAseq', value: 'proc_rnaseq' },
                    { label: 'Pseudobulk RNAseq (alpha cells)', value: 'proc_pbrna_Alpha' },
                    { label: 'Pseudobulk RNAseq (beta cells)', value: 'proc_pbrna_Beta' }
                ]
            }
        ];

        this.dataTypeReviewer = [
            {
                label: 'Donor Metadata', value: 'metadata',
                items: [
                    { label: 'Donor Information', value: 'donor' },
                    { label: 'Isolation Information', value: 'isolation' },
                ]
            },
            {
                label: 'Insulin Secretion', value: 'insulin',
                items: [
                    { label: 'GSIS', value: 'gsis' },
                    { label: 'Glucose Perifusions', value: 'peri_gluc' },
                    { label: 'Leucine Perifusions', value: 'peri_leu' },
                    { label: 'OLP Perifusions', value: 'peri_olp' }
                ]
            },
            {
                label: 'Omics', value: 'omics',
                items: [
                    { label: 'Proteomics', value: 'proc_prot' },
                ]
            }
        ];
    }

    ngOnInit() {
        this.subUsrDir = this.global.currentUsrDir.subscribe(data => this.usrDir = data)

        if (this.usrDir === "NA") {
            this.apiService.getLogin().subscribe(data => {
                this.usrDir = data;
                this.global.changeUsrDir(this.usrDir)
            })
        }

        const currentUrl = window.location.href;
        if (currentUrl.includes("localhost")) {
            this.host = "local";
        } else {
            this.host = "public";
        }
this.removeCookieManager();
    }

    ngOnDestroy() {
        this.subUsrDir.unsubscribe();
    }


    // define functions
    downloadTables() {
        this.dwnld_loading = true;
         
        // create files on server
        this.apiService.getDataTables(this.usrDir, this.selectedDataType.toString(), this.selectedFileType).subscribe(data => {
            console.log(data)
            this.zipFile = data;
            if (this.zipFile.includes("RES-OK")) {
                this.zipFile = this.zipFile.replace("RES-OK", "")
                this.dwnld_loading = false;
                //download file
                this.apiService.getDownload(this.usrDir, this.zipFile).subscribe(response => {
                    let fileName = response.headers.get('content-disposition')
                        ?.split(';')[1].split('=')[1];
                    let blob: Blob = response.body as Blob;
                    let a = document.createElement('a');
                    a.download = this.zipFile;
                    a.href = window.URL.createObjectURL(blob);
                    a.click();
                });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'API server failed to return results' });
            }
        })

    }

    downloadPatchSeq(filenm: string) { 
         this.loadingImage = 'yes';
         console.log(filenm,"filenm")
         this.apiService.getPatchScTables(this.usrDir, filenm).subscribe(data => {
            this.zipFile = data;
            if (this.zipFile.includes("RES-OK")) {
                this.zipFile = this.zipFile.replace("RES-OK", "")  
                //download file
               this.apiService.getDownload(this.usrDir, this.zipFile).subscribe(response => {
               let fileName = response.headers.get('content-disposition')
                        ?.split(';')[1].split('=')[1];
                    let blob: Blob = response.body as Blob;
                    let a = document.createElement('a');
                    a.download = this.zipFile;
                    a.href = window.URL.createObjectURL(blob);
                    this.loadingImage = 'no';
                    a.click();
        });
            } else {
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'API server failed to return results' });
            }
        })
       
    }

    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.base64Image = reader.result;
            this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(this.base64Image);
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    dataSummary() {
        this.loadingImage = 'yes';
        this.imageToShow = '';

        if (!this.selectedDataType) {
            this.loadingImage = 'no';
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'You must select at least one data type!' });
        } else {
            this.displayDataSummary = true;
            this.apiService.getDataSummary(this.usrDir, this.selectedDataType.toString()).subscribe(data => {
                this.dataStats = data;
                if (this.dataStats.includes("RES-OK")) {
                    this.dataStats = this.dataStats.replace("RES-OK", "")
                    this.numDonors = this.dataStats.split(";")[0]
                    this.printSummary = "There are " + this.numDonors + " donors in your selected data."
                    this.figNm = this.dataStats;
                    this.apiService.streamImage(this.usrDir, "data_summary.png").subscribe(response => {
                        this.createImageFromBlob(response);
                        this.loadingImage = 'no';
                    }, error => {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'API server failed to return results' });
                        this.loadingImage = 'no';
                    });
                }
            })
        }
    }

    resetData() {
        window.location.reload();
    }

    getFilterSuccess(input: boolean) {
        this.step2 = input;
    }


    dwlFeature(imgType: string) {
             let dateTimeStamp = this.getLocalDateTimeStamp();
        let fileName = "data_summary." + imgType
        console.log(this.figNm)
        this.apiService.getDownload(this.usrDir, fileName).subscribe(response => {
            let contentDisposition = response.headers.get('content-disposition');


            if (contentDisposition) {
                let matches = contentDisposition.match(/filename="?([^"]+)"?/);
                if (matches && matches.length > 1) {
                    fileName = matches[1];
                }
            }
             fileName = fileName.replace(/(\.[^\.]+)$/, `_${dateTimeStamp}$1`);
            let blob = new Blob([response.body], { type: imgType });

            let a = document.createElement('a');
            a.style.display = 'none';
            document.body.appendChild(a);

            a.download = fileName;
            a.href = window.URL.createObjectURL(blob);

            a.click();
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);

        });

    }
 getLocalDateTimeStamp() {
    let now = new Date();
    let year = now.getFullYear();
    let month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    let day = String(now.getDate()).padStart(2, '0');
    let hours = String(now.getHours()).padStart(2, '0');
    let minutes = String(now.getMinutes()).padStart(2, '0');
    let seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}-${minutes}-${seconds}`;
}
 removeCookieManager() {
   console.log(document.querySelector('#prebannerBtn'))
     const banner = document.querySelector('#prebannerBtn') as HTMLElement;
if (banner) {
  banner.style.display = 'none'; // Hides the element completely
}
}
}
