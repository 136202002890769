<div class="layout-sidebar" [ngStyle]="{'overflow':appMain.sidebarActive ? 'hidden' : 'visible'}"
                            [ngClass]="{'layout-sidebar-dark':true}" (click)="appMain.onSidebarClick($event)">
    <div class="layout-tabmenu">
        <ul class="layout-tabmenu-nav">
            <li [ngClass]="{'active-item':appMain.activeTabIndex === 0}">
                <a routerLink="" pRipple class="tabmenuitem-link" (click)="appMain.closeSidebar($event, 0)"><i class="pi pi-fw pi-home"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Home</div>
                </div>
            </li>             
            <!-- <li [ngClass]="{'active-item':appMain.activeTabIndex === 1}">
                <a routerLink="/singlevar" pRipple class="tabmenuitem-link" (click)="appMain.closeSidebar($event, 1)"><i class="pi pi-fw pi-chart-bar"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Single-variable</div>
                </div>
            </li> -->
            <li [ngClass]="{'active-item':appMain.activeTabIndex === 2}">
                <a routerLink="/omics" pRipple class="tabmenuitem-link" (click)="appMain.closeSidebar($event, 2)"><i class="pi pi-fw pi-chart-line"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Omics View</div>
                </div>
            </li>
            <!-- <li [ngClass]="{'active-item':appMain.activeTabIndex === 3}">
                <a routerLink="/multiomics" pRipple class="tabmenuitem-link" (click)="appMain.closeSidebar($event, 3)"><i class="pi pi-fw pi-sliders-h"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Multi-omic anlaysis</div>
                </div>
            </li>  -->           
            <li [ngClass]="{'active-item':appMain.activeTabIndex === 5}">
                <a routerLink="/donor" pRipple class="tabmenuitem-link" (click)="appMain.closeSidebar($event, 5)"><i class="pi pi-fw pi-user"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Donor View</div>
                </div>
            </li>    
            <li [ngClass]="{'active-item':appMain.activeTabIndex === 4}">
                <a routerLink="/feature" pRipple class="tabmenuitem-link" (click)="appMain.closeSidebar($event, 4)"><i class="pi pi-fw pi-search"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Feature View</div>
                </div>
            </li>                   
            <li [ngClass]="{'active-item':appMain.activeTabIndex === 6}">
                <a routerLink="/download" pRipple class="tabmenuitem-link" (click)="appMain.closeSidebar($event, 6)"><i class="pi pi-fw pi-download"></i></a>
                <div class="layout-tabmenu-tooltip">
                    <div class="layout-tabmenu-tooltip-arrow"></div>
                    <div class="layout-tabmenu-tooltip-text">Data Download</div>
                </div>
            </li>             
        </ul>
        <div class="layout-tabmenu-contents">
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': appMain.activeTabIndex === 0}">
                <app-sidebarTabContent>
                </app-sidebarTabContent>
            </div>            
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': appMain.activeTabIndex === 1}">
                <app-sidebarTabContent>
                    <div class="projects-tab">
                        <ul>
                            <li class="clearfix">
                                <a routerLink="/singlevar" (click)="onAnalysisMenuClick(1)" [className]="analysisState == 'vis' ? 'menu-item-select' : 'menu-item'">
                                    <div class="p-d-flex p-ai-center">
                                        <i class="pi pi-eye"></i>
                                        <span>Visualize the data</span> <br>
                                    </div>
                                </a>
                            </li>
                            <li class="clearfix">
                                <a routerLink="/singlevar" (click)="onAnalysisMenuClick(2)" [className]="analysisState == 'stats' ? 'menu-item-select' : 'menu-item'">  
                                    <div class="p-d-flex p-ai-center">
                                        <i class="pi pi-chart-line"></i>
                                        <span class="project-title">Perform statistical tests</span> <br>
                                    </div>
                                </a>
                            </li>                            
                            <li class="clearfix">
                                <a routerLink="/singlevar" (click)="onAnalysisMenuClick(3)" [className]="analysisState == 'export' ? 'menu-item-select' : 'menu-item'">   
                                    <div class="p-d-flex p-ai-center">
                                        <i class="pi pi-external-link"></i>
                                        <span>Export the results</span> <br>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </app-sidebarTabContent>
            </div>                      
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': appMain.activeTabIndex === 2}">
                <app-sidebarTabContent>
                    <div class="projects-tab">
                        <ul>
                            <li class="clearfix">
                                <a routerLink="/multivar" (click)="onAnalysisMenuClick(1)" [className]="analysisState == 'vis' ? 'menu-item-select' : 'menu-item'">
                                    <div class="p-d-flex p-ai-center">
                                        <i class="pi pi-eye"></i>
                                        <span class="project-title">Visualize the data</span> <br>
                                    </div>
                                </a>
                            </li>
                            <li class="clearfix">
                                <a routerLink="/multivar" (click)="onAnalysisMenuClick(2)" [className]="analysisState == 'stats' ? 'menu-item-select' : 'menu-item'">  
                                    <div class="p-d-flex p-ai-center">
                                        <i class="pi pi-chart-line"></i>
                                        <span class="project-title">Perform statistical tests</span> <br>
                                    </div>
                                </a>
                            </li>
                            <li class="clearfix">
                                <a routerLink="/multivar" (click)="onAnalysisMenuClick(3)" [className]="analysisState == 'export' ? 'menu-item-select' : 'menu-item'"> 
                                    <div class="p-d-flex p-ai-center">
                                        <i class="pi pi-external-link"></i>
                                        <span class="project-title">Export the results</span> <br>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </app-sidebarTabContent>
            </div> 
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': appMain.activeTabIndex === 3}">
                <app-sidebarTabContent>
                    <div class="projects-tab">
                        <ul>
                            <li class="clearfix">
                                <a routerLink="/multiomics" (click)="onAnalysisMenuClick(1)" [className]="analysisState == 'vis' ? 'menu-item-select' : 'menu-item'">
                                    <div class="p-d-flex p-ai-center">
                                        <i class="pi pi-eye"></i>
                                        <span class="project-title">Visualize the data</span> <br>
                                    </div>
                                </a>
                            </li>
                            <li class="clearfix">
                                <a routerLink="/multiomics" (click)="onAnalysisMenuClick(2)" [className]="analysisState == 'stats' ? 'menu-item-select' : 'menu-item'">  
                                    <div class="p-d-flex p-ai-center">
                                        <i class="pi pi-chart-line"></i>
                                        <span class="project-title">Perform statistical tests</span> <br>
                                    </div>
                                </a>
                            </li>
                            <li class="clearfix">
                                <a routerLink="/multiomics" (click)="onAnalysisMenuClick(3)" [className]="analysisState == 'export' ? 'menu-item-select' : 'menu-item'"> 
                                    <div class="p-d-flex p-ai-center">
                                        <i class="pi pi-external-link"></i>
                                        <span class="project-title">Export the results</span> <br>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': appMain.activeTabIndex === 4}">
                <app-sidebarTabContent>
                </app-sidebarTabContent>
            </div>
            <div class="layout-tabmenu-content" [ngClass]="{'layout-tabmenu-content-active': appMain.activeTabIndex === 5}">
                <app-sidebarTabContent>
                </app-sidebarTabContent>
            </div>            
        </div>
    </div>
</div>
