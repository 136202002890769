import { Component, OnInit, ViewEncapsulation, OnChanges, Input, ElementRef, Output, EventEmitter } from '@angular/core';
import * as d3 from "d3";

@Component({
    selector: 'app-volcano',
    templateUrl: './app.volcano.component.html',
    styleUrls: ['./app.volcano.component.css'],
    encapsulation: ViewEncapsulation.None,  // see: https://jeffschoonover.dev/posts/2021/02/styling-d3-charts-angular-view-encapsulation/
})
export class AppVolcanoComponent implements OnInit, OnChanges {

    @Input() data: any;
    @Input() xCol: string;
    @Output() newItemEvent = new EventEmitter<string>();
   @Output() pointClicked = new EventEmitter<string>();

    private svg;
    private tooltip: any;

    height: number = 600;
    width: number = 1200;

    marginTop: number = 50;
    marginRight: number = 20;
    marginBottom: number = 50;
    marginLeft: number = 60;
    yCol: string = "negLogPval";
    colorCol: string = "sig";
    yLabel: string = "-Log10(p-value)";
    divID: string = "#deaScatter";

    constructor(private element: ElementRef) {
    }

    ngOnInit() { }

    ngOnChanges() {

        // clear previous svg if it exists
        d3.select(".scatterPlot").select("svg").remove();
        d3.select(".scatterPlot").select(".chart-tooltip").remove();
        
        this.createSvg();
        this.render();
    }

    private createSvg() {

        this.svg = d3.select(this.element.nativeElement)
            .select('.scatterPlot')
            .append('svg')
            .attr("width", this.width)
            .attr("height", this.height)
            .attr("viewBox", [0, 0, this.width, this.height])
            .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
            .style("-webkit-tap-highlight-color", "transparent");

        this.tooltip = d3.select(this.element.nativeElement)
            .select('.scatterPlot')
            .append("div")
            .classed("chart-tooltip", true)
            .style("display", "none");
    }

    private render() {

        // Define internal functions
        const x1 = d3.scaleLinear()
            .domain(d3.extent(d3.map(this.data, d => d[this.xCol]))).nice()
            .range([this.marginLeft, this.width - this.marginRight]);

        const y1 = d3.scaleLinear()
            .domain(d3.extent(d3.map(this.data, d => d[this.yCol]))).nice()
            .range([this.height - this.marginBottom, this.marginTop]);

        const colorDots = d3.scaleOrdinal()
            .domain(["NS", "up", "down"])
            .range(["#D3D3D3", "#cf2e2e", "#0000ff"])

        // Create the axes.
        this.svg.append("g")
            .attr("transform", `translate(0,${this.height - this.marginBottom})`)
            .call(d3.axisBottom(x1).ticks(this.width / 80))
            .call(g => g.select(".domain").remove())
            .call(g => g.append("text")
                .attr("x", this.width)
                .attr("y", this.marginBottom - 5)
                .attr("fill", "currentColor")
                .attr("text-anchor", "end")
                .text(this.xCol + " →"));

        this.svg.append("g")
            .attr("transform", `translate(${this.marginLeft},0)`)
            .call(d3.axisLeft(y1))
            .call(g => g.select(".domain").remove())
            .call(g => g.append("text")
                .attr("x", -this.marginLeft)
                .attr("y", 15)
                .attr("fill", "currentColor")
                .attr("text-anchor", "start")
                .text("↑ " + this.yLabel));

        // Create the grid.
        this.svg.append("g")
            .attr("stroke", "currentColor")
            .attr("stroke-opacity", 0.1)
            .call(g => g.append("g")
                .selectAll("line")
                .data(x1.ticks())
                .join("line")
                .attr("x1", d => 0.5 + x1(d))
                .attr("x2", d => 0.5 + x1(d))
                .attr("y1", this.marginTop)
                .attr("y2", this.height - this.marginBottom))
            .call(g => g.append("g")
                .selectAll("line")
                .data(y1.ticks())
                .join("line")
                .attr("y1", d => 0.5 + y1(d))
                .attr("y2", d => 0.5 + y1(d))
                .attr("x1", this.marginLeft)
                .attr("x2", this.width - this.marginRight));

        const componentRef = this; // need to save this so we can call functions inside of click events

        // Create the circles
        this.svg.append("g")
            .selectAll("dot")
            .data(this.data)
            .enter()
            .append("circle")
            .attr("cx", d => x1(d[this.xCol]))
            .attr("cy", d => y1(d[this.yCol]))
            .attr("r", 3)
            .style("stroke", d => colorDots(d[this.colorCol]))
            .style("stroke-width", 1.5)
            .style("fill", "rgba(0, 0, 0, 0)")
            .on('mouseover', function (d) {
                d3.select(this).transition()
                    .duration(100)
                    .attr("r", 5)
                    .style("fill", "rgba(255, 255, 255, 1)");
                var coords = d3.pointer(event);
                d3.select('.chart-tooltip')
                    .style("left", coords[0] + 15 + "px")
                    .style("top", coords[1] - 25 + "px")
                    .style("display", "block")
                    .text(d.target.__data__['Feature'] + ": " + d.target.__data__['Description']);
            })
            .on('mouseout', function () {
                d3.select(this).transition()
                    .duration(200)
                    .attr("r", 3)
                    .style("fill", "rgba(0, 0, 0, 0)");
                d3.select('.chart-tooltip')
                    .style("display", "none")
            })
            .on('click', function (d) {
                var clickRes = d.target.__data__['Gene_ID'] + ";" + d.target.__data__['Feature'] + ";" + d.target.__data__['Description'];
                                   console.log(clickRes)
                componentRef.newItemEvent.emit(clickRes);
            
            });
    }

}
