import * as d3 from "d3";

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/multi-line-chart
export function LineChart(data, {
  x = ([x]) => x, // given d in data, returns the (temporal) x-value
  y = ([, y]) => y, // given d in data, returns the (quantitative) y-value
  z = () => 1, // given d in data, returns the (categorical) z-value
  title, // given d in data, returns the title text
  defined, // for gaps in data
  curve = d3.curveLinear, // method of interpolation between points
  marginTop = 20, // top margin, in pixels
  marginRight = 30, // right margin, in pixels
  marginBottom = 50, // bottom margin, in pixels
  marginLeft = 40, // left margin, in pixels
  width = 640, // outer width, in pixels
  height = 400, // outer height, in pixels
  xType = d3.scaleUtc, // type of x-scale
  xDomain, // [xmin, xmax]
  xRange = [marginLeft, width - marginRight], // [left, right]
  yType = d3.scaleLinear, // type of y-scale
  yDomain, // [ymin, ymax]
  yRange = [height - marginBottom, marginTop], // [bottom, top]
  yFormat, // a format specifier string for the y-axis
  yLabel, // a label for the y-axis
  zDomain, // array of z-values
  color = "currentColor", // stroke color of line, as a constant or a function of *z*
  strokeLinecap, // stroke line cap of line
  strokeLinejoin, // stroke line join of line
  strokeWidth = 1.5, // stroke width of line
  strokeOpacity, // stroke opacity of line
  mixBlendMode = "multiply", // blend mode of lines
  divID, // string with format "#id"
  donorID = 'R186',
  dataType = 'perifusion'
} = {}) {

  // Compute values.
  const X = d3.map(data, x);
  const Y = d3.map(data, y);
  const Z = d3.map(data, z);
  const O = d3.map(data, d => d);
  if (defined === undefined) defined = (d, i) => !isNaN(X[i]) && !isNaN(Y[i]);
  const D = d3.map(data, defined);

  // Compute default domains, and unique the z-domain.
  if (xDomain === undefined) xDomain = d3.extent(X);
  if (yDomain === undefined) yDomain = [0, d3.max(Y, d => typeof d === "string" ? +d : d)];
  if (zDomain === undefined) zDomain = Z;
  zDomain = new d3.InternSet(zDomain);

  // Omit any data not present in the z-domain.
  const I = d3.range(X.length).filter(i => zDomain.has(Z[i]));

  // Construct scales and axes.
  const xScale = xType(xDomain, xRange);
  const yScale = yType(yDomain, yRange);
  const xAxis = d3.axisBottom(xScale).ticks(width / 80).tickSizeOuter(0);
  const yAxis = d3.axisLeft(yScale).ticks(height / 60, yFormat);

  // Compute titles.
  const T = title === undefined ? Z : title === null ? null : d3.map(data, title);

  // Construct a line generator.
  const line = d3.line()
    .defined(i => D[i])
    .curve(curve)
    .x(i => xScale(X[i]))
    .y(i => yScale(Y[i]));

  const indData = data.filter(d => d['record_id'] == donorID)
  const dX = d3.map(indData, x);
  const dY = d3.map(indData, y);
  const dZ = d3.map(indData, z);
  const dO = d3.map(indData, d => d);
  if (defined === undefined) defined = (d, i) => !isNaN(dX[i]) && !isNaN(dY[i]);
  const dD = d3.map(indData, defined);
  const dI = d3.range(dX.length).filter(i => zDomain.has(dZ[i]));

  const indLine = d3.line()
    .curve(curve)
    .x(i => xScale(d3.map(indData, x)[i]))
    .y(i => yScale(d3.map(indData, y)[i]))

  const svg = d3.select(divID)
    .append('svg')
    .attr("width", width)
    .attr("height", height)
    .attr("viewBox", [0, 0, width, height])
    .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
    .style("-webkit-tap-highlight-color", "transparent")
    .on("pointerenter", pointerentered)
    .on("pointermove", pointermoved)
    .on("pointerleave", pointerleft)
    .on("touchstart", event => event.preventDefault());

  svg.append("g")
    .attr("transform", `translate(0,${height - marginBottom})`)
    .call(xAxis);

  svg.append("g")
    .attr("transform", `translate(${marginLeft},0)`)
    .call(yAxis)
    .call(g => g.select(".domain").remove())
    .call(g => g.append("text")
      .attr("x", -marginLeft)
      .attr("y", 15)
      .attr("fill", "currentColor")
      .attr("text-anchor", "start")
      .text(yLabel));

  const path = svg.append("g")
    .attr("fill", "none")
    .attr("stroke", typeof color === "string" ? color : null)
    .attr("stroke-linecap", strokeLinecap)
    .attr("stroke-linejoin", strokeLinejoin)
    .attr("stroke-width", strokeWidth)
    .attr("stroke-opacity", strokeOpacity)
    .selectAll("path")
    .data(d3.group(I, i => Z[i]))
    .join("path")
    .style("mix-blend-mode", mixBlendMode)
    .attr("stroke", typeof color === "function" ? ([z]) => color(z) : null)
    .attr("d", ([, I]) => line(I));

  const donorPath = svg.append("g")
    .attr("fill", "none")
    .attr("stroke-linecap", strokeLinecap)
    .attr("stroke-linejoin", strokeLinejoin)
    .attr("stroke-width", 4)
    .selectAll("path")
    .data(d3.group(dI, i => dZ[i]))
    .join("path")
    .attr("stroke", "black")
    .attr("d", ([, dI]) => indLine(dI))

  const dot = svg.append("g")
    .attr("display", "none");

  dot.append("circle")
    .attr("r", 2.5);

  dot.append("text")
    .attr("font-family", "sans-serif")
    .attr("font-size", 10)
    .attr("text-anchor", "middle")
    .attr("y", -8);

  if (dataType == 'perifusion') {
    svg.append('rect')
      .attr('x', xScale(5))
      .attr('y', height - 17)
      .attr('width', xScale(20) - xScale(5))
      .attr('height', 15)
      .attr('stroke', 'black')
      .attr('fill', '#0008ff')
      .attr('fill-opacity', '0.6')

    svg.append('rect')
      .attr('x', xScale(20))
      .attr('y', height - 17)
      .attr('width', xScale(70) - xScale(20))
      .attr('height', 15)
      .attr('stroke', 'black')
      .attr('fill', '#43a047')
      .attr('fill-opacity', '0.6')

    svg.append('rect')
      .attr('x', xScale(90))
      .attr('y', height - 17)
      .attr('width', xScale(140) - xScale(90))
      .attr('height', 15)
      .attr('stroke', 'black')
      .attr('fill', '#9900ff')
      .attr('fill-opacity', '0.6')

    svg.append('rect')
      .attr('x', xScale(160))
      .attr('y', height - 17)
      .attr('width', xScale(190) - xScale(160))
      .attr('height', 15)
      .attr('stroke', 'black')
      .attr('fill', '#286090')
      .attr('fill-opacity', '0.6')

  } else { // different experimental design for mitochondrial function

    svg.append('rect')
      .attr('x', xScale(0))
      .attr('y', height - 25)
      .attr('width', xScale(35.5) - xScale(0))
      .attr('height', 23)
      .attr('stroke', 'black')
      .attr('fill', '#0008ff')
      .attr('fill-opacity', '0.6')

    svg.append('rect')
      .attr('x', xScale(35.5))
      .attr('y', height - 25)
      .attr('width', xScale(86.62) - xScale(35.5))
      .attr('height', 23)
      .attr('stroke', 'black')
      .attr('fill', '#43a047')
      .attr('fill-opacity', '0.6')

    svg.append('rect')
      .attr('x', xScale(86.62))
      .attr('y', height - 25)
      .attr('width', xScale(154.75) - xScale(86.62))
      .attr('height', 23)
      .attr('stroke', 'black')
      .attr('fill', '#9900ff')
      .attr('fill-opacity', '0.6')

    svg.append('rect')
      .attr('x', xScale(154.75))
      .attr('y', height - 25)
      .attr('width', xScale(205.88) - xScale(154.75))
      .attr('height', 23)
      .attr('stroke', 'black')
      .attr('fill', '#286090')
      .attr('fill-opacity', '0.6')

    svg.append('rect')
      .attr('x', xScale(205.88))
      .attr('y', height - 25)
      .attr('width', xScale(265.42) - xScale(205.88))
      .attr('height', 23)
      .attr('stroke', 'black')
      .attr('fill', '#ff00ff')
      .attr('fill-opacity', '0.6')

    // Now add labels
    svg.append('text')
      .classed("label-text", true)
      .attr('x', xScale(0 + 2))
      .attr('y', height - 8)
      .text("Basal glucose")

    svg.append('text')
      .classed("label-text", true)
      .attr('x', xScale(35.5 + 11))
      .attr('y', height - 8)
      .text("High glucose")

    svg.append('text')
      .classed("label-text", true)
      .attr('x', xScale(86.62 + 22))
      .attr('y', height - 8)
      .text("Oligomycin")

    svg.append('text')
      .classed("label-text", true)
      .attr('x', xScale(154.75 + 20))
      .attr('y', height - 8)
      .text("FCCP")

    svg.append('text')
      .classed("label-text", true)
      .attr('x', xScale(205.88 + 5))
      .attr('y', height - 8)
      .text("Rotenone/antimycin A")

  }

  function pointermoved(event) {
    const [xm, ym] = d3.pointer(event);
    const i = d3.least(I, i => Math.hypot(xScale(X[i]) - xm, yScale(Y[i]) - ym)); // closest point
    path.style("stroke", ([z]) => Z[i] === z ? null : "#ddd").filter(([z]) => Z[i] === z).raise();
    dot.attr("transform", `translate(${xScale(X[i])},${yScale(Y[i])})`);
    if (T) dot.select("text").text(T[i]);
    svg.property("value", O[i]).dispatch("input", { bubbles: true });
  }

  function pointerentered() {
    path.style("mix-blend-mode", null).style("stroke", "#ddd");
    dot.attr("display", null);
  }

  function pointerleft() {
    path.style("mix-blend-mode", mixBlendMode).style("stroke", null);
    dot.attr("display", "none");
    svg.node().value = null;
    svg.dispatch("input", { bubbles: true });
  }

  return LineChart;
}