<style>
.custom-icon-size .pi {
    font-size: 1.5em;   
    transform: scale(1.2);  
}

</style>
<div style="width:100%" class="p-grid">
    <div class="p-d-flex p-col-6 p-jc-start">
        <p-selectButton [options]="donorSelectOptions" [(ngModel)]="donorSelect" optionLabel="label"
            optionValue="value"></p-selectButton>
    </div>
    <div *ngIf="listExist" class="p-d-flex p-col-6 p-jc-end">
        <button pButton type="button" label="Download Donor List" class="p-button-warning" (click)="downloadDonorList()"
            icon="pi pi-download"></button>
    </div>
</div>
<div *ngIf="donorSelect=='filter'">
    <p-tabView>
        <p-tabPanel header="Donor Characteristics">
            <p>Note: if enabled, variable filters will exclude donors with missing values for that
                variable. <a (click)="displayFilters=true"
                    style="cursor: pointer; margin-left: 10px; text-decoration: underline;">Show
                    advanced filters</a>
            </p>
            <div class="p-grid p-ai-center vertical-container" style="padding-top:10px;">
                <div class="p-col-fixed" style="width:180px">
                    <p-checkbox [(ngModel)]="filterSex" [binary]="true"></p-checkbox>
                    <span style="font-weight: bold; margin-left:15px">Sex</span>
                </div>
                <div class="p-col-7">
                    <div class="field-checkbox">
                        <p-checkbox name="sex" value="Male" [(ngModel)]="selectedSex" inputId="1"
                            [disabled]="!filterSex"></p-checkbox>
                        <label for="male" style="margin-right:20px; margin-left:10px">Male</label>
                        <p-checkbox name="sex" value="Female" [(ngModel)]="selectedSex" inputId="2"
                            [disabled]="!filterSex"></p-checkbox>
                        <label for="female" style="margin-right:20px; margin-left:10px">Female</label>
                    </div>
                </div>
                <div class="p-col">
                    <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                        (click)="metaPlot('donorsex', 'Sex')" [disabled]="!filterSex"></button>
                </div>
            </div>
            <div class="p-grid p-ai-center vertical-container" style="padding-top:20px;">
                <div class="p-col-fixed" style="width:180px">
                    <p-checkbox [(ngModel)]="filterDiagnosis" [binary]="true"></p-checkbox>
                    <span style="font-weight: bold; margin-left:15px">Diabetes diagnosis</span>
                </div>
                <div class="p-col-7">
                    <div class="field-checkbox">
                        <p-checkbox name="dtype" value="None" [(ngModel)]="selectedDtype" inputId="none"
                            [disabled]="!filterDiagnosis">
                        </p-checkbox>
                        <label for="none" style="margin-right:20px; margin-left:10px">No
                            diabetes</label>
                        <p-checkbox name="dtype" value="Type1" [(ngModel)]="selectedDtype" inputId="type1"
                            [disabled]="!filterDiagnosis">
                        </p-checkbox>
                        <label for="type1" style="margin-right:20px; margin-left:10px">Type 1</label>
                        <p-checkbox name="dtype" value="Type2" [(ngModel)]="selectedDtype" inputId="type2"
                            [disabled]="!filterDiagnosis">
                        </p-checkbox>
                        <label for="type2" style="margin-right:20px; margin-left:10px">Type 2</label>
                    </div>
                </div>
                <div class="p-col">
                    <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                        (click)="metaPlot('diagnosis', 'Diabetes.Diagnosis')" [disabled]="!filterDiagnosis"></button>
                </div>
            </div>
            <div class="p-grid p-ai-center vertical-container" style="padding-top:20px">
                <div class="p-col-fixed" style="width:180px">
                    <p-checkbox [(ngModel)]="filterAge" [binary]="true"></p-checkbox>
                    <span style="font-weight: bold; margin-left:15px">Age
                        <a href="javascript: void(0);" (click)="displayAge=true" pTooltip="Click to type range"
                            tooltipPosition="bottom" *ngIf="filterAge">({{ageValues[0] + '
                            - ' +
                            ageValues[1]}})</a></span>
                </div>
                <div class="p-col-7">
                    <p-slider [(ngModel)]="ageValues" [range]="true" [disabled]="!filterAge"></p-slider>
                </div>
                <div class="p-col">
                    <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                        (click)="metaPlot('donorage', 'Age')" [disabled]="!filterAge"></button>
                </div>
            </div>
            <div class="p-grid p-ai-center vertical-container" style="padding-top:20px">
                <div class="p-col-fixed" style="width:180px;">
                    <p-checkbox [(ngModel)]="filterBMI" [binary]="true"></p-checkbox>
                    <span style="font-weight: bold; margin-left: 15px">BMI
                        <a href="javascript: void(0);" (click)="displayBMI=true" pTooltip="Click to type range"
                            tooltipPosition="bottom" *ngIf="filterBMI">({{bmiValues[0] + '
                            - ' +
                            bmiValues[1]}})</a></span>
                </div>
                <div class="p-col-7">
                    <p-slider [(ngModel)]="bmiValues" [range]="true" [min]="15" [max]="50"
                        [disabled]="!filterBMI"></p-slider>
                </div>
                <div class="p-col">
                    <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                        (click)="metaPlot('bodymassindex', 'BMI')" [disabled]="!filterBMI"></button>
                </div>
            </div>
            <div class="p-grid p-ai-center vertical-container" style="padding-top:20px;">
                <div class="p-col-fixed" style="width:180px;">
                    <p-checkbox [(ngModel)]="filterHba1c" [binary]="true"></p-checkbox>
                    <span style="font-weight: bold; margin-left: 15px">HbA1c
                        <a href="javascript: void(0);" (click)="displayHba1c=true" pTooltip="Click to type range"
                            tooltipPosition="bottom" *ngIf="filterHba1c">({{hba1cValues[0] +
                            ' - ' +
                            hba1cValues[1]}})</a></span>
                </div>
                <div class="p-col-7">
                    <p-slider [(ngModel)]="hba1cValues" [range]="true" [min]="3" [max]="15" [step]="0.1"
                        [disabled]="!filterHba1c"></p-slider>
                </div>
                <div class="p-col">
                    <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                        (click)="metaPlot('hba1c', 'HbA1c')" [disabled]="!filterHba1c"></button>
                </div>
            </div>
            <!-- <div class="p-grid p-ai-center vertical-container" style="padding-top:20px">
                            <div class="p-col">
                                <span style="margin-right:20px">Remove internally flagged outlier
                                    donors:</span>
                                <p-checkbox [(ngModel)]="removeOutliers" [binary]="true"></p-checkbox>
                            </div>
                        </div> -->
        </p-tabPanel>
        <p-tabPanel header="Data Availability">
            <p style="margin: 10px 0px">All donors have basic clinical metadata and isolation
                information available. To filter based on availability of other
                data types, please use the form below. Only donors with ALL of the selected data types
                will be included.
            </p>
            <div class="p-d-flex p-ai-center p-grid">
                <div class="p-col-12">
                    <div style="margin-top:20px;" class="p-grid p-ai-center p-jc-left">
                        <div class="p-col-3">
                            <label style="font-weight: bold;">Select datasets:</label>
                        </div>
                        <div class="p-col-9">
                            <p-multiSelect [options]="availableType" [group]="true" [(ngModel)]="selectedAvailableType"
                                scrollHeight="250px" display="chip" defaultLabel="Select data types"
                                [style]="{'width':'400px'}" [showHeader]="false" [maxSelectedLabels]="100">
                                <ng-template let-group pTemplate="group">
                                    <div class="flex align-items-center">
                                        {{group.label}}
                                    </div>
                                </ng-template>
                            </p-multiSelect>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
    </p-tabView>
</div>

<div *ngIf="donorSelect=='upload'">
    <div class="p-grid" style="margin-top: 20px">
        <div class="p-col-12 p-grid">
            <div class="p-col-12 p-ai-center">
                <label style="padding: 10px; font-size: 14px">ID type:</label>
                <p-dropdown [options]="IDType" [(ngModel)]="selectedIDType"></p-dropdown>
                <button pButton type="button" label="Try Example" class="p-button-text" (click)="loadExample()"
                    [style]="{'margin-left': '15px'}"></button>
            </div>
            <div class="p-col-12" style="margin-top: 20px">
                <textarea rows="15" cols="30" [(ngModel)]="uploadIds" pInputTextarea
                    placeholder="Copy-and-paste a list of donor IDs"></textarea>
            </div>
        </div>
    </div>
</div>
<div class="p-grid p-ai-center p-jc-left vertical-container" style="padding-top:20px;">
    <div class="p-col-fixed" style="width:150px">
        <button pButton pRipple type="button" label="Submit" (click)="getDonors(donorSelect)" [loading]="submit_loading"></button>
    </div>
    <div class="p-col">
        <p-messages [(value)]="currentMsg" [enableService]="false"></p-messages>
    </div>
</div>


<p-dialog header="Input Age range" [(visible)]="displayAge" [style]="{width: '500px'}">
    Include donors with ages from
    <input class="p-inputnumber-sm" [(ngModel)]="ageValues[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="ageValues[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayAge=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input BMI range" [(visible)]="displayBMI" [style]="{width: '500px'}">
    Include donors with BMI values from
    <input class="p-inputnumber-sm" [(ngModel)]="bmiValues[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="bmiValues[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayBMI=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input HbA1c range" [(visible)]="displayHba1c" [style]="{width: '500px'}">
    Include donors with HbA1c values from
    <input class="p-inputnumber-sm" [(ngModel)]="hba1cValues[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="hba1cValues[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayHba1c=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input '%IEQ Recovery' range" [(visible)]="displayPerIEQRec" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="perIEQRec[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="perIEQRec[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayPerIEQRec=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Islet particle index' range" [(visible)]="displayDistIPI" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="distIPI[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="distIPI[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayDistIPI=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Insulin per IEQ' range" [(visible)]="displayDistInsPerIEQ" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="distInsPerIEQ[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="distInsPerIEQ[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayDistInsPerIEQ=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Insulin:DNA ratio' range" [(visible)]="displayDistInsDNA" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="distInsDNA[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="distInsDNA[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayDistInsDNA=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Culture time' range" [(visible)]="displayCultureTime" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="cultureTime[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="cultureTime[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayCultureTime=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Cold ischemia time' range" [(visible)]="displayCIT" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="CIT[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="CIT[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayCIT=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Pancreas weight' range" [(visible)]="displayPancWeight" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="pancWeight[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="pancWeight[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayPancWeight=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Digestion time' range" [(visible)]="displayDigTime" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="digTime[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="digTime[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayDigTime=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input '% Purity' range" [(visible)]="displayPurityPer" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="purityPer[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="purityPer[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayPurityPer=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Total IEQ' range" [(visible)]="displayTotIEQ" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="totIEQ[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="totIEQ[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayTotIEQ=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Islet particles' range" [(visible)]="displayIsletParticles" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="isletParticles[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="isletParticles[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayIsletParticles=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Islet particle index' range" [(visible)]="displayIsoIPI" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="isoIPI[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="isoIPI[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayIsoIPI=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'IEQ/Pancreas weight' range" [(visible)]="displayIEQPerPanc" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="IEQPerPanc[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="IEQPerPanc[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayIEQPerPanc=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Insulin content' range" [(visible)]="displayInsContent" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="insContent[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="insContent[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayInsContent=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Insulin per IEQ' range" [(visible)]="displayIsoInsPerIEQ" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="isoInsPerIEQ[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="isoInsPerIEQ[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayIsoInsPerIEQ=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'DNA content' range" [(visible)]="displayDNAContent" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="DNAContent[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="DNAContent[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayDNAContent=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Input 'Insulin:DNA ratio' range" [(visible)]="displayIsoInsDNA" [style]="{width: '500px'}">
    Include donors with values from
    <input class="p-inputnumber-sm" [(ngModel)]="isoInsDNA[0]" pInputText style="width:75px; margin:0px 5px;" /> to
    <input class="p-inputnumber-sm" [(ngModel)]="isoInsDNA[1]" pInputText style="width:75px; margin:0px 5px;" />
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayIsoInsDNA=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Variable Distribution" [(visible)]="displayDist" [style]="{width: '500px', height: '500px'}">
    <div class="p-d-flex p-jc-center p-ai-center" style="width:100%; height:100%">
        <div class="wrapper" *ngIf="loadingImage == 'yes'">
            <p-progressSpinner></p-progressSpinner>
        </div>
        <div style="padding-top:20px">
            <img alt="Place image title" *ngIf="imageToShow" [src]="imageToShow" width="400px">
        </div>
    </div>
    <ng-template pTemplate="footer">
           <button pButton type="button" icon="pi pi-fw pi-download" (click)="dwlFeature()" 
                class="custom-icon-size"></button>
        <button pButton type="button" icon="pi pi-check" (click)="displayDist=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>

<p-dialog header="Advanced Filters" [(visible)]="displayFilters" [style]="{width: '1000px', height: '875px'}">
    <div class="p-d-flex p-jc-left p-ai-top" style="width:100%; height:100%; padding:10px 30px">
        <p-tabView [style]="{'width': '900px'}">
            <p>Note: if enabled, variable filters will exclude donors with missing values for that
                variable.
            </p>
            <p-tabPanel header="Donor Information" [selected]="true">
                <div class="p-grid p-ai-center vertical-container" style="padding-top:10px;">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterCorDiag" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Corrected diabetes status</span>
                    </div>
                    <div class="p-col-5">
                        <div class="field-checkbox">
                            <p-checkbox value="None" [(ngModel)]="selectedCorDiag"
                                [disabled]="!filterCorDiag"></p-checkbox>
                            <label style="margin-right:20px; margin-left:10px">None</label>
                            <p-checkbox value="Pre.T2D" [(ngModel)]="selectedCorDiag"
                                [disabled]="!filterCorDiag"></p-checkbox>
                            <label style="margin-right:20px; margin-left:10px">Pre-T2D</label>
                            <p-checkbox value="Type2" [(ngModel)]="selectedCorDiag"
                                [disabled]="!filterCorDiag"></p-checkbox>
                            <label style="margin-right:20px; margin-left:10px">T2D</label>
                            <p-checkbox value="Type1" [(ngModel)]="selectedCorDiag"
                                [disabled]="!filterCorDiag"></p-checkbox>
                            <label style="margin-right:20px; margin-left:10px">T1D</label>
                        </div>
                    </div>
                    <div class="p-col">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('diagnosis_computed', 'Corrected.Diabetes.Status')" [disabled]="!filterCorDiag"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:10px;">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterDonType" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Donation type</span>
                    </div>
                    <div class="p-col-5">
                        <div class="field-checkbox">
                            <p-checkbox value="NDD" [(ngModel)]="selectedDonationType"
                                [disabled]="!filterDonType"></p-checkbox>
                            <label style="margin-right:20px; margin-left:10px">NDD</label>
                            <p-checkbox value="DCD" [(ngModel)]="selectedDonationType"
                                [disabled]="!filterDonType"></p-checkbox>
                            <label style="margin-right:20px; margin-left:10px">DCD</label>
                            <p-checkbox value="MAID" [(ngModel)]="selectedDonationType"
                                [disabled]="!filterDonType"></p-checkbox>
                            <label style="margin-right:20px; margin-left:10px">MAID</label>
                        </div>
                    </div>
                    <div class="p-col">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('donationtype', 'Donation.Type')" [disabled]="!filterDonType"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px;">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterHla2" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">HLA-A2 positive</span>
                    </div>
                    <div class="p-col-5">
                        <div class="field-checkbox">
                            <p-checkbox value="Positive" [(ngModel)]="selectedHla2"
                                [disabled]="!filterHla2"></p-checkbox>
                            <label style="margin-right:20px; margin-left:10px">Positive</label>
                            <p-checkbox value="Negative" [(ngModel)]="selectedHla2"
                                [disabled]="!filterHla2"></p-checkbox>
                            <label style="margin-right:20px; margin-left:10px">Negative</label>
                        </div>
                    </div>
                    <div class="p-col">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('hla_a2', 'HLA-A2.Positive')" [disabled]="!filterHla2"></button>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Distribution Information">
                <div class="p-grid p-ai-center vertical-container" style="padding-top:10px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterPerIEQRec" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">% IEQ recovery
                            <a href="javascript: void(0);" (click)="displayPerIEQRec=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterPerIEQRec">({{perIEQRec[0] + '
                                - ' +
                                perIEQRec[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="perIEQRec" [range]="true" [disabled]="!filterPerIEQRec" [min]="0"
                            [max]="100"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('percentieqrecoverypostculture', '%.IEQ.Recovery')"
                            [disabled]="!filterPerIEQRec"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterDistIPI" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Islet particle index
                            <a href="javascript: void(0);" (click)="displayDistIPI=true" pTooltip="Click to type range"
                                tooltipPosition="bottom" *ngIf="filterDistIPI">({{distIPI[0] + '
                                - ' +
                                distIPI[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="distIPI" [range]="true" [disabled]="!filterDistIPI" [min]="0" [max]="3"
                            [step]="0.1"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('pdisletparticleindex', 'Islet.Particle.Index')"
                            [disabled]="!filterDistIPI"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterDistInsPerIEQ" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Insulin per IEQ
                            <a href="javascript: void(0);" (click)="displayDistInsPerIEQ=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterDistInsPerIEQ">({{distInsPerIEQ[0] + '
                                - ' +
                                distInsPerIEQ[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="distInsPerIEQ" [range]="true" [min]="0" [max]="165"
                            [disabled]="!filterDistInsPerIEQ"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('pdinsulinperieq', 'Insulin.Per.IEQ')"
                            [disabled]="!filterDistInsPerIEQ"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterDistInsDNA" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Insulin:DNA ratio
                            <a href="javascript: void(0);" (click)="displayDistInsDNA=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterDistInsDNA">({{distInsDNA[0] + '
                                - ' +
                                distInsDNA[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="distInsDNA" [range]="true" [disabled]="!filterDistInsDNA" [min]="0"
                            [max]="45"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('pdinsulindnaratio', 'Insulin.To.DNA.Ratio')"
                            [disabled]="!filterDistInsDNA"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterCultureTime" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Culture time
                            <a href="javascript: void(0);" (click)="displayCultureTime=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterCultureTime">({{cultureTime[0] + '
                                - ' +
                                cultureTime[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="cultureTime" [range]="true" [disabled]="!filterCultureTime" [min]="0"
                            [max]="140"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('predistributionculturetime', 'Culture.Time')"
                            [disabled]="!filterCultureTime"></button>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Isolation Information">
                <div class="p-grid p-ai-center vertical-container" style="padding-top:10px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterCIT" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Cold ischemia time
                            <a href="javascript: void(0);" (click)="displayCIT=true" pTooltip="Click to type range"
                                tooltipPosition="bottom" *ngIf="filterCIT">({{CIT[0] + '
                                - ' +
                                CIT[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="CIT" [range]="true" [disabled]="!filterCIT" [min]="0"
                            [max]="45"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('coldischemiatime', 'CIT')" [disabled]="!filterCIT"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterPancWeight" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Pancreas weight
                            <a href="javascript: void(0);" (click)="displayPancWeight=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterPancWeight">({{pancWeight[0] + '
                                - ' +
                                pancWeight[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="pancWeight" [range]="true" [disabled]="!filterPancWeight" [min]="0"
                            [max]="230"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('pancreasweight', 'Pancreas.Weight')"
                            [disabled]="!filterPancWeight"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterDigTime" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Digestion time
                            <a href="javascript: void(0);" (click)="displayDigTime=true" pTooltip="Click to type range"
                                tooltipPosition="bottom" *ngIf="filterDigTime">({{digTime[0] + '
                                - ' +
                                digTime[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="digTime" [range]="true" [disabled]="!filterDigTime" [min]="0"
                            [max]="55"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('digesttime', 'Digestion.Time')" [disabled]="!filterDigTime"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterPurityPer" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">% Purity
                            <a href="javascript: void(0);" (click)="displayPurityPer=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterPurityPer">({{purityPer[0] + '
                                - ' +
                                purityPer[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="purityPer" [range]="true" [disabled]="!filterPurityPer" [min]="0"
                            [max]="100"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('puritypercentage', '%.Purity')" [disabled]="!filterPurityPer"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterTotIEQ" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Total IEQ
                            <a href="javascript: void(0);" (click)="displayTotIEQ=true" pTooltip="Click to type range"
                                tooltipPosition="bottom" *ngIf="filterTotIEQ">({{totIEQ[0] + '
                                - ' +
                                totIEQ[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="totIEQ" [range]="true" [disabled]="!filterTotIEQ" [min]="0"
                            [max]="1005000" [step]="1000"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('totalieq', 'Total.IEQ')" [disabled]="!filterTotIEQ"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterIsletParticles" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Islet particles
                            <a href="javascript: void(0);" (click)="displayIsletParticles=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterIsletParticles">({{isletParticles[0] + '
                                - ' +
                                isletParticles[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="isletParticles" [range]="true" [min]="0" [max]="800000" [step]="1000"
                            [disabled]="!filterIsletParticles"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('isletparticles', 'Islet.Particles')"
                            [disabled]="!filterIsletParticles"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterIsoIPI" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Islet particle index
                            <a href="javascript: void(0);" (click)="displayIsoIPI=true" pTooltip="Click to type range"
                                tooltipPosition="bottom" *ngIf="filterIsoIPI">({{isoIPI[0] + '
                                - ' +
                                isoIPI[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="isoIPI" [range]="true" [disabled]="!filterIsoIPI" [min]="0" [max]="45"
                            [step]="1"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('isletparticleindex', 'Islet.Particle.Index')"
                            [disabled]="!filterIsoIPI"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterIEQPerPanc" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">IEQ/Pancreas weight
                            <a href="javascript: void(0);" (click)="displayIEQPerPanc=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterIEQPerPanc">({{IEQPerPanc[0] + '
                                - ' +
                                IEQPerPanc[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="IEQPerPanc" [range]="true" [disabled]="!filterIEQPerPanc" [min]="0"
                            [max]="14000" [step]="100"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('ieqperpancreasweight', 'IEQ.Per.Pancreas.Weight')"
                            [disabled]="!filterIEQPerPanc"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterInsContent" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Insulin content
                            <a href="javascript: void(0);" (click)="displayInsContent=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterInsContent">({{insContent[0] + '
                                - ' +
                                insContent[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="insContent" [range]="true" [disabled]="!filterInsContent" [min]="0"
                            [max]="22700" [step]="100"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('insulincontent', 'Insulin.Content')"
                            [disabled]="!filterInsContent"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterIsoInsPerIEQ" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Insulin per IEQ
                            <a href="javascript: void(0);" (click)="displayIsoInsPerIEQ=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterIsoInsPerIEQ">({{isoInsPerIEQ[0] + '
                                - ' +
                                isoInsPerIEQ[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="isoInsPerIEQ" [range]="true" [disabled]="!filterIsoInsPerIEQ" [min]="0"
                            [max]="135" [step]="1"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('insulinperieq', 'Insulin.Per.IEQ.(post-isolation)')"
                            [disabled]="!filterIsoInsPerIEQ"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterDNAContent" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">DNA content
                            <a href="javascript: void(0);" (click)="displayDNAContent=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterDNAContent">({{DNAContent[0] + '
                                - ' +
                                DNAContent[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="DNAContent" [range]="true" [disabled]="!filterDNAContent" [min]="0"
                            [max]="45000" [step]="100"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('dnacontent', 'DNA.Content')" [disabled]="!filterDNAContent"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterIsoInsDNA" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Insulin:DNA ratio
                            <a href="javascript: void(0);" (click)="displayIsoInsDNA=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterIsoInsDNA">({{isoInsDNA[0] + '
                                - ' +
                                isoInsDNA[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="isoInsDNA" [range]="true" [disabled]="!filterIsoInsDNA" [min]="0"
                            [max]="35" [step]="1"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('insulindnaratio', 'Insulin.To.DNA.Ratio.(post-isolation)')"
                            [disabled]="!filterIsoInsDNA"></button>
                    </div>
                </div>
            </p-tabPanel>
              <p-tabPanel header="Inventory">
                <div class="p-grid p-ai-center vertical-container" style="padding-top:10px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterCryotubesremaining" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Cryopreserved tubes
                            <a href="javascript: void(0);" (click)="displayCryotubesremaining=true"
                                pTooltip="Click to type range" tooltipPosition="bottom"
                                *ngIf="filterCryotubesremaining">({{cryotubesremaining[0] + '
                                - ' +
                                cryotubesremaining[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="cryotubesremaining" [range]="true" [disabled]="!filterCryotubesremaining" [min]="0"
                            [max]="30"></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('cryotubesremaining', 'Cryopreserved tubes')"
                            [disabled]="!filterCryotubesremaining"></button>
                    </div>
                </div>
                <div class="p-grid p-ai-center vertical-container" style="padding-top:30px">
                    <div class="p-col-fixed" style="width:300px">
                        <p-checkbox [(ngModel)]="filterSftubesremaining" [binary]="true"></p-checkbox>
                        <span style="font-weight: bold; margin-left:15px">Snap-frozen tubes
                            <a href="javascript: void(0);" (click)="displaySftubesremaining=true" pTooltip="Click to type range"
                                tooltipPosition="bottom" *ngIf="filterSftubesremaining">({{sftubesremaining[0] + '
                                - ' +
                                sftubesremaining[1]}})</a></span>
                    </div>
                    <div class="p-col-5">
                        <p-slider [(ngModel)]="sftubesremaining" [range]="true" [disabled]="!filterSftubesremaining" [min]="0" [max]="30"
                           ></p-slider>
                    </div>
                    <div class="p-col" style="padding-left:20px">
                        <button pButton type="button" icon="pi pi-chart-bar" class="p-button-rounded"
                            (click)="metaPlot('sftubesremaining', 'Snap-frozen tubes')"
                            [disabled]="!filterSftubesremaining"></button>
                    </div>
                </div>
               
            </p-tabPanel>
        </p-tabView>
    </div>
    <ng-template pTemplate="footer">
        <button pButton type="button" icon="pi pi-check" (click)="displayFilters=false" label="Ok"
            class="p-button-text"></button>
    </ng-template>
</p-dialog>