import { Component, OnInit, OnDestroy } from '@angular/core';
import { GlobalVarsService } from '../service/global-vars.service';
import { MenuItem } from 'primeng/api';
import { RestAPIService } from '../service/rest-api.service';
import { Subscription } from 'rxjs';
import { MessageService, Message } from 'primeng/api';
import { DOCUMENT } from '@angular/common'; 
import { Inject }  from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as d3 from "d3";

@Component({
    selector: 'app-donor',
    templateUrl: './app.donor.component.html',
    providers: [MessageService],
})
export class AppDonorComponent implements OnInit, OnDestroy {

    analysisState: string;
    donorID: string;
    items: MenuItem[];
    omics: any;
    summaryMessage: string;
    subUsrDir: Subscription;
    usrDir: string;
    loadingImage: string = "no";
    maxDonor: number = 460;
    viewHeight: number = 100;
    donorInf: any;

    subBaseUrl: Subscription;
    baseUrl: string;

    host: string = "public";

    activeTab: string = "donor";

    // URL parameters
    urlDonorID: string;

    constructor(private apiService: RestAPIService, private global: GlobalVarsService, private messageService: MessageService,
        @Inject(DOCUMENT) document: Document, private route: ActivatedRoute) {

        this.subUsrDir = this.global.currentUsrDir.subscribe(data => this.usrDir = data)
        this.subBaseUrl = this.global.baseUrl.subscribe(data => this.baseUrl = data)

        this.items = [{
            label: 'Metadata',
            items: [
                {
                    label: 'Donor Information', styleClass: 'active', command: (event) => {
                        this.activeTab = "donor";
                        this.getDonorView(this.activeTab, this.donorID);
                    }
                },
                {
                    label: 'Isolation Information', command: (event) => {
                        this.activeTab = "isolation";
                        this.getDonorView(this.activeTab, this.donorID);
                    }
                }
            ]
        },
        {
            label: 'Insulin Secretion',
            items: [
                {
                    label: 'Static Glucose-stim. Insulin Secretion', command: (event) => {
                        this.activeTab = "gsis";
                        this.getDonorView(this.activeTab, this.donorID);
                    }
                },
                {
                    label: 'Dynamic Insulin Response to Glucose', 
                    command: (event) => {
                        this.activeTab = "gluc_peri";
                        this.getDonorView(this.activeTab, this.donorID);
                    }
                },
                {
                    label: 'Dynamic Insulin Response to Leucine', command: (event) => {
                        this.activeTab = "leu_peri";
                        this.getDonorView(this.activeTab, this.donorID);
                    }
                },
                {
                    label: 'Dynamic Insulin Response to Palmitate', command: (event) => {
                        this.activeTab = "olp_peri";
                        this.getDonorView(this.activeTab, this.donorID);
                    }
                }
            ]
        },
        {
            label: 'Other',
            items: [
                {
                    label: 'Mitochondrial Function', 
                    command: (event) => {
                        this.activeTab = "seahorse";
                        this.getDonorView(this.activeTab, this.donorID);
                    }
                },
                {
                    label: 'Single-cell Function (electrophysiology)', command: (event) => {
                        this.activeTab = "exocytosis";
                        this.getDonorView(this.activeTab, this.donorID);
                    }
                },
                // {
                //     label: 'Contaminants', command: (event) => {
                //         this.activeTab = "contaminants";
                //         this.getDonorView(this.activeTab, this.donorID);
                //     }
                // },
                {
                    label: 'Omics Overview', command: (event) => {
                        this.activeTab = "omics";
                        this.getDonorView(this.activeTab, this.donorID);
                    }
                },
            ]
        }];

        this.omics = [
            {type: "Bulk gene expression (RNA-seq)", avail: "Yes"},
            {type: "Bulk gene expression (Nanostring)", avail: "No"}
        ];
    }

    ngOnInit() {
        const currentUrl = window.location.href;
        if (currentUrl.includes("localhost")) {
          this.host = "local";
        } else {
          this.host = "public";
        }
       console.log(this.host)
        this.route.queryParams.subscribe(params => {
            this.urlDonorID = params['recordID'];
            console.log("URL donorID: " + this.urlDonorID);
        
            if(this.urlDonorID){
                this.donorID = this.urlDonorID
                this.retrieveDonor();
            } else {
                this.donorID = this.randomDonor();
                this.retrieveDonor();
            }
        });
this.removeCookieManager();
    }

    ngOnDestroy() {
        this.subUsrDir.unsubscribe();
    }

    searchDonor() {
        if( !( ((this.donorID.length === 4) && (this.donorID.startsWith("R"))) || (this.donorID.startsWith("SAMN")) ) ) {
            this.messageService.add({severity:'error', summary:'Wrong Format', detail:'Valid donor IDs are either an "R" followed by 3 digits (ie. R350), or an RRID (ie. SAMN13333248)'});
        } else {
            this.retrieveDonor();
        }
    }

    retrieveDonor(){
        if (this.usrDir === "NA") {
            this.apiService.getLogin().subscribe(data => {
                this.usrDir = data;
                this.global.changeUsrDir(this.usrDir)
                this.apiService.getDonorInfo(this.usrDir, this.donorID, this.activeTab).subscribe(data => {
                    var res = data.split(";");
                    var jsonUrl = this.baseUrl + "download/" + this.usrDir + "/" + res[1];

                    d3.json(jsonUrl).then( data => {
                        this.donorInf = data;
                    })
                });
            })
        } else {
            this.apiService.getDonorInfo(this.usrDir, this.donorID, this.activeTab).subscribe(data => {
                if (data.includes("RES-OK")) {
                    var res = data.split(";");
                    var jsonUrl = this.baseUrl + "download/" + this.usrDir + "/" + res[1];
                    d3.json(jsonUrl).then( data => {
                        this.donorInf = data;
                    })
                } else {
                    this.messageService.add({severity:'error', summary:'Not Found', detail:'This donor does not exist in the database. If you believe that this is a mistake, please contact us (see the Contact tab).'});
                }
            });
        }
    }

    randomDonor() {
        return this.donorID = "R" + Math.floor(Math.random() *  (this.maxDonor - 100 + 1) + 100).toString();
    }

    getDonorView(dataType: string, donorID: string) {
        switch (dataType) {
            case "donor": {
                this.retrieveDonor();
                break;
            }
            case "isolation": {
                this.retrieveDonor();
                break;
            }
            case "gsis": {
                this.retrieveDonor();
                break;
            }
            case "gluc_peri": {
                this.retrieveDonor();
                break;
            }
            case "leu_peri": {
                this.retrieveDonor();
                break;
            }
            case "olp_peri": {
                this.retrieveDonor();
                break;
            }
            case "omics": {
                this.retrieveDonor();
                break;
            }
            case "exocytosis": {
                this.retrieveDonor();
                break;
            }
            case "seahorse": {
                this.retrieveDonor();
                break;
            }
            case "contaminants": {
                this.summaryMessage = "No function developed for this data type yet"
                break;
            }
        }

    }

    activeMenu(event) {
        let node;
        if (event.target.tagName === "SPAN") {
            node = event.target.parentNode.parentNode;
        } else {
            node = event.target.parentNode;
        }
        if (node != "submenu") {
            let menuitem = document.getElementsByClassName("p-menuitem");
            for (let i = 0; i < menuitem.length; i++) {
                menuitem[i].classList.remove("active");
            }
            node.classList.add("active");
        }
    }

    getSeverity(status: string) {
        switch (status) {
            case 'Yes':
                return 'success';
            case 'No':
                return 'danger';
        }
    }

    getIcon(status: string) {
        switch (status) {
            case 'Yes':
                return 'pi pi-check';
            case 'No':
                return 'pi pi-times';
        }
    }

 removeCookieManager() {
   console.log(document.querySelector('#prebannerBtn'))
      const banner = document.querySelector('#prebannerBtn') as HTMLElement;
if (banner) {
  banner.style.display = 'none'; // Hides the element completely
}
  }
}
