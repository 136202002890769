import { Component, OnInit, ViewEncapsulation, Input, ElementRef, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import * as d3 from "d3";
import { csvParse } from "d3";
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { DomSanitizer } from '@angular/platform-browser';

interface ImgType {
  name: string,
  code: string
}

@Component({
  selector: 'static-gsis',
  templateUrl: './static-gsis.component.html',
  styleUrls: ['./static-gsis.component.css'],
  encapsulation: ViewEncapsulation.None,  // see: https://jeffschoonover.dev/posts/2021/02/styling-d3-charts-angular-view-encapsulation/
})
export class StaticGsisComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() donorInf: any;
  @ViewChild('myIdentifier')
  myIdentifier: ElementRef;

  subBaseUrl: Subscription;
  baseUrl: string;

  data: any;
  svg: any;
  donorInfType: string;

  secretionOpts!: ImgType[];
  selectedSecretion!: ImgType;
  indexOpts!: ImgType[];
  selectedIndex!: ImgType;

  secretionVal: number;
  indexVal: number;

  tooltip: any;
  colWidth: number = 250;
  csvUrl: string;

  // donor parameters
  donorID: string;

  constructor(private http: HttpClient, private global: GlobalVarsService, private element: ElementRef, private domSanitizer: DomSanitizer) {

    this.secretionOpts = [
      { name: '1 mM glucose', code: 'insulin_secretion_1' },
      { name: '2.8 mM glucose', code: 'insulin_secretion_2p8' },
      { name: '10 mM glucose', code: 'insulin_secretion_10' },
      { name: '16.7 mM glucose', code: 'insulin_secretion_16p7' },
    ];
    this.selectedSecretion = this.secretionOpts[0];

    this.indexOpts = [
      { name: '1 → 10 mM glucose', code: 'gsis_index_1_10' },
      { name: '1 → 16.7 mM glucose', code: 'gsis_index_1_16p7' },
      { name: '2.8 → 16.7 mM glucose', code: 'gsis_index_2p8_16p7' },
    ];
    this.selectedIndex = this.indexOpts[0];

    this.donorInfType = typeof this.donorInf;
  }

  ngOnInit(): void {

    this.subBaseUrl = this.global.baseUrl.subscribe(data => {
      this.baseUrl = data
      this.csvUrl = this.baseUrl + "download/humanislets/display_data-numerical_gsis.csv";
    })

  }

  ngAfterViewInit(): void {
    this.colWidth = this.myIdentifier.nativeElement.offsetWidth - 50; // determine plot width based on grid size
  }

  ngOnChanges() {

    if (typeof this.csvUrl !== 'undefined') {
      this.donorInfType = typeof this.donorInf;

      // add text labels
      this.donorID = this.donorInf.record_id;
      this.secretionVal = this.donorInf.insulin_secretion_1.value;
      this.indexVal = this.donorInf.gsis_index_1_10.value;
      this.main();
    }
  }

  main() {
    // make plots
    this.http.get(this.csvUrl, { responseType: 'text' }).subscribe(data => {
      this.data = csvParse(data, d3.autoType);
      this.basicHistogram(this.data, "culturetime2", this.colWidth, 230, 5, "#TimeHistogram", 30, "#43a047", this.donorInf["culturetime2"].value, this.donorInf["culturetime2"].perc)
      this.basicHistogram(this.data, "total_insulin_content", this.colWidth, 230, 15, "#ContentHistogram", 30, "#43a047", this.donorInf["total_insulin_content"].value, this.donorInf["total_insulin_content"].perc)

      this.basicDensity(this.data, this.selectedSecretion.code, this.colWidth, 250, 15, "#SecretionHistogram", 40, "#cc00ff", this.donorInf[this.selectedSecretion.code].value, this.donorInf[this.selectedSecretion.code].perc, 10)
      this.basicDensity(this.data, this.selectedIndex.code, this.colWidth, 250, 15, "#IndexHistogram", 30, "#cc00ff", this.donorInf[this.selectedIndex.code].value, this.donorInf[this.selectedIndex.code].perc, 15)
    });
  }

  updateSecretion() {
    this.secretionVal = this.donorInf[this.selectedSecretion.code].value;
    this.basicDensity(this.data, this.selectedSecretion.code, this.colWidth, 250, 15, "#SecretionHistogram", 40, "#cc00ff", this.donorInf[this.selectedSecretion.code].value, this.donorInf[this.selectedSecretion.code].perc, 10)
  }

  updateIndex() {
    this.indexVal = this.donorInf[this.selectedIndex.code].value;
    this.basicDensity(this.data, this.selectedIndex.code, this.colWidth, 250, 15, "#IndexHistogram", 30, "#cc00ff", this.donorInf[this.selectedIndex.code].value, this.donorInf[this.selectedIndex.code].perc, 15)
  }

  basicHistogram(inputData: any, varName: string, width: number, height: number, binPar: number, selectTerm: string,
    marginBottom: number, color: string, vert: number, perc: number) {

    d3.select(selectTerm).select("svg").remove();

    // remove NA - too many messes up histogram function
    inputData = inputData.filter(d => d[varName] != 'NA');

    var margin = { top: 0, right: 10, bottom: marginBottom, left: 10 }
    var compHeight = height - margin.bottom - margin.top;

    // set the parameters for the histogram
    const bins = d3.bin()
      .thresholds(binPar)
      .value((d) => d[varName])
      (inputData);

    // Create the x-axis
    const x = d3.scaleLinear()
      .domain([bins[0].x0, bins[bins.length - 1].x1])
      .range([0, width - margin.right - margin.left]);

    // Create the y-axis
    var y = d3.scaleLinear()
      .range([compHeight, 0]);
    y.domain([0, d3.max(bins, function (d) { return d.length; })]);

    // Create SVG
    this.svg = d3.select(this.element.nativeElement)
      .select(selectTerm)
      .append('svg')
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
      .style("-webkit-tap-highlight-color", "transparent")
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    this.svg.append("g")
      .attr("transform", "translate(0," + compHeight + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)")
      .call(g => g.select(".domain").remove())

    this.svg.selectAll("rect")
      .data(bins)
      .enter()
      .append("rect")
      .attr("x", 0)
      .attr("transform", function (d) { return "translate(" + x(d.x0) + "," + y(d.length) + ")"; })
      .attr("width", function (d) { return x(d.x1) - x(d.x0); })
      .attr("height", function (d) { return compHeight - y(d.length); })
      .style("fill", color)
      .attr("fill-opacity", "0.6")

    if (!isNaN(vert)) {
      var percText = Math.round(perc * 100).toString() + "th perc."
      this.svg.append("line")
        .attr("y1", 0)
        .attr("y2", compHeight)
        .attr("x1", x(vert))
        .attr("x2", x(vert))
        .style("stroke-width", 2)
        .style("stroke", "black")
        .style("fill", "none");

      if (x(vert) > (width / 2)) {
        this.svg.append("text")
          .attr("x", x(vert) - 30)
          .attr("y", 15)
          .text(percText)
          .style("font-weight", "bold")
          .style("font-size", "12pt")
      } else {
        this.svg.append("text")
          .attr("x", x(vert) + 5)
          .attr("y", 15)
          .text(percText)
          .style("font-weight", "bold")
          .style("font-size", "12pt")
      }
    }

  }

  basicDensity(inputData: any, varName: string, width: number, height: number, binPar: number, selectTerm: string,
    marginBottom: number, color: string, vert: number, perc: number, resolution: number) {

    d3.select(selectTerm).select("svg").remove();

    // remove NA - too many messes up histogram function
    inputData = inputData.filter(d => d[varName] != 'NA');

    var margin = { top: 0, right: 10, bottom: marginBottom, left: 10 }
    var compHeight = height - margin.bottom - margin.top;

    // set the parameters for the histogram
    const bins = d3.bin()
      .thresholds(binPar)
      .value((d) => d[varName])
      (inputData);

    var xExtent = d3.extent(d3.map(inputData, d => d[varName]))
    var xMag = xExtent[1] - xExtent[0]

    // Create the x-axis
    const x = d3.scaleSqrt()
      .domain([(xExtent[0] - 0.2 * xMag), (xExtent[1] + 0.2 * xMag)]) // need to be a bit larger than extent because density profiles extend past
      .range([0, width - margin.right - margin.left]);

    // Compute kernel density estimation
    var kde = this.kernelDensityEstimator(this.kernelEpanechnikov(xMag/resolution), x.ticks(100))
    var density = kde(inputData.map(function (d) { return d[varName]; }))

    var densityMax = d3.max(density, function(d) { return d[1]}) as unknown as number;

    // Create the y-axis
    var y = d3.scaleLinear()
      .range([compHeight, 0])
      .domain([0, densityMax]);

    // Create SVG
    this.svg = d3.select(this.element.nativeElement)
      .select(selectTerm)
      .append('svg')
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
      .style("-webkit-tap-highlight-color", "transparent")
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    this.svg.append("g")
      .attr("transform", "translate(0," + compHeight + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)")
      .call(g => g.select(".domain").remove());

    this.svg.append("path")
      .attr("class", "mypath")
      .datum(density)
      .attr("fill", color)
      .attr("opacity", ".8")
      .attr("stroke", "#fff")
      .attr("stroke-width", 1)
      .attr("stroke-linejoin", "round")
      .attr("d",  d3.line()
        .curve(d3.curveBasis)
          .x(function(d) { return x(d[0]); })
          .y(function(d) { return y(d[1]); })
      );

    if (!isNaN(vert)) {
      var percText = Math.round(perc * 100).toString() + "th perc."
      this.svg.append("line")
        .attr("y1", 0)
        .attr("y2", compHeight)
        .attr("x1", x(vert))
        .attr("x2", x(vert))
        .style("stroke-width", 2)
        .style("stroke", "black")
        .style("fill", "none");

      if (x(vert) > (width / 2)) {
        this.svg.append("text")
          .attr("x", x(vert) - 30)
          .attr("y", 15)
          .text(percText)
          .style("font-weight", "bold")
          .style("font-size", "12pt")
      } else {
        this.svg.append("text")
          .attr("x", x(vert) + 5)
          .attr("y", 15)
          .text(percText)
          .style("font-weight", "bold")
          .style("font-size", "12pt")
      }
    }

  }

  // This is what I need to compute kernel density estimation
  kernelDensityEstimator(kernel: any, X: any) {
    return function (V) {
      return X.map(function (x) {
        return [x, d3.mean(V, function (v: any) { return kernel(x - v); })];
      });
    };
  }

  kernelEpanechnikov(k) {
    return function (v) {
      return Math.abs(v /= k) <= 1 ? 0.75 * (1 - v * v) / k : 0;
    };
  }

}
