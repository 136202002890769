<div class="p-grid" style='padding:30px 50px 0px 50px;'>
    <div class="p-col-12">
        <div class="card" style='padding:100px; font-size:14pt'>
            <h1>Terms of Service</h1>
            <div>
                <h3 style="margin-top:50px">Acknowledgement</h3>
                <p>
                    Users are required to acknowledge the use of this resource along with the contributions made by
                    organ procurement organizations, organ donors and families. Users should also acknowledge the human
                    research ethics protocol under which data were gathered and analyzed. This can be done via the
                    following acknowledgement text:
                </p>
                <p style="font-weight: bold; font-style: italic; margin-left:100px; margin-right:100px">
                    “This work includes data and/or analyses from HumanIslets.com funded by the Canadian Institutes of
                    Health Research, JDRF Canada, and Diabetes Canada (5-SRA-2021-1149-S-B/TG 179092) with data from
                    islets isolated by the Alberta Diabetes Institute IsletCore with the support of the Human Organ
                    Procurement and Exchange program, Trillium Gift of Life Network, BC Transplant, Quebec Transplant,
                    and other Canadian organ procurement organizations with written informed donor consent as approved
                    by the Human Research Ethics Board at the University of Alberta (Pro00013094).”
                </p>

                <h3 style="margin-top:50px">Citation</h3>
                <p>
                    Users are required to cite the use of this resource in any publication arising from its use or
                    access, and sources of underlying data. The relevant citations are listed below:
                </p>
                <div style="margin: 0px 100px">
                    <span style="font-weight: bold;">HumanIslets.com web tool</span>
                    <p><a href="https://doi.org/10.1101/2024.06.19.599613" target="_blank">Ewald et al. (2024) HumanIslets: An open resource human research islet data access and analysis.
                        bioRxiv, 6:2024.06.19.599613. doi:10.1101/2024.06.19.599613</a></p>

                    <span style="font-weight: bold;">Proteomic data and RNAseq</span>
                    <p><a href="https://www.medrxiv.org/content/10.1101/2023.05.24.23290298v1" target="_blank">Kolic et al. (2024) Proteomic predictors of
                            individualized nutrient-specific insulin secretion in health and disease. medRxiv,
                            5:2023.05.24.23290298. doi: 10.1101/2023.05.24.23290298.</a></p>

                    <span style="font-weight: bold;">Patch-seq</span>
                    <p><a href="https://pubmed.ncbi.nlm.nih.gov/35108513/" target="_blank">Dai et al. (2022) Heterogenous impairment of
                            α
                            cell function in type 2 diabetes is linked to cell maturation state. Cell Metabolism,
                            34(2):256-268.e5. doi: 10.1016/j.cmet.2021.12.021.</a></p>
                    <p><a href="https://pubmed.ncbi.nlm.nih.gov/32302527/" target="_blank">Camunas-Soler et al. (2020) Patch-seq links
                            single-cell transcriptomes to human islet dysfunction in diabetes. Cell Metabolism,
                            31(5):1017-1031.e4. doi: 10.1016/j.cmet.2020.04.005.</a></p>
                </div>
                <p style="margin-top:50px">
                    The HumanIslets tool relies on statistical methods and R packages published by others. We encourage
                    researchers to cite the appropriate publications:
                </p>
                <div style="margin: 0px 100px">
                    <span style="font-weight: bold;">Omics analysis results</span>
                    <p><a href="https://academic.oup.com/bioinformatics/article/26/1/139/182458" target="_blank">Robinson et al. (2009) edgeR: a
                            Bioconductor package for differential expression analysis of
                            digital gene expression data. Bioinformatics, 26(1):139-140. doi:
                            doi.org/10.1093/bioinformatics/btp616</a></p>

                    <span style="font-weight: bold;">Missing value imputation (processed proteomics data)</span>
                    <p><a href="https://academic.oup.com/bioinformatics/article/28/1/112/219101" target="_blank">Stekhoven et al. (2012)
                            MissForest—non-parametric missing value imputation for mixed-type data, Bioinformatics,
                            28(1):112-118. doi: 10.1093/bioinformatics/btr597</a></p>

                    <span style="font-weight: bold;">Batch correction (processed bulk RNAseq and Nanostring)</span>
                    <p><a href="https://academic.oup.com/bioinformatics/article/28/6/882/311263" target="_blank">Leek et al. (2012) The sva package for
                            removing batch effects and other unwanted variation in high-throughput experiments,
                            Bioinformatics, 28(6):882-883. doi: 10.1093/bioinformatics/bts034</a></p>

                    <span style="font-weight: bold;">Pathway analysis libraries</span>
                    <p><a href="https://academic.oup.com/nar/article/50/D1/D687/6426058" target="_blank">Gillespie et al. (2022) The reactome
                            pathway knowledgebase 2022, Nucleic Acids Research, 50(D1): D687–D692,
                            doi.org/10.1093/nar/gkab1028</a></p>
                    <p><a href="https://academic.oup.com/nar/article/51/D1/D587/6775388" target="_blank">Kanehisa et al. (2023) KEGG for
                            taxonomy-based analysis of pathways and genomes. Nucleic Acids Research, 51(D1): D587-D592,
                            doi.org/10.1093/nar/gkac963 </a></p>
                    <p><a href="https://pubmed.ncbi.nlm.nih.gov/36866529/" target="_blank">The Gene Ontology Consortium. (2023) The
                            Gene Ontology knowledgebase in 2023 Genetics, 224(1):iyad031. doi: 10.1093/genetics/iyad031
                        </a></p>
                </div>

                <h3 style="margin-top:50px">Time Stamp Reporting</h3>
                <p>
                    Because datasets are occasionally updated, which may impact normalisation and analyses, <span
                        style="font-weight: bold; font-style: italic;">users should
                        report the date of web tool access, analysis, and/or data download</span>. To facilitate this,
                    data
                    downloads are provided with a time/date stamp.
                </p>

                <h3 style="margin-top:50px">Without Warranty and Not for Clinical Use</h3>
                <p>
                    Data and analysis tools are provided without warranty, are not for clinical use, and should not be
                    used directly or indirectly to inform clinical diagnoses or treatment. While every effort has been
                    made to ensure the accuracy, integrity, and validity of the data and analyses presented here for
                    research purposes only, users are solely responsible for any results and interpretations.
                </p>
            </div>
        </div>
    </div>
</div>