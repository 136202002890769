import { Component, OnInit, ViewEncapsulation, Input, ElementRef, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import * as d3 from "d3";
import { csvParse } from "d3";
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { GlobalVarsService } from 'src/app/service/global-vars.service';

declare function greet(): void;

@Component({
  selector: 'donor-inf',
  templateUrl: './donor-inf.component.html',
  styleUrls: ['./donor-inf.component.css'],
  encapsulation: ViewEncapsulation.None,  // see: https://jeffschoonover.dev/posts/2021/02/styling-d3-charts-angular-view-encapsulation/
})
export class DonorInfComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() donorInf: any;
  @ViewChild('myIdentifier')
  myIdentifier: ElementRef;

  subBaseUrl: Subscription;
  baseUrl: string;

  data: any;
  svg: any;
  tooltip: any;
  colWidth: number = 250;

  viewAnna: string = 'no';

  // donor parameters
  donorID: string;
  diagnosis: string;
  sex: string;
  hla: string;
  superpop: string;
  pop: string;
  bmi: number;
  age: number;
  hba1c: number;
  cryotubesremaining:number;
  sftubesremaining:number;
  //t1d_grs: number;
  //t2d_grs: number;
  rrid: string;
  adjStatus: string;
  csvUrl: string;


  dispAncestry: boolean = true;

  constructor(private http: HttpClient, private global: GlobalVarsService, private element: ElementRef) {
  }

  ngOnInit(): void {

    this.subBaseUrl = this.global.baseUrl.subscribe(data => {
      this.baseUrl = data
      this.csvUrl = this.baseUrl + "download/humanislets/display_data-numerical_donor_info.csv";
    })

  }

  ngAfterViewInit(): void {
    this.colWidth = this.myIdentifier.nativeElement.offsetWidth - 40; // determine plot width based on grid size
  }

  ngOnChanges() {
    if (typeof this.csvUrl !== 'undefined') {
      console.log(this.donorInf)
      // add text labels
      this.donorID = this.donorInf.record_id
      this.rrid = this.donorInf.rrid
      this.diagnosis = this.donorInf.diagnosis
      this.adjStatus = this.donorInf.corStatus
      this.sex = this.donorInf.sex
      this.hla = this.donorInf.HLA
      this.bmi = this.donorInf.bmi.value
      this.age = this.donorInf.age.value
      this.hba1c = this.donorInf.hba1c.value
      this.cryotubesremaining = this.donorInf.cryotubesremaining.value
      this.sftubesremaining = this.donorInf.sftubesremaining.value
    }

    this.main();
  }

  main() {

    // make plots

    if (typeof this.csvUrl !== 'undefined') {
      this.http.get(this.csvUrl, { responseType: 'text' }).subscribe(data => {
        this.data = csvParse(data, d3.autoType);

        this.basicHistogram(this.data, "hba1c", this.colWidth, 300, 20, "#Hba1cHistogram", 20, "#43a047", this.hba1c, this.donorInf.hba1c.perc)
        this.basicHistogram(this.data, "donorage", this.colWidth, 300, 20, "#AgeHistogram", 20, "#43a047", this.age, this.donorInf.age.perc)
        this.basicHistogram(this.data, "bodymassindex", this.colWidth, 300, 20, "#BMIHistogram", 20, "#43a047", this.bmi, this.donorInf.bmi.perc)
      });
    }
  }

  basicHistogram(inputData: any, varName: string, width: number, height: number, binPar: number, selectTerm: string,
    marginBottom: number, color: string, vert: number, perc: number) {

    d3.select(selectTerm).select("svg").remove();

    // remove NA - too many messes up histogram function
    inputData = inputData.filter(d => d[varName] != 'NA');

    var margin = { top: 0, right: 10, bottom: marginBottom, left: 10 }
    var compHeight = height - margin.bottom - margin.top;

    var xExtent = d3.extent(d3.map(inputData, d => d[varName]))

    // set the parameters for the histogram
    const bins = d3.bin()
      .thresholds(binPar)
      .value((d) => d[varName])
      (inputData);

    // Create the x-axis
    const x = d3.scaleLinear()
      .domain([bins[0].x0, bins[bins.length - 1].x1])
      .range([0, width - margin.right - margin.left]);

    // Create the y-axis
    var y = d3.scaleLinear()
      .range([compHeight, 0]);
    y.domain([0, d3.max(bins, function (d) { return d.length; })]);

    // Create SVG
    this.svg = d3.select(this.element.nativeElement)
      .select(selectTerm)
      .append('svg')
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
      .style("-webkit-tap-highlight-color", "transparent")
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    this.svg.append("g")
      .attr("transform", "translate(0," + compHeight + ")")
      .call(d3.axisBottom(x))
      .call(g => g.select(".domain").remove())

    this.svg.selectAll("rect")
      .data(bins)
      .enter()
      .append("rect")
      .attr("x", 0)
      .attr("transform", function (d) { return "translate(" + x(d.x0) + "," + y(d.length) + ")"; })
      .attr("width", function (d) { return x(d.x1) - x(d.x0); })
      .attr("height", function (d) { return compHeight - y(d.length); })
      .style("fill", color)
      .attr("fill-opacity", "0.6")

    if (!isNaN(vert)) {
      var percText = Math.round(perc * 100).toString() + "th perc."
      this.svg.append("line")
        .attr("y1", 0)
        .attr("y2", compHeight)
        .attr("x1", x(vert))
        .attr("x2", x(vert))
        .style("stroke-width", 2)
        .style("stroke", "black")
        .style("fill", "none");

      if (x(vert) > (width / 2)) {
        this.svg.append("text")
          .attr("x", x(vert) - 30)
          .attr("y", 15)
          .text(percText)
          .style("font-weight", "bold")
          .style("font-size", "12pt")
      } else {
        this.svg.append("text")
          .attr("x", x(vert) + 5)
          .attr("y", 15)
          .text(percText)
          .style("font-weight", "bold")
          .style("font-size", "12pt")
      }
    }

  }

  isNumber(value) {
    return typeof value == 'number';
  }

}
