<div class="p-grid">
    <div class="p-col-12">
        <div class="card p-d-flex p-ai-center">
            <h1 style='padding-left: 40px; margin:0px'>Donor View</h1>
        </div>
    </div>
</div>
<div class="p-grid">
    <div class="p-col-12">
        <div class="card">
            <div class="p-grid p-ai-center p-jc-left vertical-container" style="padding:50px">
                <span style="font-size: 18px; font-weight: bold; margin-right: 10px">Search for a donor:</span>
                <input type="text" pInputText [(ngModel)]="donorID" placeholder="ie) R446" (keyup.enter)="searchDonor()">
                <div style="margin-left: 30px">
                    <p-button label="Search" (click)="searchDonor()" icon="pi pi-search"></p-button>
                </div>
                <div style="margin-left: 30px">
                    <p-messages></p-messages>
                </div>
            </div>  
            <div class="p-grid p-ai-stretch p-jc-left vertical-container" style="padding-top: 0px; padding-right: 50px; padding-left: 50px; padding-bottom: 50px">
                <div class="p-col-fixed" style="width:300px; padding:0">
                    <p-menu [model]="items" [style]="{'width': '300px', 'height': '750px'}" (click) = "activeMenu($event)" id="menuID"></p-menu>  
                </div>
                <div #donorView class="donor-view p-col" style="margin-left: 20px; padding:0px;">
                    <div *ngIf="activeTab === 'donor'">
                        <donor-inf [donorInf]="donorInf"></donor-inf>
                    </div>
                    <div *ngIf="activeTab === 'isolation'">
                        <isolation-inf [donorInf]="donorInf"></isolation-inf>
                    </div>
                    <div *ngIf="activeTab === 'gsis'">
                        <static-gsis [donorInf]="donorInf"></static-gsis>
                    </div>
                    <div *ngIf="activeTab === 'gluc_peri'">
                        <peri-gluc [donorInf]="donorInf">></peri-gluc>
                    </div>
                    <div *ngIf="activeTab === 'leu_peri'">
                        <peri-leu [donorInf]="donorInf">></peri-leu>
                    </div>
                    <div *ngIf="activeTab === 'olp_peri'">
                        <peri-olp [donorInf]="donorInf">></peri-olp>
                    </div>
                    <div *ngIf="activeTab === 'seahorse'">
                        <mito-func [donorInf]="donorInf">></mito-func>
                    </div>
                    <div *ngIf="activeTab === 'omics'" style="padding:50px">
                        <h3>Omics Data Availability</h3>
                        <p>The HumanIslets project is collecting up to seven different types of omics data per donor. The table below shows which omics types are available for donor {{donorID}}.</p>
                        <div class="p-grid" style="margin-top:30px;">
                            <div class="p-col-12" *ngIf="!donorInf.record_id">
                                <p-table [value]="donorInf" [tableStyle]="{ 'min-width': '50rem' }">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th style="font-size: 1.25rem;">Omics Type</th>
                                            <th style="font-size: 1.25rem;">Available?</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-omics>
                                        <tr>
                                            <td>{{ omics.type }}</td>
                                            <td><p-tag [value]="omics.avail" [severity]="getSeverity(omics.avail)" [icon]="getIcon(omics.avail)"></p-tag></td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="activeTab === 'exocytosis'">
                        <ephys [donorInf]="donorInf"></ephys>
                    </div>
                    <div *ngIf="activeTab === 'contaminants'" style="padding:20px">{{summaryMessage}}</div>
                </div>
            </div>        
        </div>
    </div>
</div>