import { Component, OnInit, OnDestroy } from '@angular/core';
import { Message, SelectItem, SelectItemGroup } from 'primeng/api';
import { AppMainComponent } from 'src/app/app.main.component';
import { RestAPIService } from 'src/app/service/rest-api.service';
import { Subscription } from 'rxjs';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Output, EventEmitter } from '@angular/core';

interface City {
    name: string,
    code: string
}

@Component({
    selector: 'app-filter',
    templateUrl: './app.filter.component.html',
})

export class AppFilterComponent implements OnInit, OnDestroy {

    @Output() emitFilterSuccess = new EventEmitter<boolean>();
    listExist: boolean = false;

    // usrDir variable
    subUsrDir: Subscription;
    usrDir: string;

    // view image variables
    imageToShow: any;
    featureImage: string = "";
    loadingImage: string = "no";
    base64Image: any;
    submit_loading: boolean = false;

    // API parameter variables
    uploadIds: string;
    donorIds: string = 'NA';
    donorRes: string;

    // main page filter boolean
    filterSex: boolean = false;
    filterDiagnosis: boolean = false;
    filterAge: boolean = false;
    filterBMI: boolean = false;
    filterHba1c: boolean = false;

    // main page filter parameters
    selectedSex: string[] = ['Male', 'Female'];
    selectedDtype: string[] = ['None', 'Type1', 'Type2'];
    ageValues: number[] = [20, 80];
    bmiValues: number[] = [20, 45];
    hba1cValues: number[] = [10, 12];

    // advanced filter boolean
    // donor info
    filterCorDiag: boolean = false;
    filterDonType: boolean = false;
    filterHla2: boolean = false;

    // distribution info
    filterPerIEQRec: boolean = false;
    filterDistIPI: boolean = false;
    filterDistInsPerIEQ: boolean = false;
    filterDistInsDNA: boolean = false;
    filterCultureTime: boolean = false;

    // isolation info
    filterCIT: boolean = false;
    filterPancWeight: boolean = false;
    filterDigTime: boolean = false;
    filterPurityPer: boolean = false;
    filterTotIEQ: boolean = false;
    filterIsletParticles: boolean = false;
    filterIsoIPI: boolean = false;
    filterIEQPerPanc: boolean = false;
    filterInsContent: boolean = false;
    filterIsoInsPerIEQ: boolean = false;
    filterDNAContent: boolean = false;
    filterIsoInsDNA: boolean = false;

    filterCryotubesremaining: boolean = false;
    filterSftubesremaining: boolean = false;

    // advanced filter parameters
    // donor info
    selectedCorDiag: string[] = ['None', 'Pre.T2D', 'Type2', 'Type1'];
    selectedDonationType: string[] = ['NDD', 'DCD', 'MAID'];
    selectedHla2: string[] = ['Positive', 'Negative'];
    selectedMedCond: string[] = ['No', 'Yes'];

    // distribution info
    perIEQRec: number[] = [50, 100];
    distIPI: number[] = [0.1, 2.7];
    distInsPerIEQ: number[] = [20, 70];
    distInsDNA: number[] = [5, 30];
    cultureTime: number[] = [0, 72];

    // isolation info
    CIT: number[] = [2, 40];
    pancWeight: number[] = [0, 200];
    digTime: number[] = [10, 45];
    purityPer: number[] = [75, 100];
    totIEQ: number[] = [100000, 1004000];
    isletParticles: number[] = [100000, 800000];
    isoIPI: number[] = [2, 30];
    IEQPerPanc: number[] = [220, 12000];
    insContent: number[] = [400, 20000];
    isoInsPerIEQ: number[] = [10, 100];
    DNAContent: number[] = [1000, 35000];
    isoInsDNA: number[] = [2, 30];

    // Inventory
    cryotubesremaining: number[]=[1,22];
     sftubesremaining: number[]=[1,18];
    // data type paramters
    donorSelectOptions: any[];
    donorSelect: string = "filter";

    availableType: SelectItemGroup[];
    selectedAvailableType: any[] = [];

    IDType: SelectItem[];
    selectedIDType: string = "redcap";

    // dialog display booleans
    displayAge: boolean;
    displayBMI: boolean;
    displayHba1c: boolean;
    displayPerIEQRec: boolean;
    displayDistIPI: boolean;
    displayDistInsPerIEQ: boolean;
    displayDistInsDNA: boolean;
    displayCultureTime: boolean;
    displayCIT: boolean;
    displayPancWeight: boolean;
    displayDigTime: boolean;
    displayPurityPer: boolean;
    displayTotIEQ: boolean;
    displayIsletParticles: boolean;
    displayIsoIPI: boolean;
    displayIEQPerPanc: boolean;
    displayInsContent: boolean;
    displayIsoInsPerIEQ: boolean;
    displayDNAContent: boolean;
    displayIsoInsDNA: boolean;
    displayCryotubesremaining: boolean;
    displaySftubesremaining: boolean;

    displayDist: boolean;
    displayAgeDist: boolean;
    displayBMIDist: boolean;
    displayHba1cDist: boolean;
    displayDataSummary: boolean;
    displayFilters: boolean;
    figNm: string;

    removeOutliers: boolean = true;

    zipFile: string;
    msgText: string;
    currentMsg: Message[];

    constructor(public appMain: AppMainComponent, private apiService: RestAPIService, private domSanitizer: DomSanitizer, private global: GlobalVarsService) {

        this.IDType = [
            { label: 'Donor ID', value: 'redcap' },
            { label: 'RRID', value: 'rrid' }
        ];

        this.donorSelectOptions = [{ label: 'Apply Filters', value: 'filter' }, { label: 'Upload List', value: 'upload' }];

        this.availableType = [
            {
                label: 'Insulin Secretion', value: 'insulin',
                items: [
                    {label: 'GSIS', value: 'gsis'},
                    {label: 'Glucose Perifusions', value: 'peri_gluc'}, 
                    {label: 'Leucine Perifusions', value: 'peri_leu'},
                    {label: 'OLP Perifusions', value: 'peri_olp'}
                ]
            },
            {
                label: 'Other Outcomes', value: 'other',
                items: [
                    {label: 'Electrophysiology Outcomes', value: 'ephys_donor'},
                    {label: 'Mitochondrial Function', value: 'seahorse'},
                ]
            },
            {
                label: 'Omics Data', value: 'omics',
                items: [
                    {label: 'Gene Expression (Nanostring)', value: 'proc_nanostring_merge'},
                    {label: 'Proteomics', value: 'proc_prot'},
                    {label: 'Bulk RNAseq', value: 'proc_rnaseq'},
                    {label: 'Pseudobulk RNAseq (alpha cells)', value: 'proc_pbrna_Alpha'},
                    {label: 'Pseudobulk RNAseq (beta cells)', value: 'proc_pbrna_Beta'},
                    {label: 'Single-cell patchSeq', value: 'ephys_cell'}
                ]
            }
        ];
    }

    ngOnInit() {
        this.subUsrDir = this.global.currentUsrDir.subscribe(data => this.usrDir = data)

        if (this.usrDir === "NA") {
            this.apiService.getLogin().subscribe(data => {
                this.usrDir = data;
                this.global.changeUsrDir(this.usrDir)
            })
        }
    }

    ngOnDestroy() {
        this.subUsrDir.unsubscribe();
    }


    // define functions
    getDonors(method: string) {
     
        if (method === "filter") {
            this.submit_loading = true;
            if( this.selectedAvailableType === undefined ) {
                console.log('undefined')
            }
            const data = {
                usrDir: this.usrDir,
                omics: "bulk",
                avail: "true|" + this.selectedAvailableType.toString(),
                sex: this.filterSex.toString() + "|" + this.selectedSex.toString(),
                diagnosis: this.filterDiagnosis.toString() + "|" + this.selectedDtype.toString(),
                age: this.filterAge.toString() + "|" + this.ageValues.toString(),
                bmi: this.filterBMI.toString() + "|" + this.bmiValues.toString(),
                hba1c: this.filterHba1c.toString() + "|" + this.hba1cValues.toString(),
                corDiag: this.filterCorDiag.toString() + "|" + this.selectedCorDiag.toString(),
                donType: this.filterDonType.toString() + "|" + this.selectedDonationType.toString(),
                hla2Type: this.filterHla2.toString() + "|" + this.selectedHla2.toString(),
                perIEQRec: this.filterPerIEQRec.toString() + "|" + this.perIEQRec.toString(),
                distIPI: this.filterDistIPI.toString() + "|" + this.distIPI.toString(),
                distInsPerIEQ: this.filterDistInsPerIEQ.toString() + "|" + this.distInsPerIEQ.toString(),
                distInsDNA: this.filterDistInsDNA.toString() + "|" + this.distInsDNA.toString(),
                cultTime: this.filterCultureTime.toString() + "|" + this.cultureTime.toString(),
                cit: this.filterCIT.toString() + "|" + this.CIT.toString(),
                pancWeight: this.filterPancWeight.toString() + "|" + this.pancWeight.toString(),
                digTime: this.filterDigTime.toString() + "|" + this.digTime.toString(),
                purityPer: this.filterPurityPer.toString() + "|" + this.purityPer.toString(),
                totIEQ: this.filterTotIEQ.toString() + "|" + this.totIEQ.toString(),
                isletParticles: this.filterIsletParticles.toString() + "|" + this.isletParticles.toString(),
                isoIPI: this.filterIsoIPI.toString() + "|" + this.isoIPI.toString(),
                IEQPerPanc: this.filterIEQPerPanc.toString() + "|" + this.IEQPerPanc.toString(),
                insContent: this.filterInsContent.toString() + "|" + this.insContent.toString(),
                isoInsPerIEQ: this.filterIsoInsPerIEQ.toString() + "|" + this.isoInsPerIEQ.toString(),
                DNAContent: this.filterDNAContent.toString() + "|" + this.DNAContent.toString(),
                isoInsDNA: this.filterIsoInsDNA.toString() + "|" + this.isoInsDNA.toString(),
                cryotubesremaining: this.filterCryotubesremaining.toString() + "|" + this.cryotubesremaining.toString(),
                  sftubesremaining: this.filterSftubesremaining.toString() + "|" + this.sftubesremaining.toString()
            }
            console.log(this.selectedAvailableType.toString())
            this.apiService.getDonors(data).subscribe(data => {
                this.donorRes = data;
                if (this.donorRes.includes("RES-OK")) {
                    this.donorRes = this.donorRes.replace("RES-OK", "")
                    if (this.donorRes === "0") {
                        this.msgText = "There were no donors that passed all of the specified filters!"
                        this.currentMsg = [{ severity: 'error', summary: 'Error', detail: this.msgText },];
                        this.listExist = false;
                        this.toggleSuccess(false);
                    } else {
                        this.msgText = this.donorRes + " donors passed all of the specified filters."
                        this.currentMsg = [{ severity: 'success', summary: 'Success', detail: this.msgText },];
                        this.listExist = true;
                        this.toggleSuccess(true);
                    }
                    this.submit_loading = false;
                } else {
                    this.currentMsg = [{ severity: 'error', summary: 'Error', detail: 'The API server failed to return results! Please contact us.' },];
                    this.listExist = false;
                    this.toggleSuccess(false);
                    this.submit_loading = false;
                }
            })
        } else {
            this.submit_loading = true;
            this.donorIds = this.uploadIds.replaceAll("\n", ",")
            if (this.donorIds.slice(-1) === ",") {
                this.donorIds = this.donorIds.slice(0, -1)
            }

            this.apiService.postUploadDonors(this.usrDir, this.donorIds, this.selectedIDType).subscribe(data => {
                this.donorRes = data;
                if (this.donorRes.includes("RES-OK")) {
                    this.donorRes = this.donorRes.replace("RES-OK", "")
                    if (this.donorRes === "0") {
                        this.msgText = "None of the uploaded IDs matched to our database!"
                        this.listExist = false;
                        this.toggleSuccess(false);
                        this.currentMsg = [{ severity: 'error', summary: 'Error', detail: this.msgText },];
                    } else {
                        this.msgText = this.donorRes + " uploaded IDs matched to our database."
                        this.currentMsg = [{ severity: 'success', summary: 'Success', detail: this.msgText },];
                        this.listExist = true;
                        this.toggleSuccess(true);
                    }
                    this.submit_loading = false;
                } else {
                    this.currentMsg = [{ severity: 'error', summary: 'Error', detail: 'The API server failed to return results! Please contact us.' },];
                    this.listExist = false;
                    this.toggleSuccess(false);
                    this.submit_loading = false;
                }
            })
            this.submit_loading = false;
        }
    }

    downloadDonorList() {
           let dateTimeStamp = this.getLocalDateTimeStamp();
           this.apiService.getDonorList(this.usrDir).subscribe(data => {
            if (data.includes("RES-OK")) {
                var txtFile = data;
                txtFile = txtFile.replace("RES-OK", "")

                //download file
                this.apiService.getDownload(this.usrDir, txtFile).subscribe(response => {
                    let fileName = response.headers.get('content-disposition')
                        ?.split(';')[1].split('=')[1];
                    txtFile = txtFile.replace(/(\.[^\.]+)$/, `_${dateTimeStamp}$1`);
                    let blob: Blob = response.body as Blob;
                    let a = document.createElement('a');
         
                    a.download = txtFile;
                    a.href = window.URL.createObjectURL(blob);
                    a.click();
                })
            } else {
                alert('error: API server failed to return results');
            }
        });
    }

    loadExample() {
        this.uploadIds = "R391\nR399\nR343\nR200\nR022\nR246\nR212\nR329\nR191\nR173\nR357\nR272\nR286\nR321\nR392\nR395\nR337\nR101\nR227\nR019\n"
    }

    metaPlot(meta, xlab) {
        this.displayDist = true;
        this.loadingImage = 'yes';
        this.imageToShow = '';
       
        this.apiService.getMetaPlot(this.usrDir, meta, xlab).subscribe(data => {
            this.featureImage = data;
            if (this.featureImage.includes("RES-OK")) {

                this.featureImage = this.featureImage.replace("RES-OK", "")
                console.log(this.featureImage);
                this.figNm = this.featureImage
                this.apiService.streamImage(this.usrDir, this.featureImage).subscribe(response => {
                    this.createImageFromBlob(response);
                    this.loadingImage = 'no';
                }, error => {
                    console.log('error: API server failed to return results');
                    this.loadingImage = 'no';
                });
            }
        });
    }

    createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.base64Image = reader.result;
            this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(this.base64Image);
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    toggleSuccess(value: boolean) {
        this.emitFilterSuccess.emit(value);
    }

dwlFeature(){
 let dateTimeStamp = this.getLocalDateTimeStamp();
this.apiService.getDownload(this.usrDir,this.figNm).subscribe(response => {
          let contentDisposition = response.headers.get('content-disposition');
    let fileName = this.figNm;  
    if (contentDisposition) { 
           let matches = contentDisposition.match(/filename="?([^"]+)"?/);
        if (matches && matches.length > 1) {
            fileName = matches[1];
        }
    }
        fileName = fileName.replace(/(\.[^\.]+)$/, `_${dateTimeStamp}$1`);
    let blob = new Blob([response.body], { type: 'image/png' });
 
    let a = document.createElement('a');
    a.style.display = 'none';  
    document.body.appendChild(a);  

    a.download = fileName;  
    a.href = window.URL.createObjectURL(blob); 

    a.click(); 
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
 
        });

}
getLocalDateTimeStamp() {
    let now = new Date();
    let year = now.getFullYear();
    let month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    let day = String(now.getDate()).padStart(2, '0');
    let hours = String(now.getHours()).padStart(2, '0');
    let minutes = String(now.getMinutes()).padStart(2, '0');
    let seconds = String(now.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}-${minutes}-${seconds}`;
}
}
