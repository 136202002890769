<div class="p-grid p-ai-top p-ai-stretch vertical-container" style="margin:20px">
    <div class="p-col" style="padding-bottom: 0px">
        <div #myIdentifier class="p-grid" style="padding:0px 10px 0px 0px">
            <div class="p-col-12 donor-view" style="text-align: right">
                <div class="p-grid">
                    <div class="p-col-6 p-d-flex p-ai-center p-jc-start">
                        <span *ngIf="donorMissing" style="font-size:12pt; padding-left: 5px; font-weight:600">{{donorMissing}}</span>
                    </div>
                    <div class="p-col-6 p-d-flex p-ai-center p-jc-end">
                        <p-selectButton [options]="stateOptions" [(ngModel)]="selType" optionLabel="label" optionValue="value" (onChange)="updateLines()"></p-selectButton>
                    </div>
                    <div class="p-col-12">
                        <div id="mitoFunc" class="linePlot"></div>
                        <div style="text-align: center;"><span style="font-size: 18px;">Time (minutes)</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div  class="p-col-fixed" style="padding-bottom: 0px; width: 250px">
        <div class="p-grid" style="padding:0px 0px 0px 10px">
            <div class="p-col-12 donor-view" style="margin-bottom:20px; min-height: 272px">
                <div id="SelectedHistogram" class="histogram" style="margin-top: 5px; align-content: center; padding:0px"></div>
                <div style="text-align: center;"><span style="font-size: 12px;">{{donorInf[selectedVar].units}}</span></div>
                <div *ngIf="this.donorInfType == 'defined'" style="text-align: center; margin-top:10px"><span style="font-size: 16px;"><strong>Value:</strong> {{donorInf[selectedVar].value}}</span></div>
                <div *ngIf="this.donorInfType == 'undefined'" style="text-align: center; margin-top:10px"><span style="font-size: 16px;"><strong>Value:</strong> Not measured</span></div>
            </div>
        </div>
        <div class="p-grid donor-view" style="min-height: 412px; margin-left:4px; margin-top: 1px; padding-top: 10px">
            <div class="p-col-fixed" style="width:30px">
                <div style="height:50px">&nbsp;</div>
                <div style="height:30px"><p-radioButton value="calc_nonmito_oc" [(ngModel)]="selectedVar" inputId="item1" (onClick)="makeHistograms()"></p-radioButton></div>
                <div style="height:30px"><p-radioButton value="calc_basal_resp" [(ngModel)]="selectedVar" inputId="item2" (onClick)="makeHistograms()"></p-radioButton></div>
                <div style="height:30px"><p-radioButton value="calc_atp_resp" [(ngModel)]="selectedVar" inputId="item3" (onClick)="makeHistograms()"></p-radioButton></div>
                <div style="height:30px"><p-radioButton value="calc_proton_leak" [(ngModel)]="selectedVar" inputId="item4" (onClick)="makeHistograms()"></p-radioButton></div>
                <div style="height:30px"><p-radioButton value="calc_max_gluc_resp" [(ngModel)]="selectedVar" inputId="item5" (onClick)="makeHistograms()"></p-radioButton></div>
                <div style="height:40px"><p-radioButton value="calc_stim_gluc_resp" [(ngModel)]="selectedVar" inputId="item6" (onClick)="makeHistograms()"></p-radioButton></div>
                <div style="height:30px"><p-radioButton value="calc_max_resp" [(ngModel)]="selectedVar" inputId="item7" (onClick)="makeHistograms()"></p-radioButton></div>
                <div style="height:30px"><p-radioButton value="calc_spare_cap" [(ngModel)]="selectedVar" inputId="item8" (onClick)="makeHistograms()"></p-radioButton></div>
                <div style="height:30px"><p-radioButton value="calc_gluc_stim_oci" [(ngModel)]="selectedVar" inputId="item9" (onClick)="makeHistograms()"></p-radioButton></div>
                <div style="height:30px"><p-radioButton value="calc_gluc_stim_sparecap" [(ngModel)]="selectedVar" inputId="item10" (onClick)="makeHistograms()"></p-radioButton></div>
            </div>
            <div class="p-col">
                <div class= "p-col-12" style="padding-bottom: 20px; text-align:left; height: 50px">
                    <span style="font-size: 1.5rem; font-weight: 600;">Variables</span>
                </div>
                <div style="height:30px"><label for="item1" class="ml-2" pTooltip="Minimum rotenone/antimycin A value" tooltipPosition="top">Non-mitochondrial OC</label></div>
                <div style="height:30px"><label for="item2" class="ml-2" pTooltip="Last basal glucose value" tooltipPosition="top">Basal respiration</label></div>
                <div style="height:30px"><label for="item3" class="ml-2" pTooltip="Last basal glucose value - minimum oligomycin value" tooltipPosition="top">ATP respiration</label></div>
                <div style="height:30px"><label for="item4" class="ml-2" pTooltip="Minimum oligomycin value" tooltipPosition="top">Proton leak</label></div>
                <div style="height:30px"><label for="item5" class="ml-2" pTooltip="Maximum high glucose value - minimum rotenone/antimycin A value" tooltipPosition="top">Max glucose respiration</label></div>
                <div style="height:40px"><label for="item6" class="ml-2" pTooltip="Maximum high glucose value - last basal glucose value" tooltipPosition="top">Glucose-stimulated respiration</label></div>
                <div style="height:30px"><label for="item7" class="ml-2" pTooltip="Maximum FCCP value - minimum rotenone/antimycin A value" tooltipPosition="top">Max respiration</label></div>
                <div style="height:30px"><label for="item8" class="ml-2" pTooltip="Maximum FCCP value - last basal glucose value" tooltipPosition="top">Spare capacity</label></div>
                <div style="height:30px"><label for="item9" class="ml-2" pTooltip="Maximum high glucose value / last basal glucose value" tooltipPosition="top">Glucose-stimulated OCI</label></div>
                <div style="height:30px"><label for="item10" class="ml-2" pTooltip="Maximum high glucose value / maximum FCCP value * 100" tooltipPosition="top">Glucose-stimulated spare capacity</label></div>
            </div>
            </div>
        </div>
    </div>



