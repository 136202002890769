import {Component} from '@angular/core';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppService} from './service/menu-state.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './app.sidebar.component.html',
     styleUrls: ['./app.sidebar.component.css']
})
export class AppSideBarComponent {

    analysisState: string;
    
    constructor(public app: AppComponent, public appMain: AppMainComponent, private appservice: AppService) {
        this.appservice.state.subscribe(c => {
            this.analysisState = c;
        });
    }
    
    onAnalysisMenuClick(index: number){   
        this.appservice.nextState(index)
    }

}

