import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { RestAPIService } from '../service/rest-api.service';

@Component({
  selector: 'app-about',
  templateUrl: './app.about.component.html',
})
export class AppTeamComponent implements OnInit {

  subBaseUrl: Subscription;
  baseUrl: string;
  csvUrl: string;

  // view image variables
  imageToShow: any;
  featureImage: string = "";
  loadingImage: string = "no";
  base64Image: any;

  constructor(private http: HttpClient, private global: GlobalVarsService, private domSanitizer: DomSanitizer,
    private apiService: RestAPIService,) {
  }

  ngOnInit(): void {

    this.subBaseUrl = this.global.baseUrl.subscribe(data => {
      this.baseUrl = data

      this.apiService.streamOtherImage("display_data-avail_heatmap.png").subscribe(response => {
        console.log(response)
        this.createImageFromBlob(response);
        console.log(this.imageToShow)
      })
    }) 
  }

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
        this.base64Image = reader.result;
        this.imageToShow = this.domSanitizer.bypassSecurityTrustUrl(this.base64Image);
    }, false);

    if (image) {
        reader.readAsDataURL(image);
    }
}

}
