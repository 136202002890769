import { Component, OnInit, ViewEncapsulation, Input, ElementRef, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import * as d3 from "d3";
import { csvParse } from "d3";
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { GlobalVarsService } from 'src/app/service/global-vars.service';
import { DomSanitizer } from '@angular/platform-browser';

interface ImgType {
  name: string,
  code: string
}

@Component({
  selector: 'ephys',
  templateUrl: './ephys.component.html',
  styleUrls: ['./ephys.component.css'],
  encapsulation: ViewEncapsulation.None,  // see: https://jeffschoonover.dev/posts/2021/02/styling-d3-charts-angular-view-encapsulation/
})
export class EphysComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() donorInf: any;
  @ViewChild('myIdentifier')
  myIdentifier: ElementRef;

  subBaseUrl: Subscription;
  baseUrl: string;

  data: any;
  svg: any;
  donorInfType: string;
  base64Image: any;
  imageToShow: any;

  xAxis: any;
  yAxis: any;
  barMargin: any = { top: 20, right: 20, bottom: 20, left: 30 };
  barHeight: number = 250;

  tooltip: any;
  colWidth: number = 250;
  csvUrl: string;
  cellUrl: string;

  // donor parameters
  donorID: string;
  selectedVar: string = "total_exocytosis_fF_pF_donor";
  units: string = "fF/pF";
  histTitle: string = "total_exocytosis_fF_pF_donor";
  selectedVarValue: any;
  valueExists: boolean = true;

  cellType: string = 'Beta';
  glucConc: string = 'conc5'
  cellOptions: any[] = [{ label: 'Alpha', value: 'Alpha' }, { label: 'Beta', value: 'Beta' }];
  glucOptions: any[] = [{ label: '1 mM', value: 'conc1' }, { label: '5 mM', value: 'conc5' }, { label: '10 mM', value: 'conc10' }];

  constructor(private http: HttpClient, private global: GlobalVarsService, private element: ElementRef, private domSanitizer: DomSanitizer) {
    this.donorInfType = typeof this.donorInf;
  }

  ngOnInit(): void {

    this.subBaseUrl = this.global.baseUrl.subscribe(data => {
      this.baseUrl = data
      this.csvUrl = this.baseUrl + "download/humanislets/display_data-numerical_ephys.csv";
      this.cellUrl = this.baseUrl + "download/humanislets/display_data-numerical_ephys_cell.csv";
    })

  }

  ngAfterViewInit(): void {
    this.colWidth = this.myIdentifier.nativeElement.offsetWidth - 16; // determine plot width based on grid size
  }

  ngOnChanges() {

    if (typeof this.csvUrl !== 'undefined') {
      // add text labels
      this.donorInfType = typeof this.donorInf;
      this.donorID = this.donorInf.record_id
      this.main();
    }
  }

  main() {
    // make plots
    this.http.get(this.csvUrl, { responseType: 'text' }).subscribe(data => {
      this.data = csvParse(data, d3.autoType);
      this.data = this.data.filter(d => d.cell_type == this.cellType);
      this.data = this.data.filter(d => d.glucose_mM == Number(this.glucConc.slice(4)));

      this.histTitle = this.donorInf[this.cellType][this.glucConc][this.selectedVar].name;
      this.units = this.donorInf[this.cellType][this.glucConc][this.selectedVar].units;

      this.selectedVarValue = this.donorInf[this.cellType][this.glucConc][this.selectedVar].value;
      if (typeof this.selectedVarValue != 'number') { this.selectedVarValue = NaN; }

      this.valueExists = typeof this.selectedVarValue == 'number' && !isNaN(this.selectedVarValue);

      this.basicHistogram(this.data, this.selectedVar, this.colWidth, 450, 20, "#VarHistogram", 30, "#43a047", this.selectedVarValue, this.donorInf[this.cellType][this.glucConc][this.selectedVar].perc)
    });

    this.http.get(this.cellUrl, { responseType: 'text' }).subscribe(data => {

      this.data = csvParse(data, d3.autoType);
      this.data = this.data.filter(d => d.record_id == this.donorID);
      this.data = this.data.filter(d => d.cell_type == this.cellType);
      this.data = this.data.filter(d => d.glucose_mM == Number(this.glucConc.slice(4)));

      var boxData = this.data.map(d => d[this.selectedVar])

      this.basicBoxplot("#BoxPlot", this.data)
    });
  }

  basicHistogram(inputData: any, varName: string, width: number, height: number, binPar: number, selectTerm: string,
    marginBottom: number, color: string, vert: number, perc: number) {

    d3.select(selectTerm).select("svg").remove();

    // remove NA - too many messes up histogram function
    inputData = inputData.filter(d => d[varName] != 'NA');

    var margin = { top: 0, right: 10, bottom: marginBottom, left: 10 }
    var compHeight = height - margin.bottom - margin.top;

    // set the parameters for the histogram
    const bins = d3.bin()
      .thresholds(binPar)
      .value((d) => d[varName])
      (inputData);

    // Create the x-axis
    const x = d3.scaleLinear()
      .domain([bins[0].x0, bins[bins.length - 1].x1])
      .range([0, width - margin.right - margin.left]);

    // Create the y-axis
    var y = d3.scaleLinear()
      .range([compHeight, 0]);
    y.domain([0, d3.max(bins, function (d) { return d.length; })]);

    // Create SVG
    this.svg = d3.select(this.element.nativeElement)
      .select(selectTerm)
      .append('svg')
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
      .style("-webkit-tap-highlight-color", "transparent")
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    this.svg.append("g")
      .attr("transform", "translate(0," + compHeight + ")")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("dx", "-.8em")
      .attr("dy", ".15em")
      .attr("transform", "rotate(-65)")
      .call(g => g.select(".domain").remove())

    this.svg.selectAll("rect")
      .data(bins)
      .enter()
      .append("rect")
      .attr("x", 0)
      .attr("transform", function (d) { return "translate(" + x(d.x0) + "," + y(d.length) + ")"; })
      .attr("width", function (d) { return x(d.x1) - x(d.x0); })
      .attr("height", function (d) { return compHeight - y(d.length); })
      .style("fill", color)
      .attr("fill-opacity", "0.6")

    if (!isNaN(vert)) {
      var percText = Math.round(perc * 100).toString()  + "th perc."
      this.svg.append("line")
        .attr("y1", 0)
        .attr("y2", compHeight)
        .attr("x1", x(vert))
        .attr("x2", x(vert))
        .style("stroke-width", 2)
        .style("stroke", "black")
        .style("fill", "none");

      if (x(vert) > (width / 2)) {
        this.svg.append("text")
          .attr("x", x(vert) - 80)
          .attr("y", 15)
          .text(percText)
          .style("font-weight", "bold")
          .style("font-size", "12pt")
      } else {
        this.svg.append("text")
          .attr("x", x(vert) + 5)
          .attr("y", 15)
          .text(percText)
          .style("font-weight", "bold")
          .style("font-size", "12pt")
      }
    }

  }

  basicBoxplot(selectTerm: string, data: any) {

    var varName = this.selectedVar

    // clear old plots
    d3.select(".boxplot").select("svg").remove();

    // set the dimensions and margins of the graph
    var margin = { top: 10, right: 0, bottom: 10, left: 40 },
      width = (this.colWidth / 2) - margin.left - margin.right,
      height = 390 - margin.top - margin.bottom;

    // append the svg object to the body of the page
    var svg = d3.select(selectTerm)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform",
        "translate(" + margin.left + "," + margin.top + ")");

    // remove NA - too many messes up histogram function
    data = data.filter(d => d[this.selectedVar] != 'NA');

    // Compute summary statistics used for the box:
    var data_sorted = data.map(d => d[this.selectedVar])
    var data_sorted = data_sorted.sort(d3.ascending)
    var q1 = d3.quantile(data_sorted, .25)
    var median = d3.quantile(data_sorted, .5)
    var q3 = d3.quantile(data_sorted, .75)
    var min = Math.min(...data_sorted)
    var max = Math.max(...data_sorted)

    var axisMin = min - 0.2 * Math.abs(median)
    var axisMax = max + 0.2 * Math.abs(median)

    // Show the Y scale
    var y = d3.scaleLinear()
      .domain([axisMin, axisMax])
      .range([height, 0]);
    svg.call(d3.axisLeft(y))

    // a few features for the box
    var center = (this.colWidth - margin.left - margin.right) / 4
    var width = (this.colWidth - margin.left - margin.right) / 4

    // Show the main vertical line
    svg
      .append("line")
      .attr("x1", center)
      .attr("x2", center)
      .attr("y1", y(min))
      .attr("y2", y(max))
      .attr("stroke", "#cc00ff")
      .attr("stroke-width", "2px")

    // Show the box
    svg
      .append("rect")
      .attr("x", center - width / 2)
      .attr("y", y(q3))
      .attr("height", (y(q1) - y(q3)))
      .attr("width", width)
      //.attr("stroke", "#cc00ff")
      //.attr("stroke-width", "2px")
      .style("fill", "#efb1ff")

    // show median, min and max horizontal lines
    svg
      .selectAll("toto")
      .data([median])
      .enter()
      .append("line")
      .attr("x1", center - width / 2)
      .attr("x2", center + width / 2)
      .attr("y1", function (d) { return (y(d)) })
      .attr("y2", function (d) { return (y(d)) })
      .attr("stroke", "#cc00ff")
      .attr("stroke-width", "2px")

    // Add individual points with jitter
    var jitterWidth = width*0.8
    svg
      .selectAll("indPoints")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", function () {return(center - jitterWidth / 2 + Math.random() * jitterWidth)})
      .attr("cy", function (d) { return (y(d[varName])) })
      .attr("r", 4)
      .style("fill", "#cc00ff")
      .attr("stroke", "#cc00ff")
  }

  activeMenu(event) {
    let node;
    if (event.target.tagName === "SPAN") {
      node = event.target.parentNode.parentNode;
    } else {
      node = event.target.parentNode;
    }
    if (node != "submenu") {
      let menuitem = document.getElementsByClassName("p-menuitem");
      for (let i = 0; i < menuitem.length; i++) {
        menuitem[i].classList.remove("active");
      }
      node.classList.add("active");
    }
  }

}
