<div class="p-grid p-ai-top p-ai-stretch vertical-container" style="margin:20px">
    <div class="p-col-4" style="padding-bottom: 0px">
        <div #myIdentifier class="p-grid" style="padding:0px 10px 0px 0px">
            <div *ngIf="donorInfType != 'undefined'" class="p-col-12 donor-view" style="margin-bottom:0px; min-height:708px; text-align: center;">
                <div  style="text-align: center; margin-top:35px">
                    <span style="font-size: 1.5rem; font-weight: 600;">Culture Time: </span>
                    <span *ngIf="donorInf.culturetime2.value != 'NA'" style="font-size: 1.3rem; font-weight: 300;">{{donorInf.culturetime2.value}} days</span>
                    <span *ngIf="donorInf.culturetime2.value == 'NA'" style="font-size: 1.3rem; font-weight: 300;">Not recorded</span>
                </div>
                <div id="TimeHistogram" class="histogram" style="margin-top: 40px; align-content: center;"></div>
                <span style="text-align: center;">Days</span>
                <div  style="text-align: center; margin-top:30px">
                    <span style="font-size: 1.5rem; font-weight: 600;">Total Insulin Content: </span>
                    <span *ngIf="donorInf.total_insulin_content.value != 'NA'" style="font-size: 1.3rem; font-weight: 300;">{{donorInf.total_insulin_content.value}} ug/mL</span>
                    <span *ngIf="donorInf.total_insulin_content.value == 'NA'" style="font-size: 1.3rem; font-weight: 300;">Not recorded</span>
                </div>
                <div id="ContentHistogram" class="histogram" style="margin-top: 30px; align-content: center;"></div>
                <span style="text-align: center;">ug/mL</span>
            </div>
        </div>
    </div>
    <div class="p-col-4" style="padding-bottom: 0px">
        <div class="p-grid" style="padding:0px 10px">
            <div *ngIf="donorInfType != 'undefined'" class="p-col-12 donor-view" style="margin-bottom:0px; min-height: 708px; text-align: center;">
                <div  style="text-align: center; margin-top:35px">
                    <span style="font-size: 1.5rem; font-weight: 600;">Insulin Secretion</span>
                </div>
                <div class="p-d-flex p-ai-center p-jc-center" style="margin-top:40px; height:200px">
                    <p-listbox [options]="secretionOpts" [(ngModel)]="selectedSecretion" optionLabel="name" [style]="{'width':'200px'}" (onChange)="updateSecretion()"></p-listbox>
                </div>
                <div  style="text-align: left; margin-top:60px; margin-left:20px">
                    <span style="font-size: 12pt; font-weight: 500;">Value: </span>
                    <span *ngIf="secretionVal != 'NA'" style="font-size: 12pt; font-weight: 300;">{{secretionVal}} ug/mL</span>
                    <span *ngIf="secretionVal == 'NA'" style="font-size: 12pt; font-weight: 300;">Not measured</span>
                </div>
                <div id="SecretionHistogram" class="histogram" style="margin-top: 40px; align-content: center;"></div>
                <span style="text-align: center;">Percent (%)</span>
            </div>
        </div>
    </div>
    <div class="p-col-4" style="padding-bottom: 0px">
        <div class="p-grid" style="padding:0px 0px 0px 10px; min-height: 708px; text-align: center;">
            <div *ngIf="donorInfType != 'undefined'" class="p-col-12 donor-view">
                <div  style="text-align: center; margin-top:35px">
                    <span style="font-size: 1.5rem; font-weight: 600;">Stimulation Index</span>
                </div>
                <div  style="text-align: center; margin-top:5px">
                    <span style="font-size: 1rem; font-weight: 300;">(high glucose secretion/low glucose secretion)</span>
                </div>
                <div class="p-d-flex p-ai-center p-jc-center" style="margin-top:40px; height:200px">
                    <p-listbox [options]="indexOpts" [(ngModel)]="selectedIndex" optionLabel="name" [style]="{'width':'200px'}" (onChange)="updateIndex()"></p-listbox>
                </div>
                <div  style="text-align: left; margin-top:35px; margin-left:20px">
                    <span style="font-size: 12pt; font-weight: 500;">Value: </span>
                    <span *ngIf="indexVal != 'NA'" style="font-size: 12pt; font-weight: 300;">{{indexVal}}</span>
                    <span *ngIf="indexVal == 'NA'" style="font-size: 12pt; font-weight: 300;">Not measured</span>
                </div>
                <div id="IndexHistogram" class="histogram" style="margin-top: 40px; align-content: center;"></div>
                <span style="text-align: center;">Index</span>
            </div>
        </div>
    </div>
</div>


