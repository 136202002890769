import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppVisComponent } from './pages/app.vis.component';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppDownloadComponent } from './pages/app.download.component';
import { AppFeatureComponent } from './pages/app.feature.component';
import { AppDonorComponent } from './pages/app.donor.component';
import { AppOmicsComponent } from './pages/app.omics.component';
import { AppTeamComponent } from './pages/app.about.component';
import { AppTOSComponent } from './pages/app.tos.component';
import { AppUpdatesComponent } from './pages/app.updates.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: AppMainComponent,
                children: [
                    { path: '', component: AppVisComponent },
                    { path: 'feature', component: AppFeatureComponent },
                    { path: 'download', component: AppDownloadComponent },
                    { path: 'about', component: AppTeamComponent},
                    { path: 'donor', component: AppDonorComponent },
                    { path: 'omics', component: AppOmicsComponent },
                    { path: 'tos', component: AppTOSComponent },
                    { path: 'updates', component: AppUpdatesComponent },
                ]
            },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', useHash: false })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
