<div class="p-grid p-ai-top p-ai-stretch vertical-container" style="margin:20px">
    <div class="p-col-fixed" style="padding-bottom: 0px; width:350px">
        <div class="p-grid" style="padding:0px 10px 0px 0px">
            <div class="p-col-12 donor-view" style="margin-bottom:0px; min-height:708px; text-align: center;">
                <div class="p-grid">
                    <div class="p-col-12"
                        style="margin-top:40px; margin-bottom:20px; min-height:70px; text-align: center;  padding: 0px 20px;">
                        <span style="font-size: 1.5rem; font-weight: 600;">Electrophysiology Measurements</span>
                    </div>
                    <div class="p-col-fixed" style="width:60px; margin-left: 5px">
                        <div style="height:50px"><p-radioButton value="cell_size_pF_donor" [(ngModel)]="selectedVar"
                                inputId="item1" (onClick)="main()"></p-radioButton></div>
                        <div style="height:50px"><p-radioButton value="total_exocytosis_fF_pF_donor" [(ngModel)]="selectedVar"
                                inputId="item2" (onClick)="main()"></p-radioButton></div>
                        <div style="height:50px"><p-radioButton value="early_exocytosis_fF_pF_donor" [(ngModel)]="selectedVar"
                                inputId="item3" (onClick)="main()"></p-radioButton></div>
                        <div style="height:50px"><p-radioButton value="late_exocytosis_fF_pF_donor" [(ngModel)]="selectedVar"
                                inputId="item4" (onClick)="main()"></p-radioButton></div>
                        <div style="height:50px"><p-radioButton value="calcium_entry_pC_pF_donor" [(ngModel)]="selectedVar"
                                inputId="item5" (onClick)="main()"></p-radioButton></div>
                        <div style="height:70px"><p-radioButton value="early_ca_current_pA_pF_donor" [(ngModel)]="selectedVar"
                                inputId="item6" (onClick)="main()"></p-radioButton></div>
                        <div style="height:70px"><p-radioButton value="late_ca_current_pA_pF_donor" [(ngModel)]="selectedVar"
                                inputId="item7" (onClick)="main()"></p-radioButton></div>
                        <div style="height:70px"><p-radioButton value="na_current_amp_pA_pF_donor" [(ngModel)]="selectedVar"
                                inputId="item8" (onClick)="main()"></p-radioButton></div>
                        <div style="height:50px"><p-radioButton value="na_half_inactivation_mV_donor" [(ngModel)]="selectedVar"
                                inputId="item9" (onClick)="main()"></p-radioButton></div>
                    </div>
                    <div class="p-col" *ngIf="donorInfType != 'undefined'" style="text-align: left; padding-right:30px">
                        <div style="height:50px">
                            <label for="item1" class="ml-2">
                                <span style="font-size: 12pt;">Cell size (pF)</span>
                            </label>
                        </div>
                        <div style="height:50px">
                            <label for="item2" class="ml-2">
                                <span style="font-size: 12pt;">Total exocytosis (fF/pF)</span>
                            </label>
                        </div>
                        <div style="height:50px">
                            <label for="item3" class="ml-2">
                                <span style="font-size: 12pt;">Early (RRP) exocytosis (fF/pF)</span>
                            </label>
                        </div>
                        <div style="height:50px">
                            <label for="item4" class="ml-2">
                                <span style="font-size: 12pt;">Late exocytosis (fF/pF)</span>
                            </label>
                        </div>
                        <div style="height:50px">
                            <label for="item5" class="ml-2">
                                <span style="font-size: 12pt;">Calcium entry (pC/pF)</span>
                            </label>
                        </div>
                        <div style="height:70px">
                            <label for="item6" class="ml-2">
                                <span style="font-size: 12pt;">Early calcium current amplitude (pA/pF)</span>
                            </label>
                        </div>
                        <div style="height:70px">
                            <label for="item7" class="ml-2">
                                <span style="font-size: 12pt;">Late calcium current amplitude (pA/pF)</span>
                            </label>
                        </div>
                        <div style="height:70px">
                            <label for="item8" class="ml-2">
                                <span style="font-size: 12pt;">Sodium current amplitude (pA/pF)</span>
                            </label>
                        </div>
                        <div style="height:50px">
                            <label for="item9" class="ml-2">
                                <span style="font-size: 12pt;">Half inactivation sodium current (mV)</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="p-col" style="padding-bottom: 0px">
        <div class="p-grid" style="padding:0px 0px 0px 10px;">
            <div class="p-col-12" style="padding-bottom: 0px">
                <div class="p-grid" style="padding:0px 0px 0px 0px;">
                    <div class="p-col-12 donor-view" style="margin-bottom:20px; max-height: 62px; text-align: center;">
                        <div class="p-grid">
                            <div class="p-col-4 p-d-flex p-ai-center p-jc-start" style="min-height: 60px;">
                                <span style="margin-left: 20px; margin-right: 20px;">Cell type: </span>
                                <p-selectButton [options]="cellOptions" [(ngModel)]="cellType" optionLabel="label" optionValue="value" (onChange)="main()"></p-selectButton>
                            </div>
                            <div class="p-col-8 p-d-flex p-ai-center p-jc-start">
                                <span style="margin-right: 20px;">Glucose concentration: </span>
                                <p-selectButton [options]="glucOptions" [(ngModel)]="glucConc" optionLabel="label" optionValue="value" (onChange)="main()"></p-selectButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div #myIdentifier class="p-col-8" style="padding-bottom: 0px">
                <div class="p-grid" style="padding:0px 10px 0px 0px; min-height: 626px;">
                    <div class="p-col-12 donor-view">
                        <div style="text-align: center; margin-top:40px; margin-bottom: 20px">
                            <span style="font-size: 1.5rem; font-weight: 600;">{{histTitle}}</span>
                        </div>
                        <div *ngIf="valueExists" style="text-align: center; margin-top:20px; margin-bottom: 40px">
                            <span style="font-size: 12pt; font-weight: 600;">Value: </span> <span style="font-size: 12pt;">{{selectedVarValue}} {{units}}</span>
                        </div>
                        <div *ngIf="!valueExists" style="text-align: center; margin-top:20px; margin-bottom: 40px">
                            <span style="font-size: 12pt;">Not measured for donor {{donorID}}</span>
                        </div>
                        <div class="p-d-flex p-ai-center p-jc-center" style="padding:0px 30px">
                            <div id="VarHistogram" class="histogram" style="align-content: center; margin-bottom:15px;"></div>
                        </div>
                        <div class="p-d-flex p-ai-center p-jc-center" style="padding:0px">
                            <span style="text-align: center; margin-top: 0px; width: 100%">Normalized {{units}} (averaged across all cells)</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-4" style="padding-bottom: 0px">
                <div class="p-grid" style="padding:0px 0px 0px 10px">
                    <div class="p-col-12 donor-view" style="margin-bottom:0px; min-height: 626px; text-align: center;">
                        <div style="text-align: center; margin-top:40px; margin-bottom: 20px">
                            <span style="font-size: 1.5rem; font-weight: 600;">Single-cell Values</span>
                        </div>
                        <div *ngIf="valueExists" style="text-align: center; margin-top:20px; margin-bottom: 30px">
                            <span style="font-size: 12pt; font-weight: 600;">Units: </span> <span style="font-size: 12pt;">Normalized {{units}}</span>
                        </div>
                        <div *ngIf="valueExists" class="p-d-flex p-ai-center p-jc-center">
                            <div id="BoxPlot" class="boxplot" style="align-content: center; margin-bottom:10px;"></div>
                        </div>
                        <div *ngIf="valueExists" style="text-align: center;">
                            <span style="font-size: 10pt;"> <strong>Boxplot attributes:</strong> Horizontal line = median, box = interquartile range, whisker ends = 
                            minimum and maximum.</span>
                        </div>
                        <div *ngIf="!valueExists" class="p-d-flex p-ai-center p-jc-center" 
                            style="margin-top: 30px; min-height:400px; padding: 0px 40px">
                            <span style="font-size: 12pt">Not measured for donor {{donorID}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>