import {Component, AfterViewInit, Renderer2, OnInit, OnDestroy} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import {AppComponent} from './app.component';
import {AppService} from './service/menu-state.service';

declare var glowCookies: any;

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html'
})
export class AppMainComponent implements AfterViewInit, OnDestroy {

    activeTabIndex: number;

    sidebarActive: boolean;

    topbarMenuActive: boolean;

    sidebarClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: any;

    documentClickListener: any;

    configActive: boolean;

    configClick: boolean;

    constructor(public renderer: Renderer2, private primengConfig: PrimeNGConfig, public app: AppComponent, private appservice: AppService) {}

    ngAfterViewInit() {
        this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
            if (!this.topbarItemClick) {
                this.activeTopbarItem = null;
                this.topbarMenuActive = false;
            }

            if (!this.sidebarClick && (this.overlay || !this.isDesktop())) {
                this.sidebarActive = false;
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            this.configClick = false;
            this.topbarItemClick = false;
            this.sidebarClick = false;
        });
   this.initGlowCookies();
    }

    onTabClick(event: Event, index: number) {
        if (this.activeTabIndex === index) {
            this.sidebarActive = !this.sidebarActive;
        } else {
            this.activeTabIndex = index;
            this.sidebarActive = true;
        }

        event.preventDefault();
        this.appservice.nextState(1);
    }

    closeSidebar(event: Event, index: number) {
console.log(index===0,"index===0")
       if(index===0){
  const banner = document.querySelector('#prebannerBtn') as HTMLElement;
if (banner) {
   banner.style.display = ''; // Hides the element completely
}
        }
        this.activeTabIndex = index;
        this.sidebarActive = false;
        event.preventDefault();
    }

    onSidebarClick($event) {
        this.sidebarClick = true;
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null; } else {
            this.activeTopbarItem = item; }

        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    onRippleChange(event) {
        this.app.ripple = true;
    }

    get overlay(): boolean {
        // true = overlay; false = static
        return this.app.layoutMode === 'overlay';
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }
    }

initGlowCookies() {
    // Initialize glowCookies
    glowCookies.start('en', {
      analytics: 'G-346PTVHWBK',
      style: 1,
      manageColor: 'white',
      bannerDescription: 'We use essential cookies to make our site work. If you are OK, we also use Google Analytics to analyze website traffic.',
      bannerLinkText: 'Read more on Google Analytics',
      policyLink: 'https://marketingplatform.google.com/about/analytics/terms/gb/',
      acceptBtnText: 'OK',
      rejectBtnText: 'Decline',
      manageBackground: '#00A651',
      manageText: 'Manage Cookies'
    });
}
 
}
