<div class="exception-body pagenotfound-body">
    <div class="exception-panel">
        <img src="assets/layout/images/exception/icon-404.png" />

        <p-button label="GO TO HOME" [routerLink]="['/']"></p-button>
    </div>

    <div class="exception-band">
        <div class="exception-content">
            <h3>PAGE NOT FOUND</h3>
            <p>The page you requested cannot be found</p>
            <img src="assets/layout/images/logo.png" />
        </div>
    </div>
</div>
