import {Component, OnInit} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit{

    layoutMode = 'static';

    darkMenu = true;

    inputStyle = 'outlined';

    ripple = true;

    compactMode = false;

    constructor() {}

    ngOnInit() {

    }
}
