import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { GlobalVarsService } from '../service/global-vars.service';

@Injectable({
  providedIn: 'root'
})
export class RestAPIService implements OnInit {

    subBaseUrl: Subscription;
    baseUrl: string;
    
    apiCall!: string;

constructor(private http: HttpClient, private global: GlobalVarsService) {
    this.subBaseUrl = this.global.baseUrl.subscribe(data => this.baseUrl = data)
}

ngOnInit() {
}

    getLogin(){
        this.apiCall = this.baseUrl + "login"
        console.log(this.apiCall)

        return this.http.get(this.apiCall, {responseType: 'text'});
    }

    streamImage(usrDir: string, imgNm: string): Observable<Blob> {
        this.apiCall = this.baseUrl + "download/" + usrDir + "/" + imgNm
        console.log(this.apiCall);

        return this.http.get(this.apiCall, {responseType: 'blob'});
    }

    streamOtherImage(imgNm: string): Observable<Blob> {
        this.apiCall = this.baseUrl + "download/humanislets/" + imgNm
        console.log(this.apiCall);

        return this.http.get(this.apiCall, {responseType: 'blob'});
    }

    getDownload(usrDir: string, imgNm: string) {
        this.apiCall = this.baseUrl + "download/" + usrDir + "/" + imgNm
        console.log(this.apiCall);

        return this.http.get(this.apiCall, {observe: 'response', responseType: 'blob'});
    }

    getHdf5Download(fileNm: string) {
console.log(this.baseUrl ,"this.baseUrl ")
        this.apiCall = this.baseUrl + "download/hdf5/" + fileNm
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {observe: 'response', responseType: 'blob'});
    }

    getUnivariatePlot(usrDir: string, feature: string, meta: string, plot: string): Observable<string>{
        this.apiCall = this.baseUrl + "univariate/" + usrDir + "?feature=" + feature + "&meta=" + meta + "&plot=" + plot;               
        console.log(this.apiCall);

        return this.http.get(this.apiCall, {responseType: 'text'});
    }   

    getScatterPlot(usrDir: string, xaxis: string, yaxis: string, meta: string): Observable<string>{
        this.apiCall = this.baseUrl + "scatterplot/" + usrDir + "?xaxis=" + xaxis + "&yaxis=" + yaxis + "&meta=" + meta;
        console.log(this.apiCall);

        return this.http.get(this.apiCall, {responseType: 'text'});
    }

    getDonors(data: any) {
        this.apiCall = this.baseUrl + "getdonors";
        console.log(this.apiCall);

        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })

        return this.http.post(this.apiCall, data, { headers, responseType: 'text' });
    }

    getMetaPlot(usrDir: string, meta: string, xlab: string) {
        this.apiCall = this.baseUrl + "metaplot/" + usrDir + "?meta=" + meta + "&xlab=" + xlab;               
        console.log(this.apiCall);

        return this.http.get(this.apiCall, {responseType: 'text'});
    }

    postUploadDonors(usrDir: string, donorIds: string, idType: string){
        this.apiCall = this.baseUrl + "uploaddonors/" + usrDir + "?ids=" + donorIds + "&type=" + idType;
        console.log(this.apiCall);

        return this.http.get(this.apiCall, {responseType: 'text'});
    }

    getDataTables(usrDir: string, tables: string, filetype: string){
        this.apiCall = this.baseUrl + "hitables/" + usrDir + "?tables=" + tables + "&filetype=" + filetype;
        console.log(this.apiCall);

        return this.http.get(this.apiCall, {responseType: 'text'})
    }

   getPatchScTables(usrDir: string,  filename: string){
          
        this.apiCall = this.baseUrl + "patchscdownload/" + usrDir +  "?filename=" + filename;
        console.log(this.apiCall);

        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    getDataSummary(usrDir: string, tables: string){
        this.apiCall = this.baseUrl + "datasummary/" + usrDir + "?tables=" + tables;
        console.log(this.apiCall);

        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    getRedcapData(usrDir: string){
        this.apiCall = this.baseUrl + "getredcap/" + usrDir;
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    updateRedcapData(usrDir: string){
        this.apiCall = this.baseUrl + "updateredcap/" + usrDir;
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    // this is a placeholder (no API call on server) and will get replaced by individual functions
    getDonorViewData(usrDir: string, dataType: string, donorID: string){
        this.apiCall = this.baseUrl + "getdonorview/" + usrDir + "?type=" + dataType + "&donor=" + donorID;
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    getDonorList(usrDir: string){
        this.apiCall = this.baseUrl + "donorlist/" + usrDir;
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    getRegressionResults(usrDir: string, variableGroup: string, analysisVar: string, ref: string, contrast: string, 
        fixedEffects: string, omicsType: string, pvalThresh: string, donors: string, cell: string, glucose: string, fdr: string){
        this.apiCall = this.baseUrl + "regression/" + usrDir + "?variableGroup=" + variableGroup + "&analysisVar=" + analysisVar + "&ref=" + ref + "&contrast=" + contrast + "&fixedEffects=" + fixedEffects + "&omicsType=" + omicsType + "&pvalthresh=" + pvalThresh + "&donors=" + donors + "&cell=" + cell + "&glucose=" + glucose + "&fdr=" + fdr;
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    getSpearmanResults(usrDir: string, analysisVar: string, pvalThresh: string, donors: string, cell: string, glucose: string, fdr: string){
        this.apiCall = this.baseUrl + "spearman/" + usrDir + "?analysisVar=" + analysisVar + "&pvalthresh=" + pvalThresh + "&donors=" + donors + "&cell=" + cell + "&glucose=" + glucose + "&fdr=" + fdr;
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    getORAResults(usrDir: string, library: string, fdr: string, collapse: string){
        this.apiCall = this.baseUrl + "ora/" + usrDir + "?library=" + library  + "&fdr=" + fdr + "&collapse=" + collapse;
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    getGSEAResults(usrDir: string, library: string, rank: string, fdr: string, collapse: string){
        this.apiCall = this.baseUrl + "gsea/" + usrDir + "?library=" + library + "&rank=" + rank + "&fdr=" + fdr + "&collapse=" + collapse;
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    getOmicsFeature(usrDir: string, featureID: string, metaVar: string, omicsType: string, donors: string, cell: string, glucose: string){
        this.apiCall = this.baseUrl + "omicfeature/" + usrDir + "?geneID=" + featureID + "&metaVar=" + metaVar + "&omicsType=" + omicsType+ "&donors=" + donors + "&cell=" + cell + "&glucose=" + glucose;
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    getSCFeature(usrDir: string, featureID: string){
        this.apiCall = this.baseUrl + "scfeature/" + usrDir + "?geneID=" + featureID;
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    getDonorInfo(usrDir: string, donorID: string, type: string){
        this.apiCall = this.baseUrl + "donorinfo/" + usrDir + "?donorID=" + donorID + "&dataType=" + type + "&version=" + "1";
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    getPathwayHeatmap(usrDir: string, pathName: string, library: string, analysisVar: string, omicsType: string, varGroup: string, cell: string, glucose: string, donors: string){
        this.apiCall = this.baseUrl + "pwheatmap/" + usrDir + "?pathName=" + pathName + "&library=" + library + "&analysisVar=" + analysisVar + "&omicsType=" + omicsType + "&varGroup=" + varGroup + "&cell=" + cell + "&glucose=" + glucose + "&donors=" + donors;
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

    getSearchResults(usrDir: string, varID: string, varType: string, contrast: string){
        this.apiCall = this.baseUrl + "getsearch/" + usrDir + "?varID=" + varID  + "&varType=" + varType + "&contrast=" + contrast;
        console.log(this.apiCall);
        return this.http.get(this.apiCall, {responseType: 'text'})
    }

}

