<div class="p-grid p-ai-center vertical-container" style="padding-top:20px">
    <div class="p-col-fixed" style="width:140px"><strong>Gene set number</strong></div>
    <div class="p-col-fixed" style="width:200px; padding-left:0px;"><p-inputNumber [(ngModel)]="nPathways" 
        pTooltip="Hit 'Enter' to update" tooltipPosition="bottom" inputId="minmax" inputId="minmax" mode="decimal" [min]="1" [max]="totalPaths" (onKeyDown)="render(data)"></p-inputNumber></div>
    <div class="p-col-fixed" style="width:100px"><strong>Resolution</strong></div>
    <div class="p-col-1" style="padding-left:0px; padding-right: 40px;"><p-slider [(ngModel)]="resolution" [min]="5" [max]="100" (onChange)="render(data)"></p-slider></div>
    <div class="p-col-fixed" style="width:130px"><strong>Density height</strong></div>
    <div class="p-col-1" style="padding-left:0px; padding-right: 40px;"><p-slider [(ngModel)]="densHeight" [min]="5" [max]="200" (onChange)="render(data)"></p-slider></div>
    <div class="p-col-fixed" style="width:100px"><strong>Row height</strong></div>
    <div class="p-col-1" style="padding-left:0px; padding-right: 40px;"><p-slider [(ngModel)]="rowHeight" [min]="5" [max]="100" (onChange)="render(data)"></p-slider></div>
</div>
<div id="RidgeLine" class="ridgeline" style="margin-top: 30px"></div>