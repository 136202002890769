<div class="p-grid p-ai-top p-ai-stretch vertical-container" style="margin:20px">
    <div class="p-col" style="padding-bottom: 0px">
        <div #myIdentifier class="p-grid" style="padding:0px 10px 0px 0px">
            <div class="p-col-12 donor-view" style="text-align: right">
                <div class="p-grid">
                    <div class="p-col-6 p-d-flex p-ai-center p-jc-start">
                        <span *ngIf="donorMissing" style="font-size:12pt; padding-left: 5px; font-weight:600">{{donorMissing}}</span>
                    </div>
                    <div class="p-col-6 p-d-flex p-ai-center p-jc-end">
                        <p-selectButton [options]="stateOptions" [(ngModel)]="selType" optionLabel="label" optionValue="value" (onChange)="updateLines()"></p-selectButton>
                    </div>
                    <div class="p-col-12">
                        <div id="leuPeri" class="linePlot"></div>
                        <div style="text-align: center;"><span style="font-size: 18px;">Time (minutes)</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div  class="p-col-fixed" style="padding-bottom: 0px; width: 250px">
        <div class="p-grid" style="padding:0px 0px 0px 10px">
            <div class="p-col-12 donor-view" style="margin-bottom:20px; min-height: 162px">
                <div id="BaselineHistogram" class="histogram" style="margin-top: 5px; align-content: center; padding:0px"></div>
                <div style="text-align: center;"><span style="font-size: 12px;">Baseline: log10(AUC)</span></div>
            </div>
            <div class="p-col-12 donor-view" style="margin-bottom:20px; min-height: 162px">
                <div id="leu1Histogram" class="histogram" style="margin-top: 5px; align-content: center; padding:0px"></div>
                <div style="text-align: center;"><span style="font-size: 12px;">5mM leucine: log10(AUC)</span></div>
            </div>
            <div class="p-col-12 donor-view" style="margin-bottom:20px; min-height: 162px">
                <div id="leu2Histogram" class="histogram" style="margin-top: 5px; align-content: center; padding:0px"></div>
                <div style="text-align: center;"><span style="font-size: 12px;">5mM leucine + 6mM glucose: log10(AUC)</span></div>
            </div>
            <div class="p-col-12 donor-view" style="margin-bottom:0px; min-height: 162px">
                <div id="kclHistogram" class="histogram" style="margin-top: 5px; align-content: center; padding:0px"></div>
                <div style="text-align: center;"><span style="font-size: 12px;">30 mM KCl: log10(AUC)</span></div>
            </div>
        </div>
    </div>
</div>


